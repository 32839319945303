import * as React from 'react'
import * as _ from 'lodash'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import ContributorQuestionWidget from './ContributorQuestionWidget'
import ValidateCustomRadioWidget from '../src/components/widgets/CustomRadioWidget'
import CheckboxesWidgetWithHelp from '../src/components/widgets/checkboxesWidgetWithHelp'
import customWidgets from '../src/components/widgets'
import { I18n } from 'react-redux-i18n'
const Fragment = (React as any).Fragment

function RegisterWidget() {
  return (
    <Fragment>
      <a
        href="https://ovr.sos.wv.gov/Register/Landing"
        target="_blank"
        className="link-line"
      >
        {I18n.t('Appintake.clickToVote')}
      </a>
    </Fragment>
  )
}

function NoppWidget() {
  return (
    <Fragment>
      <div
        className="main "
        style={{
          overflow: 'auto',
          height: '560px',
          border: '1px solid black',
          padding: '14px'
        }}
        id="pr1"
      >
        <div className="content centered">
          <h3>{I18n.t('Appintake.noticeOfPrivacy')}</h3>
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            {' '}
            <span className="fa fa-print btn-icon-sm" />
            Print
          </button>
          <p className="centered">
            {' '}
            {I18n.t('Appintake.westVirginiaDepartmentOfHealthAndHR')}
            <br />
          </p>
        </div>
        <div className="content">
          <strong>{I18n.t('Appintake.effectiveDateOfNotice')} </strong>
          <p>{I18n.t('Appintake.effectiveDateOfNoticeDescription')}</p>
        </div>
        <div className="content bordered">
          <h4>{I18n.t('Appintake.NOTICEDESCRIBES')}</h4>
        </div>
        <div className="content ">
          <h5>{I18n.t('Appintake.PRIVACYANDYOU')} </h5>
          <p>{I18n.t('Appintake.PRIVACYANDYOUdesc')}</p>
        </div>
        <div className="content">
          <h6>{I18n.t('Appintake.CHANGESTONOTICEOFPRIVACY')} </h6>
          <p>{I18n.t('Appintake.CHANGESTONOTICEOFPRIVACYdesc')}</p>
        </div>
        <div className="content">
          <h6>{I18n.t('Appintake.HOWWEMAYUSEANDSHARE')} </h6>
          <div>
            {I18n.t('Appintake.HOWWEMAYUSEANDSHAREdesc')}
            <ul className="Pvcy-list">
              <li>{I18n.t('Appintake.HOWWEMAYUSEANDSHAREdescL1')} </li>
              <li>{I18n.t('Appintake.HOWWEMAYUSEANDSHAREdescL2')}</li>
              <li>{I18n.t('Appintake.HOWWEMAYUSEANDSHAREdescL3')}</li>
              <li>{I18n.t('Appintake.HOWWEMAYUSEANDSHAREdescL4')}</li>
            </ul>
          </div>
        </div>
        <div className="content">
          <h6>{I18n.t('Appintake.WHYWEMAYUSEORSHARE')} </h6>
          <ol className="Pvcy-list" list-style-type="1">
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL1Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL1')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL2Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL2')}
            </li>
            <li>
              <strong> {I18n.t('Appintake.WHYWEMAYUSEORSHAREL3Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL3')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL4Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL4')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL5Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL5')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL6Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL6')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL7Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL7')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL8Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL8')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL9Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL9')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL10Strong')}</strong>{' '}
              {I18n.t('Appintake.WHYWEMAYUSEORSHAREL10')}
            </li>
          </ol>
          <p>
            <strong>{I18n.t('Appintake.IUnderstand')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREp')}
          </p>
        </div>
        <div className="content">
          <h6>{I18n.t('Appintake.WRITTENPERMISSION')} </h6>
          <p>{I18n.t('Appintake.WRITTENPERMISSIONp1')}</p>
        </div>
        <div className="content">
          <h6>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTS')} </h6>
          <div className="sublist">
            <strong> {I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShave')}</strong>
            <ul className="Pvcy-list">
              <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL1')}</li>
              <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL2')}</li>
              <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL3')}</li>
              <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL4')}</li>
              <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL5')}</li>
              <li>
                <b> {I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL6')}</b>{' '}
              </li>
              <li>
                {I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL7')}
                <a
                  href="https://dhhr.wv.gov/Pages/default.aspx"
                  className="link-line"
                >
                  {I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL7Link')}
                </a>{' '}
              </li>
            </ul>
          </div>
        </div>
        <div className="align border-address">
          <h6>{I18n.t('Appintake.IMPORTANT')}</h6>
          <p>
            <br />
            {I18n.t('Appintake.IMPORTANTp')}
          </p>
        </div>
        <div className="content">
          <h6> {I18n.t('Appintake.HOWDOIASKABOUTMYPRIVACYRIGHTS')} </h6>
          <p>{I18n.t('Appintake.HOWDOIASKABOUTMYPRIVACYRIGHTSp')}</p>
        </div>
        <div className="align border-address">
          <h6> {I18n.t('Appintake.clientServices')} </h6>
          <address>{I18n.t('Appintake.clientServicesAddress')}</address>
        </div>
        <div className="content">
          <h6>{I18n.t('Appintake.HOWDOICOMPLAIN')} </h6>
          <p>{I18n.t('Appintake.HOWDOICOMPLAINp')}</p>
        </div>
        <div className="align border-address">
          <h6>{I18n.t('Appintake.privacyOfficer')}</h6>
          <address>
            <h6>{I18n.t('Appintake.privacyOfficerAddress')}</h6>
          </address>
        </div>
        <div className="align border-address">
          <h6>{I18n.t('Appintake.privacyOfficer')}</h6>
          <address>
            <br />
            {I18n.t('Appintake.privacyOfficer1Address')}
            <br />
          </address>
        </div>
        <div className="align border-address">
          <h6>{I18n.t('Appintake.secretaryOfTheUSDepartment')}</h6>
          <address>
            {I18n.t('Appintake.secretaryOfTheUSDepartmentAddress')}
          </address>
        </div>

        <div className="content introduction-p-style">
          <h6>{I18n.t('Appintake.NORETALIATION')} </h6>
          <p> {I18n.t('Appintake.NORETALIATIONp')}</p>
        </div>
        <div className="content">
          <h6> {I18n.t('Appintake.QUESTIONS')} </h6>
          <p> {I18n.t('Appintake.QUESTIONSp')}</p>
        </div>

        <p>
          {I18n.t('Appintake.localCountyOffices')}
          <a
            href="https://dhhr.wv.gov/bms/Pages/default.aspx"
            className="link-line"
          >
            {' '}
            {I18n.t('Appintake.wvdhhrBms')}
          </a>{' '}
        </p>
      </div>
    </Fragment>
  )
}
/* print  For Right & Responsiblities*/
function printElem(id) {
  let newWin = window.open('', '', 'width=1000,height=800')
  if (newWin) {
    newWin.document.write(
      '<html><head><title></title><style>.content h6 {text-align: center;}.content .centered {  text-align: center;}.bordered h6 {   border: 1px solid black;    padding: 5px 15px;    font-size: 13px!important;} </style></head><body>'
    )
    let prele = document.getElementById(id)
    const printButtonElements = prele.querySelectorAll('.printStyle')
    _.map(printButtonElements, printButton => {
      _.set(printButton, 'hidden', true)
    })
    newWin.document.write(prele.innerHTML)
    newWin.document.close()
    newWin.focus()
    newWin.window.print()
    newWin.close()
  }
}

function ImportantInfoWidget() {
  return (
    <div>
      <div>
        <h3>{I18n.t('Appintake.infoWidgetInfo')}</h3>
      </div>
      <div>
        <ul>
          <li>{I18n.t('Appintake.infoWidgetL1')}</li>
          <li>
            {I18n.t('Appintake.infoWidgetL2')}
            <ul>
              <li>{I18n.t('Appintake.infoWidgetL3')}</li>
              <li>{I18n.t('Appintake.infoWidgetL4')}</li>
              <li> {I18n.t('Appintake.infoWidgetL5')}</li>
              <li> {I18n.t('Appintake.infoWidgetL6')}</li>
              <li>{I18n.t('Appintake.infoWidgetL7')}</li>
            </ul>
          </li>
          <li>
            All questions marked with asterisks, star, are required to be
            answered.
          </li>
          <li>{I18n.t('Appintake.infoWidgetL9')}</li>
          <li>{I18n.t('Appintake.infoWidgetL10')}</li>
          <li>{I18n.t('Appintake.infoWidgetL11')}</li>
          <li>{I18n.t('Appintake.infoWidgetL12')}</li>
          <li>{I18n.t('Appintake.infoWidgetL13')}</li>
          <li>
            {I18n.t('Appintake.infoWidgetL14')}
            <ul>
              <li>{I18n.t('Appintake.infoWidgetL15')}</li>
              <li>{I18n.t('Appintake.infoWidgetL16')}</li>
              <li style={{ listStyle: 'none' }}>{I18n.t('Appintake.or')}</li>
              <li>{I18n.t('Appintake.infoWidgetL17')}</li>
            </ul>
          </li>
          <li>{I18n.t('Appintake.infoWidgetL18')}</li>
        </ul>
      </div>
      <div>
        <h3>{I18n.t('Appintake.childCare')}</h3>
        <p>{I18n.t('Appintake.infoWidgetL19')}</p>
        <br />
        <p>{I18n.t('Appintake.infoWidgetL20')}</p>
        <br />
        <p> {I18n.t('Appintake.infoWidgetL21')}</p>
        <ul>
          <li> {I18n.t('Appintake.infoWidgetL22')}</li>
          <li>
            {I18n.t('Appintake.infoWidgetL23')}
            <ul>
              <li>{I18n.t('Appintake.infoWidgetL24')}</li>
            </ul>
          </li>
          <li>
            {I18n.t('Appintake.infoWidgetL25')}
            <ul>
              <li>{I18n.t('Appintake.infoWidgetL26')}</li>
            </ul>
          </li>
        </ul>
        <br />
        <p>{I18n.t('Appintake.infoWidgetL27')}</p>
        <ul>
          <li>{I18n.t('Appintake.infoWidgetL28')}</li>
          <li>{I18n.t('Appintake.infoWidgetL29')}</li>
          <li>{I18n.t('Appintake.infoWidgetL30')}</li>
          <li>
            {I18n.t('Appintake.infoWidgetL31')}
            <ul>
              <li>{I18n.t('Appintake.infoWidgetL32')}</li>
              <li>{I18n.t('Appintake.infoWidgetL33')}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  )
}

function RnrWidget(props) {
  return (
    <div>
      <h2 className="aIHeader rights-font">
        {I18n.t('Appintake.rnrWidgetL1')}
      </h2>
      <div className="aIDiv print1 " id="rights_pr">
        <h3 className="aIh5 ">
          {I18n.t('Appintake.rnrWidgetL2')}
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('rights_pr')
            }}
          >
            {' '}
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('Appintake.rnrWidgetL3')}
          </button>
        </h3>
        <ul>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL4')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL5')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL6')}{' '}
            <strong> {I18n.t('Appintake.rnrWidgetL6Strong1')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL6p1')}
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL7Strong')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL7')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL8')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL9')}{' '}
            {!props.formContext.hideWVWORKS && (
              <>
                <strong> {I18n.t('Appintake.rnrWidgetL9Strong1')}</strong>{' '}
                {I18n.t('Appintake.rnrWidgetL11p1')}
              </>
            )}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL12')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL13')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.rnrWidgetL14Strong')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL14')}{' '}
            <strong>{I18n.t('Appintake.rnrWidgetL14Strong1')}</strong>
            {I18n.t('Appintake.rnrWidgetL14p1')}
            <ol list-style-type="A">
              <li>{I18n.t('Appintake.rnrWidgetL15')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL16')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL17')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL18')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL19')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL21')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL22')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL23')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL24')}</li>
            </ol>
          </li>
          <li>
            <strong>{I18n.t('Appintake.rnrWidgetL25Strong')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL25')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL26')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL27')}{' '}
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL27p1')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL28')}
            <strong> {I18n.t('Appintake.rnrWidgetL28Strong1')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL28p1')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL29')}{' '}
            <strong>{I18n.t('Appintake.rnrWidgetL30Strong')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL30')}
            <strong> {I18n.t('Appintake.iUnderstand')}</strong> DHHR will notify
            the telephone company.
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL30Strong')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL32')}
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL30Strong')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL33')}
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL30Strong')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL34')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL35')}
            <p style={{ paddingLeft: '30px' }}>
              {I18n.t('Appintake.rnrWidgetL36')}
            </p>
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL37Strong')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL37')}
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL38')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL39')}{' '}
            <strong>{I18n.t('Appintake.rnrWidgetL44')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL41')}
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL43')}{' '}
            <strong>{I18n.t('Appintake.rnrWidgetL44')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL45')}
            <strong> {I18n.t('Appintake.rnrWidgetL46')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL47')}{' '}
            <strong> {I18n.t('Appintake.rnrWidgetL48')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL49')}
          </li>
        </ul>
      </div>
    </div>
  )
}

function RnrCHCRWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">
        {' '}
        {I18n.t('Appintake.CHCRWidgetHeading')}
      </h2>
      <div className="aIDiv print1 " id="pr1">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            {' '}
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <h3 className="aIh5 " id="parentsrights_pr">
            {I18n.t('Appintake.CHCRWidgetParent')}
          </h3>
          <p>{I18n.t('Appintake.CHCRWidgetL1')}</p>
          <ul>
            <li>{I18n.t('Appintake.CHCRWidgetL2')}</li>
            <li>{I18n.t('Appintake.CHCRWidgetL3')}</li>
            <li>{I18n.t('Appintake.CHCRWidgetL4')}</li>
            <li>{I18n.t('Appintake.CHCRWidgetL5')}</li>
            <li>{I18n.t('Appintake.CHCRWidgetL6')}</li>
          </ul>
        </div>
        <div>
          <h3 className="aIh5 ">{I18n.t('Appintake.CHCRWidgetL7')}</h3>
          <p>{I18n.t('Appintake.CHCRWidgetL8')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL9')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL10')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL11')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL12')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL13')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL14')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL15')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL16')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL17')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL18')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL19')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL20')}</p>
          <ol>
            <li>{I18n.t('Appintake.CHCRWidgetL21')}</li>
            <li>{I18n.t('Appintake.CHCRWidgetL22')}</li>
          </ol>
          <p>{I18n.t('Appintake.CHCRWidgetL23')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL24')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL25')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL26')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL27')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL28')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL29')}</p>
        </div>
        <div>
          <h3 className="aIh5 ">{I18n.t('Appintake.CHCRWidgetL30')}</h3>
          <p>{I18n.t('Appintake.CHCRWidgetL31')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL32')}</p>
          <p>{I18n.t('Appintake.CHCRWidgetL33')}</p>
        </div>
      </div>
    </div>
  )
}

function RnrCHSPWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">
        {' '}
        {I18n.t('Appintake.RnrCHSPWidget1')}
      </h2>
      <div className="aIDiv print1 " id="CHSPpr1">
        <p className="aIh5 ">
          <button
            type="button"
            id="PrintCHSP"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('CHSPpr1')
            }}
          >
            {' '}
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <h3 className="aIh5 ">{I18n.t('Appintake.RnrCHSPWidget2')}</h3>
          <ul>
            <li>{I18n.t('Appintake.RnrCHSPWidget3')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget4')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget5')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget6')}</li>
            <li>
              <strong>{I18n.t('Appintake.rnrWidgetL14Strong')}</strong>{' '}
              {I18n.t('Appintake.RnrCHSPWidget7')}
            </li>
            <li>{I18n.t('Appintake.RnrCHSPWidget8')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget9')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget10')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget11')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget12')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget13')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget14')}</li>
          </ul>
        </div>
        <div>
          <h3 className="aIh5 "> {I18n.t('Appintake.RnrCHSPWidget15')}</h3>
          <ul>
            <li>{I18n.t('Appintake.RnrCHSPWidget16')}</li>
            <li>
              {I18n.t('Appintake.RnrCHSPWidget17')}
              <strong> I understand</strong>{' '}
              {I18n.t('Appintake.RnrCHSPWidget18')}
            </li>
            <li>{I18n.t('Appintake.RnrCHSPWidget19')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget20')}</li>
            <li>
              {I18n.t('Appintake.RnrCHSPWidget21')}
              <strong> {I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrCHSPWidget22')}
            </li>
          </ul>
        </div>
        <div>
          <h3 className="aIh5 ">{I18n.t('Appintake.RnrCHSPWidget23')}</h3>
          <ul>
            <li>{I18n.t('Appintake.RnrCHSPWidget24')}</li>
            <li>
              {I18n.t('Appintake.RnrCHSPWidget25')}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrCHSPWidget26')}
            </li>
            <li>
              {I18n.t('Appintake.RnrCHSPWidget27')}
              <strong> {I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrCHSPWidget28')}
            </li>
            <li> {I18n.t('Appintake.RnrCHSPWidget29')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget30')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget31')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget32')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget33')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget34')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget35')}</li>
            <li>{I18n.t('Appintake.RnrCHSPWidget36')}</li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrCHSPWidget37')}
            </li>
            <li>
              {I18n.t('Appintake.RnrCHSPWidget38')}
              <strong> {I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrCHSPWidget39')}
            </li>
          </ul>
        </div>
        <div>
          <h3 className="aIh5 ">{I18n.t('Appintake.RnrCHSPWidget40')}</h3>
          <table id="debit_card_fee">
            <thead>
              <tr>
                <th>{I18n.t('Appintake.RnrCHSPWidget41')}</th>
                <th>{I18n.t('Appintake.RnrCHSPWidget42')}</th>
                <th>{I18n.t('Appintake.RnrCHSPWidget43')}</th>
              </tr>
            </thead>
            <tbody>
              <tr id="get_cash_1">
                {' '}
                <td>{I18n.t('Appintake.RnrCHSPWidget44')} </td>
                <td> </td>
                <td> </td>
              </tr>
              <tr id="nrow_1">
                <td> {I18n.t('Appintake.RnrCHSPWidget46')}</td>
                <td>$0</td>
                <td>
                  {I18n.t('Appintake.RnrCHSPWidget47')}
                  <a
                    href="https://www.allpointnetwork.com/locator.aspx"
                    target="_blank"
                  >
                    <u>www.allpointnetwork.com/locator.aspx</u>
                  </a>
                  . {I18n.t('Appintake.RnrCHSPWidget48')}
                </td>
              </tr>
              <tr id="nrow_2">
                <td>
                  <div>{I18n.t('Appintake.RnrCHSPWidget49')}</div>
                </td>
                <td>$2.50</td>
                <td>{I18n.t('Appintake.RnrCHSPWidget50')}</td>
              </tr>
              <tr id="get_cash_2">
                {' '}
                <td> {I18n.t('Appintake.RnrCHSPWidget51')} </td>
                <td> </td>
                <td> </td>
              </tr>
              <tr id="nrow_3">
                <td> {I18n.t('Appintake.RnrCHSPWidget52')}</td>
                <td>$0.50</td>
                <td>{I18n.t('Appintake.RnrCHSPWidget53')}</td>
              </tr>
              <tr id="get_cash_3">
                {' '}
                <td> {I18n.t('Appintake.RnrCHSPWidget54')} </td>
                <td> </td>
                <td> </td>
              </tr>
              <tr id="nrow_4">
                <td>
                  <div> {I18n.t('Appintake.RnrCHSPWidget56')}</div>
                </td>
                <td>$20.00</td>
                <td>{I18n.t('Appintake.RnrCHSPWidget57')}</td>
              </tr>
            </tbody>
          </table>
          {I18n.t('Appintake.RnrCHSPWidget58')}
          <a
            href="/assets/pdf-files/RID8730-Cardholder-Agreement.pdf"
            target="_blank"
            className="link-line"
          >
            {' '}
            <u>{I18n.t('Appintake.RnrCHSPWidget59')} </u>
          </a>
        </div>
      </div>
    </div>
  )
}

function AuthorizedRepresentativeForm() {
  return (
    <a
      href="/assets/pdf-files/DFA_SLA_1.pdf"
      target="_blank"
      className="link-line"
    >
      {' '}
      <u>{I18n.t('Appintake.AuthorizedRepresentativeForm')} </u>
    </a>
  )
}

function LIEAPApplicationForm(props) {
  return (
    <>
      {props.formContext.showELIEAP && (
        <a
          href="/assets/pdf-files/DFA_LIEAP_1+Rev+12_16_Rev._3_12_21.pdf"
          target="_blank"
          className="link-line"
        >
          {' '}
          <u>{I18n.t('Appintake.LIEAPApplicationForm')} </u>
        </a>
      )}
    </>
  )
}

function RnrFDNTWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">
        {I18n.t('Appintake.RnrFDNTWidget1')}
      </h2>
      <div className="aIDiv print1 " id="pr2">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr2')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <h3 className="aIh5 " id="snap_pr">
            {I18n.t('Appintake.RnrFDNTWidget2')}
          </h3>
          <div className="rnrContentBox">
            <p>{I18n.t('Appintake.RnrFDNTWidget55')}</p>
            <br></br>
            <br></br>
            <p>{I18n.t('Appintake.RnrFDNTWidget53')}</p>
            <p>
              {I18n.t('Appintake.RnrFDNTWidget56')}
              <a
                href=" https://www.usda.gov/sites/default/files/documents/ad-3027.pdf"
                className="link-line"
              >
                https://www.usda.gov/sites/default/files/documents/ad-3027.pdf
              </a>
              {I18n.t('Appintake.RnrFDNTWidget57')}
            </p>
            <p>
              <ol>
                <li> {I18n.t('Appintake.RnrFDNTWidget50')}</li>
                {I18n.t('Appintake.RnrFDNTWidget49')}
                <li> {I18n.t('Appintake.RnrFDNTWidget51')}</li>
                {I18n.t('Appintake.RnrFDNTWidget52')}
                <li>{I18n.t('Appintake.RnrFDNTWidget59')}</li>
                <a
                  href="mailto:FNSCIVILRIGHTSCOMPLAINTS@usda.gov"
                  className="link-line"
                >
                  FNSCIVILRIGHTSCOMPLAINTS@usda.gov
                </a>
              </ol>
            </p>
            <br />
            <p>{I18n.t('Appintake.RnrFDNTWidget45')}</p>
          </div>

          <ul>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget41')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget42')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget9')}
              <em>{I18n.t('Appintake.RnrFDNTWidget9em')}</em>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget10')}
              <strong>
                <u>{I18n.t('Appintake.RnrFDNTWidget111')}</u>
              </strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget121')}
              <strong>
                <u>{I18n.t('Appintake.RnrFDNTWidget11')}</u>
              </strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget12')}
              <strong>
                <u>{I18n.t('Appintake.RnrFDNTWidget37')}</u>
              </strong>{' '}
              - {I18n.t('Appintake.RnrFDNTWidget13')},{' '}
              {I18n.t('Appintake.RnrFDNTWidget40')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget15')}
              <ol type="A">
                <li>
                  {I18n.t('Appintake.RnrFDNTWidget16')}{' '}
                  <strong>
                    <u>{I18n.t('Appintake.RnrFDNTWidget17')}</u>
                  </strong>
                </li>
                <li>
                  {I18n.t('Appintake.RnrFDNTWidget18')}{' '}
                  <strong>
                    <u>{I18n.t('Appintake.RnrFDNTWidget19')}</u>
                  </strong>
                </li>
                <li>{I18n.t('Appintake.RnrFDNTWidget20')}</li>
              </ol>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget21')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget22')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget39')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget24')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget25')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget26')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget28')}{' '}
              <strong>{I18n.t('Appintake.RnrFDNTWidget31')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget29')}{' '}
              <strong>{I18n.t('Appintake.RnrFDNTWidget31')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget30')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget33')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget34')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget35')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>
              &nbsp;{I18n.t('Appintake.RnrFDNTWidget36')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function RnrHEWLWidget(props) {
  let applicantName
  try {
    let thisApplicant = props.formContext.formData.Application.clients[0]
    if (thisApplicant && thisApplicant.clientFstNm) {
      let name = thisApplicant.clientFstNm
      if (thisApplicant.clientLstNm) {
        name += ' ' + thisApplicant.clientLstNm
      }
      applicantName = name
    } else {
      applicantName = 'Undefined'
    }
  } catch (e) {
    applicantName = 'Undefined'
  }

  return (
    <div>
      <h2 className="aIHeader rights-font">{I18n.t('Appintake.HCMPA')}</h2>
      <div className="aIDiv print1 " id="pr3">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr3')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <div className="rnrContentBox">
            <h3 className="aIh5 " id="healthcoverage_pr">
              {I18n.t('Appintake.HealthCoveragePrograms')}
            </h3>
            <p>
              {I18n.t('Appintake.federalLawProhbits')}{' '}
              <a
                href="https://www.hhs.gov/ocr/office/file"
                target="_blank"
                className="link-line"
              >
                <u>www.hhs.gov/ocr/office/file</u>
              </a>{' '}
              {I18n.t('Appintake.writingHHSDirector')}{' '}
            </p>
          </div>

          <ul>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.recipientofMedicaid')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.medicalAssistanceForMyChild')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.incomeIsAbove')}
              <strong> I further understand </strong>{' '}
              {I18n.t('Appintake.myWorkerwill')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.periodOfIneligibility')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.requiredtoDisclose')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.stateMustBeNamed')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.lawMandates')}
              <ul>
                <li>{I18n.t('Appintake.individualQualifies')}</li>
                <li>{I18n.t('Appintake.individualHasSurvingSpouse')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget1')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget2')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget3')}</li>
              </ul>
              <p>{I18n.t('Appintake.RnrHEWLWidget4')}</p>
              <p>{I18n.t('Appintake.RnrHEWLWidget5')}</p>
              <p>{I18n.t('Appintake.RnrHEWLWidget6')}</p>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget7')}
              <ol type="A">
                <li>{I18n.t('Appintake.RnrHEWLWidget8')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget9')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget10')}</li>
              </ol>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget11')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>
              {I18n.t('Appintake.RnrHEWLWidget12')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget13')}{' '}
              <strong>{I18n.t('Appintake.rnrWidgetL14Strong')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget14')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget15')}{' '}
              <strong> {I18n.t('Appintake.authorize')} </strong>
              {I18n.t('Appintake.RnrHEWLWidget16')}
              {I18n.t('Appintake.RnrHEWLWidget17')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget18')}{' '}
              <strong>{I18n.t('Appintake.furtherAuthorize')} </strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget19')}
            </li>
            <li>
              {I18n.t('Appintake.iUnderstand')}{' '}
              {I18n.t('Appintake.RnrHEWLWidget20')}
            </li>
            <li>
              {I18n.t('Appintake.RnrHEWLWidget24')}{' '}
              <strong>{applicantName}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget25')}{' '}
            </li>
            <li>
              {I18n.t('Appintake.iUnderstand')}{' '}
              {I18n.t('Appintake.RnrHEWLWidget26')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget27')}{' '}
              <strong>{I18n.t('Appintake.rnrWidgetL14Strong')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget28')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function RnrHMENWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">Home Energy Assistance (LIEAP)</h2>
      <div className="aIDiv print1 " id="pr4">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr4')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <ul>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.knowinglyProvide')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.willBeNotified')}{' '}
              <strong> {I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.paymentMustBeUsed')}{' '}
              <strong> {I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.foundEligible')}{' '}
              <strong> {I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.regularEmergencyLieap')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

class DHHRInsruction extends React.Component<any, any> {
  componentDidMount() {
    const reneCovInd = _.get(
      this.props.formContext,
      'formData.Application.reneCovInd'
    )
    if (reneCovInd == 0) {
      let noElement = document.getElementById('useTextYesNo_1') as any
      if (noElement) {
        noElement.checked = true
      }
    } else if (reneCovInd > 0) {
      let yesElement = document.getElementById('useTextYesNo_0') as any
      let yesElements = document.getElementsByName('useTextYears') as any
      if (yesElements) {
        for (let i = 0; i < yesElements.length; i++) {
          yesElement.checked = true
          yesElements[reneCovInd - 1].checked = true
          yesElements[i].disabled = false
        }
      }
    }
  }

  render() {
    return (
      <div>
        <p>{I18n.t('Appintake.DHHRIncomeData')}</p>
        <div className="years-padding-style">
          <input
            id="useTextYesNo_0"
            type="radio"
            name="useTextYesNo"
            value="Yes"
            onClick={() => {
              let yesElements = document.getElementsByName(
                'useTextYears'
              ) as any
              for (let i = 0; i < yesElements.length; i++) {
                yesElements[i].disabled = false
              }
              this.props.formContext.setReneCovInd(null)
            }}
          />{' '}
          <label
            className="appIntakeReview"
            htmlFor="useTextYesNo_0"
            id="useTextYesNo_0_label"
          >
            {I18n.t('Appintake.useTaxInformation')}
          </label>
          <div role="group" aria-labelledby="useTextYesNo_0_label">
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <input
                  id="useTextYears_1"
                  type="radio"
                  name="useTextYears"
                  value="1Year"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(1)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_1">
                  {I18n.t('Appintake.1years')}
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_2"
                  type="radio"
                  name="useTextYears"
                  value="2Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(2)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_2">
                  {I18n.t('Appintake.2years')}
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_3"
                  type="radio"
                  name="useTextYears"
                  value="3Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(3)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_3">
                  {I18n.t('Appintake.3years')}
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_4"
                  type="radio"
                  name="useTextYears"
                  value="4Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(4)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_4">
                  {I18n.t('Appintake.4years')}
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_5"
                  type="radio"
                  name="useTextYears"
                  value="5Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(5)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_5">
                  {I18n.t('Appintake.5years')}
                </label>
              </li>
            </ul>
          </div>
          <input
            id="useTextYesNo_1"
            type="radio"
            name="useTextYesNo"
            value="No"
            onClick={() => {
              let yesElements = document.getElementsByName(
                'useTextYears'
              ) as any
              for (let i = 0; i < yesElements.length; i++) {
                yesElements[i].checked = false
                yesElements[i].disabled = true
              }
              this.props.formContext.setReneCovInd(0)
            }}
          />{' '}
          <label
            className="appIntakeReview"
            htmlFor="useTextYesNo_1"
            id="useTextYesNo_1_label"
          >
            {I18n.t('Appintake.dontUseTaxInformation')}
          </label>
        </div>
      </div>
    )
  }
}
function checkingConditions() {
  return (
    <div>
      <ul>
        <li>{I18n.t('Appintake.readByMe')}</li>
        <li>{I18n.t('Appintake.acceptResponsibilities')}</li>
        <li>{I18n.t('Appintake.informationIsTrue')}</li>
        <li>{I18n.t('Appintake.bcseAttorney')}</li>
      </ul>
    </div>
  )
}

function checkingConditionsFull(props) {
  return (
    <div>
      <ul>
        <li>{I18n.t('Appintake.readByMe')}</li>
        <li>{I18n.t('Appintake.acceptResponsibilities')}</li>
        <li>{I18n.t('Appintake.informationIsTrue')}</li>
        <li>{I18n.t('Appintake.electronicallySign')}</li>
        {props.formContext &&
        props.formContext.formData &&
        props.formContext.formData.Application &&
        props.formContext.formData.Application.benefitCategorieslist &&
        _.includes(
          props.formContext.formData.Application.benefitCategorieslist,
          'CHSP'
        ) ? (
          <li>{I18n.t('Appintake.bcseAttorney')}</li>
        ) : (
          ''
        )}
      </ul>
    </div>
  )
}

function AppIntakeRadioWidget(props) {
  const {
    options,
    value,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    id
  } = props

  let newValue = value
  let newDisabled = disabled

  if (props.id) {
    if (
      (props.formContext.roleName === 'Community Partner' ||
        props.formContext.roleName === 'CP Admin') &&
      props.id === 'AppIntakePreQuestionnaire_Application_someoneAsstYouFlg'
    ) {
      newValue = 'N'
      newDisabled = true
      props.options.enumOptions.splice(0, 1)
      if (props.formContext.formData.Application)
        props.formContext.formData.Application.someoneAsstYouFlg = 'N'
    }
  }

  const newProps = {
    ...props,
    value: newValue,
    disabled: newDisabled
  }

  return <ValidateCustomRadioWidget {...newProps} />
}

function RnrWVWorksWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">WV WORKS</h2>
      <div className="aIDiv print1 " id="pr4">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr4')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <ul>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksPayment')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>
              {I18n.t('Appintake.wvWorksRecipientOrNonRecipient')}
            </li>
            <li>
              <div>
                <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
                {I18n.t('Appintake.wvWorksLearningDisability')}",
              </div>{' '}
              <ol>
                <p> {I18n.t('Appintake.DHHRCanHelp')}</p>
                <p>{I18n.t('Appintake.DHHRCanCall')}</p>
                <p> {I18n.t('Appintake.DHHRCanTell')} </p>
              </ol>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksChildMoving')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksAttendingSchool')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksDirectDeposit')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksCashBenefit')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksTanfFunds')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksSupportServices')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  carousel: Fields.CarouselField,
  panel: Fields.PanelField,
  tableRow: Fields.TableRowField,
  checkboxGrid: Fields.CheckboxGridField,
  addressSuggestion: Fields.AddressSuggestionField
}

export const widgets = {
  radio: AppIntakeRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkboxes: Widgets.CustomCheckboxesWidget,
  'alt-date': Widgets.DatePickerWidget,
  review: Widgets.ReviewWidget,
  dollar: customWidgets.customDollarWidget,
  span: Widgets.SpanWidget,
  contributorQuestion: ContributorQuestionWidget,
  validatedText: Widgets.ValidatedTextWidget,
  checkbox: Widgets.CustomCheckboxWidget,
  phone: Widgets.PhoneWidget,
  ssn: Widgets.SSNWidget,
  textarea: Widgets.CustomTextAreaWidget,
  voterLink: RegisterWidget,
  noppLink: NoppWidget,
  startInfo: ImportantInfoWidget,
  rnrInfo: RnrWidget,
  rnrInfoCHCR: RnrCHCRWidget,
  rnrInfoCHSP: RnrCHSPWidget,
  rnrInfoFDNT: RnrFDNTWidget,
  rnrInfoHEWL: RnrHEWLWidget,
  rnrInfoHMEN: RnrHMENWidget,
  rnrInfoWVWORKS: RnrWVWorksWidget,
  checkingConditions: checkingConditions,
  DHHRInsruction: DHHRInsruction,
  checkingConditionsFull: checkingConditionsFull,
  county: Widgets.CustomCountyWidget,
  checkBoxesEnumHelp: CheckboxesWidgetWithHelp,
  ssn1: Widgets.SSNWidgetV2,
  authorizedRepresentativeForm: AuthorizedRepresentativeForm,
  lIEAPApplicationForm: LIEAPApplicationForm
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
