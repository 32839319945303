import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
import { connect } from 'react-redux'
import * as serviceHelper from '../src/components/utils'
import * as actions from '../../../actions'
import { UnauthorizedError } from '../src/components/utils'

const gatewayBaseUrl = config['gatewayBaseUrl']
const gatewayWvUrl = config['gatewayWvUrl']
const formsBaseUrl = config['formsBaseUrl']

const loadEndpoint = config['fetchDocList']
const deleteEndpoint = gatewayWvUrl + config['docRemove']
const viewEndpoint = gatewayWvUrl + config['docView']
const uploadEndpoint = gatewayWvUrl + config['docUpload']
const submitEndpoint = config['submitAllDocs']
const formsEndpoint = config['forms_MyDocumentsAccordion']
const myDocuments =
  '{"myDocuments":[{"accordionTitle":"Child Support Documents","participantUpload":[],"documentUpload":[],"documentSubmit":[]}],"myDocumentsChildCare":[{"accordionTitle":"Child Care Documents","participantUpload":[],"documentUpload":[],"documentSubmit":[]}]}'
const baseUrl = process.env.PUBLIC_URL

interface MyDocumentsAccordionContainerProps {
  presentation: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  appIntake: string
  applicants: Array<{
    myDocumentsChildCare: any
    myDocuments: Array<{
      docList: Array<{
        docUploadId: {
          docUploadId: string
        }
      }>
    }>
  }>
  appId: string
  minHeight: string
  defaultDocType: number
  onDocUpload: (uploadedDoc: any) => void
  onUploadDelete: (deletedDoc: any) => void
  errorMessage: any
  activeApplicationId: any
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  serviceErrors: Array<any>
  roleId: any
  orgId: any
  logoutUser
  history: {
    push: (url: string) => void
  }
  benefits: any
  isCoc: any
  locale
}

interface MyDocumentsAccordionContainerState {
  showModal: boolean
  formData: {}
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  modalData: {}
  loaded: boolean
  showDeleteModal: boolean
  showSelectDocumentTypeModal: boolean
  currentDocumentID: string
  showExistingDocumentModal: boolean
  showLimitModal: boolean
  existingDocumentType: string
  showUploadDocumentModal: boolean
  showUploadWrongDocumentModal: boolean
  deleteFieldId: Array<string>
}

class MyDocumentsAccordionContainer extends React.Component<
  MyDocumentsAccordionContainerProps,
  MyDocumentsAccordionContainerState
> {
  constructor(props: MyDocumentsAccordionContainerProps) {
    super(props)
    sessionStorage.setItem('expand', 'false')

    this.state = {
      showModal: false,
      formSchema: null,
      formData: null,
      formContext: null,
      modalData: null,
      loaded: false,
      showDeleteModal: false,
      showSelectDocumentTypeModal: false,
      currentDocumentID: '',
      showExistingDocumentModal: false,
      showLimitModal: false,
      existingDocumentType: '',
      deleteFieldId: null,
      showUploadDocumentModal: false,
      showUploadWrongDocumentModal: false
    }
  }

  componentDidMount() {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid
          : '',
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    serviceHelper
      .fetchJson(
        formsEndpoint.replace('{version}', '1.0'),
        request,
        this.props.showErrorMessage
      )
      .then(formJson => {
        this.setState({ formSchema: formJson }, () => {
          if (this.props.appIntake) {
            this.parseApplicantData(this.props.applicants)
          } else {
            this._loadFormData(loadEndpoint)
          }
        })
      })
      .catch(error => {
        console.error('MyDocumentsAccordion form fetch failed due to ex', error)
      })
  }

  // UNSAFE_componentWillReceiveProps(newProps: any) {
  //   if (this.props.appIntake && this.state.formSchema) {
  //     this.parseApplicantData(newProps.applicants)
  //   }
  // }
  componentDidUpdate(prevProps, prevState) {
    let newDocuments = 0
    for (let i = 0; i < this.props.applicants.length; i++) {
      if (this.props.applicants[i].myDocuments) {
        newDocuments =
          newDocuments + this.props.applicants[i].myDocuments[0].docList.length
      }
    }
    let newDocumentsChildCare = 0
    for (let i = 0; i < this.props.applicants.length; i++) {
      if (this.props.applicants[i].myDocumentsChildCare) {
        newDocumentsChildCare =
          newDocumentsChildCare +
          this.props.applicants[i].myDocumentsChildCare[0].docList.length
      }
    }
    if (this.props.appIntake && this.state.formSchema) {
      if (prevState.formData && prevState.formData.myDocuments) {
        if (
          !(
            newDocuments ===
            prevState.formData.myDocuments[0].documentUpload.length
          )
        ) {
          this.parseApplicantData(this.props.applicants)
        }
      }
      if (prevState.formData && prevState.formData.myDocumentsChildCare) {
        if (
          !(
            newDocumentsChildCare ===
            prevState.formData.myDocumentsChildCare[0].documentUpload.length
          )
        ) {
          this.parseApplicantData(this.props.applicants)
        }
      }
    }
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount
            ? this.props.auth.userAccount.uuid
            : '',
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          locale: (this.props.locale + '_US').toUpperCase()
        }
      }
      serviceHelper
        .fetchJson(
          formsEndpoint.replace('{version}', '1.0'),
          request,
          this.props.showErrorMessage
        )
        .then(formJson => {
          this.setState({ formSchema: formJson })
        })
        .catch(error => {
          console.error(
            'MyDocumentsAccordion form fetch failed due to ex',
            error
          )
        })
    }
  }

  parseApplicantData = (applicants: Array<any>) => {
    let parsedJson = JSON.parse(myDocuments)
    let documents = parsedJson['myDocuments']
    let documentsChildCare = parsedJson['myDocumentsChildCare']
    let docUploadIds = new Array()
    let docUploadNames = new Array()
    for (var accordionSection of documents) {
      for (var applicant of applicants) {
        let applicantName = applicant.clientFstNm + ' ' + applicant.clientLstNm
        let prtcpObj = {
          applicantName
        }
        accordionSection.participantUpload.push(prtcpObj)
        if (applicant.myDocuments) {
          for (var docClassList of applicant.myDocuments) {
            for (var doc of docClassList.docList) {
              let uploadedObj = {
                prtcpName: applicantName,
                flNm: doc.fileName,
                docVerfNm: doc.fileType,
                uploadDt: doc.docUploadId.uploadDt,
                docUploadId: doc.docUploadId
              }
              if (docClassList.docClssNm === accordionSection.accordionTitle) {
                accordionSection.documentUpload.push(uploadedObj)
              }
              docUploadIds.push(doc.docUploadId)
              docUploadNames.push(applicantName + ' - ' + doc.fileName)
            }
          }
        }
      }
    }
    let docUploadIdsCC = new Array()
    let docUploadNamesCC = new Array()
    for (var accordionSection of documentsChildCare) {
      for (var applicant of applicants) {
        let applicantName = applicant.clientFstNm + ' ' + applicant.clientLstNm
        if (this.props.isCoc) {
          applicantName =
            _.get(applicant, 'clientDetails.personalData[0].clientFstNm') +
            ' ' +
            _.get(applicant, 'clientDetails.personalData[0].clientLstNm')
        }
        let prtcpObj = {
          applicantName
        }
        accordionSection.participantUpload.push(prtcpObj)
        if (applicant.myDocumentsChildCare) {
          for (var docClassList of applicant.myDocumentsChildCare) {
            for (var doc of docClassList.docList) {
              let uploadedObj = {
                prtcpName: applicantName,
                flNm: doc.fileName,
                docVerfNm: doc.fileType,
                uploadDt: doc.docUploadId.uploadDt,
                docUploadId: doc.docUploadId
              }
              if (true) {
                accordionSection.documentUpload.push(uploadedObj)
              }
              docUploadIds.push(doc.docUploadId)
              docUploadNames.push(applicantName + ' - ' + doc.fileName)
            }
          }
        }
      }
    }

    let modalData = {
      docUploadIds: docUploadIds,
      docUploadNames: docUploadNames,
      uploadedDocs: new Array()
    }
    let modalDataCC = {
      docUploadIds: docUploadIdsCC,
      docUploadNames: docUploadNamesCC,
      uploadedDocs: new Array()
    }
    this._loadMyDocumentAccordionComponent(
      this.state.formSchema,
      parsedJson,
      modalData,
      modalDataCC
    )
  }

  _parseJson = (json: Array<any>) => {
    let parsedJson = JSON.parse(myDocuments)
    let returnJson = parsedJson['myDocuments']
    let docUploadIds = new Array()
    let docUploadNames = new Array()
    for (let j = 0; j < returnJson.length; j++) {
      returnJson[j].participantUpload = this.props.applicants
      for (let i = 0; i < json.length; i++) {
        let appDoc = json[i]
        if (appDoc['docClssNm'] == returnJson[j]['accordionTitle']) {
          if (appDoc['status'] == 'SUBMITTED') {
            if (returnJson[j]['documentSubmit'] == null) {
              returnJson[j]['documentSubmit'] = new Array()
            }
            returnJson[j]['documentSubmit'].push(appDoc)
          } else {
            if (returnJson[j]['documentUpload'] == null) {
              returnJson[j]['documentUpload'] = new Array()
            }
            returnJson[j]['documentUpload'].push(appDoc)
            docUploadIds.push(appDoc.docUploadId)
            docUploadNames.push(appDoc.prtcpName + ' - ' + appDoc.flNm)
          }
        }
      }
    }
    let modalData = {
      docUploadIds: docUploadIds,
      docUploadNames: docUploadNames,
      uploadedDocs: new Array()
    }
    let returnJsonCC = parsedJson['myDocumentsChildCare']
    let docUploadIdsCC = new Array()
    let docUploadNamesCC = new Array()
    for (let j = 0; j < returnJsonCC.length; j++) {
      returnJsonCC[j].participantUpload = this.props.applicants
      for (let i = 0; i < json.length; i++) {
        let appDoc = json[i]
        if (appDoc['docClssNm'] == returnJsonCC[j]['accordionTitle']) {
          if (appDoc['status'] == 'SUBMITTED') {
            if (returnJsonCC[j]['documentSubmit'] == null) {
              returnJsonCC[j]['documentSubmit'] = new Array()
            }
            returnJsonCC[j]['documentSubmit'].push(appDoc)
          } else {
            if (returnJsonCC[j]['documentUpload'] == null) {
              returnJsonCC[j]['documentUpload'] = new Array()
            }
            returnJsonCC[j]['documentUpload'].push(appDoc)
            docUploadIdsCC.push(appDoc.docUploadId)
            docUploadNamesCC.push(appDoc.prtcpName + ' - ' + appDoc.flNm)
          }
        }
      }
    }
    let modalDataCC = {
      docUploadIds: docUploadIdsCC,
      docUploadNames: docUploadNamesCC,
      uploadedDocs: new Array()
    }
    this._loadMyDocumentAccordionComponent(
      this.state.formSchema,
      parsedJson,
      modalData,
      modalDataCC
    )
  }

  _viewDocDetails = (docId: string) => {
    let csDocs = _.includes(docId, '_myDocuments_')
    let docTypes = csDocs ? 'myDocuments' : 'myDocumentsChildCare'
    let res = docId.split(`_${docTypes}_`)

    let isUpload = _.includes(res[1], '_documentUpload_')
    let id
    let doc
    let index
    let panelIndex
    if (isUpload) {
      res = res[1].split('_documentUpload_')
      panelIndex = res[0]
      id = res[1].split('_')
      index = id[0]
      doc = this.state.formData[docTypes][panelIndex]['documentUpload'][index]
    } else {
      res = res[1].split('_documentSubmit_')
      panelIndex = res[0]
      id = res[1].split('_')
      index = id[0]
      doc = this.state.formData[docTypes][panelIndex]['documentSubmit'][index]
    }

    const fetchEndpoint = viewEndpoint.replace(
      '{docID}',
      doc['docUploadId']['docUploadId']
    )
    fetch(fetchEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        roleId: this.props.roleId,
        orgId: this.props.orgId
      }
    })
      .then(res => {
        return res.arrayBuffer()
      })
      .then(function(bytearray) {
        if (doc.flNm.split('.')[1] == 'pdf') {
          var file = new Blob([bytearray], {
            type: 'application/pdf'
          })
        } else if (
          doc.flNm.split('.')[1] == 'tif' ||
          doc.flNm.split('.')[1] == 'tiff'
        ) {
          var file = new Blob([bytearray], {
            type: 'application/tiff'
          })
        } else {
          var file = new Blob([bytearray], {
            type: 'image/jpeg'
          })
        }
        //trick to download store a file having its URL
        var fileURL = ''
        // for IE 10/11 save doc pop up will appear

        fileURL = URL.createObjectURL(file)
        window.open(fileURL)
        // auto download the file
        /*var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = 'viewDocument.pdf';
                document.body.appendChild(a);
                a.click();*/
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('MyDocumentsAccordion _viewDocDetails failed with ex', ex)
      })
  }
  _loadFormData = (endpoint: string) => {
    const fetchEndpoint = endpoint.replace('{applId}', this.props.appId)
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        // load json into form
        this._parseJson(json)
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
      })
  }

  _loadMyDocumentAccordionComponent = (
    formSchemaJson: any,
    formDataJson: any,
    modalData: any,
    modalDataCC: any
  ) => {
    let formData, formContext, formEnums
    //modalJson = JSON.stringify(modalData);
    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    modalData = { ...modalData, props: this.props }
    modalDataCC = { ...modalDataCC, props: this.props }
    formContext = {
      refs: {
        ..._.get(formSchemaJson, 'metaData.refs', {}),
        '{is CCA}': () => {
          return this.props.benefits.CCA
        },
        '{is CHSP}': () => {
          return this.props.benefits.CHSP
        }
      },
      forms: formSchemaJson ? formSchemaJson['forms'] : null,
      modalData: [modalData, modalDataCC]
    }

    //load the pre-filled form data
    let formDataLoad = formDataJson

    if (_.isObject(formDataLoad) && !_.isEmpty(formDataLoad)) {
      //Merge the pre-filled form data with an empty default object to ensure necessary objects are available for RJSF rendering.
      formData = _.mergeWith(
        helper.createObjectFromMasterSchema(null, formContext),
        formDataLoad,
        function(objValue, srcValue) {
          if (srcValue == null && objValue == undefined) {
            return {}
          }
          return undefined
        }
      )
    } else {
      //New application, initialize to empty object
      formData = helper.createObjectFromMasterSchema(null, formContext)
    }

    //default 0 path, first accordion, first client
    if (this.props.defaultDocType) {
      _.set(
        formData,
        'myDocuments[0].participantUpload[0].docType',
        this.props.defaultDocType
      )
    }

    //Augment the context with formData
    formContext = {
      ...formContext,
      formData: formData,
      upload: this._onUploadCheck,
      delete: this._openDeleteModal,
      viewDocDetails: this._viewDocDetails
    }
    this.setState({
      formData: formData,
      formContext: formContext,
      formSchema: formSchemaJson,
      modalData: modalData,
      loaded: true
    })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        }
      }
    })
  }

  _onDeleteClick = () => {
    this.setState({ loaded: false, showDeleteModal: false })
    let { deleteFieldId } = this.state
    let csDocs = _.includes(deleteFieldId, '_myDocuments_')
    let docTypes = csDocs ? 'myDocuments' : 'myDocumentsChildCare'
    let panelIndex = deleteFieldId[0]
    let id = deleteFieldId[1].split('_')
    let index = id[0]
    let document = this.state.formData[docTypes][0]['documentUpload'][index]
    let clientDocument
    const clientIndex = this.props.applicants.find(client => {
      if (client[docTypes]) {
        clientDocument = client[docTypes][0].docList.find(doc => {
          return (
            doc.docUploadId.docUploadId === document.docUploadId.docUploadId
          )
        })
      }
      if (clientDocument) {
        return true
      }
    })
    let newDocIndex
    if (clientIndex) {
      newDocIndex = clientIndex[docTypes][0].docList.indexOf(clientDocument)
    }
    const fetchEndpoint = deleteEndpoint.replace(
      '{docID}',
      document['docUploadId']['docUploadId']
    )
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        if (this.props.appIntake) {
          const deletedDoc = {
            clientName: document['prtcpName'],
            documentClssIndex: panelIndex,
            docIndex: newDocIndex,
            pType: csDocs ? 'myDocuments' : 'myDocumentsChildCare'
          }
          this.props.onUploadDelete(deletedDoc)
          this.setState({
            loaded: true,
            formContext: {
              ...this.state.formContext,
              expand_doc_CollapsiblePanelGroupField: true
            }
          })
        } else {
          this._parseJson(json)
        }
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('MyDocumentsAccordion _onDeleteClick failed with ex', ex)
      })
  }

  _onUploadCheck = (id: string) => {
    if (_.includes(id, '_myDocuments_')) {
      let res = id.split('_myDocuments_')
      res = res[1].split('_participantUpload_')
      let panelIndex = res[0]
      res = res[1].split('_')
      let index = res[0]
      let documentUploadCount = this.state.formData['myDocuments'][panelIndex][
        'documentUpload'
      ]
      let courtOrderCounter = 0
      let otherCounter = 0
      let participantUpload = this.state.formData['myDocuments'][panelIndex][
        'participantUpload'
      ][index]
      if (
        participantUpload['docType'] &&
        participantUpload['docType'].length >= 1 &&
        participantUpload &&
        participantUpload.file &&
        participantUpload.file.length > 0
      ) {
        if (
          participantUpload['docType'] === '18' ||
          participantUpload['docType'] === '2'
        ) {
          //cosnt id = participantUpload['docType'].codeReferenceId;
          const existingDocument = this.state.formData[
            'myDocuments'
          ][0].documentUpload.find(documentElement => {
            const docEnum = ['2', '18', '17']
            const docEnumNames = ['Birth Certificate', 'Court Order', 'Other']
            const fileIndex = docEnum.indexOf(participantUpload['docType'])
            const fileType = docEnumNames[fileIndex]
            return (
              participantUpload.applicantName === documentElement.prtcpName &&
              participantUpload.docType &&
              fileType === documentElement.docVerfNm
            )
          })
          if (existingDocument) {
            const referenceCodeValue = existingDocument.docVerfNm
            this.setState(prevState => {
              return {
                ...prevState,
                showExistingDocumentModal: true,
                currentDocumentID: id,
                existingDocumentType: referenceCodeValue
              }
            })
          } else {
            for (let i = 0; i < documentUploadCount.length; ++i) {
              if (documentUploadCount[i].docVerfNm === 'Court Order') {
                courtOrderCounter = courtOrderCounter + 1
              }
            }
            if (
              courtOrderCounter >= 1 &&
              participantUpload['docType'] === '18'
            ) {
              const referenceCodeValue = 'Court Order'
              this.setState(prevState => {
                return {
                  ...prevState,
                  showLimitModal: true,
                  existingDocumentType: referenceCodeValue
                }
              })
            } else {
              this._onUploadClick(id)
            }
          }
        } else if (participantUpload['docType'] === '17') {
          const docEnum = ['2', '18', '17']
          const docEnumNames = ['Birth Certificate', 'Court Order', 'Other']
          const fileIndex = docEnum.indexOf(participantUpload['docType'])
          const fileType = docEnumNames[fileIndex]
          const existingOtherDocumentArray = this.state.formData[
            'myDocuments'
          ][0].documentUpload.filter(documentElement => {
            return (
              participantUpload.applicantName === documentElement.prtcpName &&
              participantUpload.docType &&
              fileType === documentElement.docVerfNm
            )
          })
          if (
            existingOtherDocumentArray &&
            existingOtherDocumentArray.length >= 5
          ) {
            const existingDocumentType =
              participantUpload['docType'].referenceCodeValue
            this.setState(prevState => {
              return {
                ...prevState,
                existingDocumentType: fileType,
                showExistingDocumentModal: true
              }
            })
          } else {
            for (let i = 0; i < documentUploadCount.length; ++i) {
              if (documentUploadCount[i].docVerfNm === 'Other') {
                otherCounter = otherCounter + 1
              }
            }
            if (otherCounter >= 5) {
              const referenceCodeValue = 'Other'
              this.setState(prevState => {
                return {
                  ...prevState,
                  showLimitModal: true,
                  existingDocumentType: referenceCodeValue
                }
              })
            } else {
              this._onUploadClick(id)
            }
          }
        }
      } else {
        if (
          !(
            participantUpload['docType'] &&
            participantUpload['docType'].codeReferenceId
          )
        ) {
          this.setState({ showSelectDocumentTypeModal: true })
        } else if (
          !(
            participantUpload.file &&
            participantUpload['docType'].file.length > 0
          )
        ) {
          this.setState({ showUploadWrongDocumentModal: true })
        } else if (
          !(
            participantUpload &&
            participantUpload.file &&
            participantUpload['docType'].file.length > 0
          )
        ) {
          this.setState({ showUploadDocumentModal: true })
        }
      }
    } else {
      let resCS = id.split('_myDocumentsChildCare_')
      resCS = resCS[1].split('_participantUpload_')
      let panelIndex = 0
      resCS = resCS[1].split('_')
      let index = resCS[0]
      let documentUploadCount = this.state.formData['myDocumentsChildCare'][
        panelIndex
      ]['documentUpload']
      let participantUploadCS = this.state.formData['myDocumentsChildCare'][
        panelIndex
      ]['participantUpload'][index]
      if (
        participantUploadCS &&
        participantUploadCS.file &&
        participantUploadCS.file.length > 0
      ) {
        this._onUploadClick(id)
      }
    }
  }

  _onUploadClick = (id: string) => {
    const { formContext } = this.state
    let csDocs = _.includes(id, '_myDocuments_')
    let docTypes = csDocs ? 'myDocuments' : 'myDocumentsChildCare'
    let res = id.split(`_${docTypes}_`)
    res = res[1].split('_participantUpload_')
    let panelIndex = res[0]
    res = res[1].split('_')
    let index = res[0]

    let participantUpload = this.state.formData[docTypes][panelIndex][
      'participantUpload'
    ][index]
    let data = new FormData()
    let applId = this.props.activeApplicationId

    let dataURI = participantUpload['file']
    // convert base64/URLEncoded data component to raw binary data held in a string
    //let dataURI=uploadData.file;
    if (dataURI) {
      this.setState({ loaded: false })
      let byteString = dataURI.split(',')[1]
      // separate out the mime component
      let mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]
      let filename = dataURI.split(';')[1].split('=')[1]

      let index =
        id.match('(?:.*)_participantUpload_([0-9]+)_?') &&
        id.match('(?:.*)_participantUpload_([0-9]+)_?')[1]

      let docType =
        docTypes === 'myDocuments'
          ? formContext.formData.myDocuments[0].participantUpload[
              index
            ].hasOwnProperty('docType')
            ? formContext.formData.myDocuments[0].participantUpload[index]
                .docType
            : null
          : ''
      //data.append('file', byteString);
      //data.append('appId', this.props.appId);
      //data.append('caseId', null);
      //data.append('documentCategory', participantUpload['docType']);
      //data.append('documentType', null);
      //data.append('byPassFlag', 'N');
      //data.append('fileName', filename);
      //data.append('clientId', participantUpload['id']);
      //data.append('mimeType', mimeString);

      serviceHelper
        .fetchJson(
          uploadEndpoint,
          {
            method: 'POST',
            headers: {
              filename: filename,
              'Content-Type': mimeString,
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              applicationID: applId,
              documentType: docType,
              roleId: this.props.roleId,
              orgId: this.props.orgId
            },
            body: byteString
          },
          this.props.showErrorMessage
        )
        .then(json => {
          if (json && json.error) {
            sessionStorage.setItem('expand', 'true')
            let mydocs = this.state.formContext.formData.myDocuments.slice()
            for (let i = 0; i < mydocs[0].participantUpload.length; i++) {
              if (
                mydocs[0].participantUpload[i].applicantName ===
                participantUpload.applicantName
              ) {
                if (mydocs[0].participantUpload[i].docType) {
                  delete mydocs[0].participantUpload[i].docType
                }
                if (mydocs[0].participantUpload[i].file) {
                  delete mydocs[0].participantUpload[i].file
                }
              }
            }
            this.setState({
              loaded: true,
              formContext: {
                ...this.state.formContext,
                formData: {
                  ...this.state.formContext.formData,
                  ...mydocs
                },
                expand_doc_CollapsiblePanelGroupField: true
              }
            })
          } else {
            if (this.props.appIntake) {
              if (docTypes === 'myDocuments') {
                const docEnum = ['2', '18', '17']
                const docEnumNames = [
                  'Birth Certificate',
                  'Court Order',
                  'Other'
                ]
                const fileIndex = docEnum.indexOf(participantUpload['docType'])
                const fileType = docEnumNames[fileIndex]
                const uploadedDoc = {
                  clientIndex: index,
                  documentClssIndex: panelIndex,
                  doc: {
                    fileType: fileType,
                    typeRefCode: participantUpload['docType'],
                    fileName: participantUpload['file']
                      .split(';')[1]
                      .split('name=')[1],
                    docUploadId: json,
                    uploadDt: json.uploadDt,
                    ptype: 'myDocuments'
                  }
                }
                sessionStorage.setItem('expand', 'true')
                this.props.onDocUpload(uploadedDoc)
                this.setState({
                  loaded: true,
                  formContext: {
                    ...this.state.formContext,
                    expand_doc_CollapsiblePanelGroupField: true
                  }
                })
              } else {
                const uploadedDoc = {
                  clientIndex: index,
                  documentClssIndex: panelIndex,
                  doc: {
                    fileName: participantUpload['file']
                      .split(';')[1]
                      .split('name=')[1],
                    docUploadId: json,
                    uploadDt: json.uploadDt,
                    ptype: 'myDocumentsChildCare'
                  }
                }
                sessionStorage.setItem('expand', 'true')
                this.props.onDocUpload(uploadedDoc)
                this.setState({
                  loaded: true,
                  formContext: {
                    ...this.state.formContext,
                    expand_doc_CollapsiblePanelGroupField: true
                  }
                })
              }
            } else {
              this._loadFormData(loadEndpoint)
            }
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.error(
            'MyDocumentsAccordion _onUploadClick failed with ex',
            ex
          )
          this.setState({ loaded: true })
        })
    }
  }

  _onSubmit = ({ formData }) => {
    this.setState({ loaded: false })
    const fetchEndpoint = submitEndpoint.replace(
      '{applId}',
      formData.props.appId
    )
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'PUT',
          headers: {
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData['uploadedDocs'])
        },
        this.props.showErrorMessage
      )
      .then(json => {
        this._close()
        this._loadFormData(loadEndpoint)
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('MyDocumentsAccordion _onSubmit failed with ex', ex)
      })
  }

  _close = () => {
    this.setState({ showModal: false })
  }

  _open = () => {
    this.setState({ showModal: true })
  }

  _closeDeleteModal = () => {
    this.setState({ showDeleteModal: false, deleteFieldId: null })
  }

  _closeSelectDocumentTypeModal = () => {
    this.setState({ showSelectDocumentTypeModal: false })
  }
  _openDeleteModal = (id: string) => {
    let csDocs = _.includes(id, '_myDocuments_')
    let docTypes = csDocs ? 'myDocuments' : 'myDocumentsChildCare'
    let res = id.split(`${docTypes}`)
    res = res[1].split('_documentUpload_')
    this.setState({ showDeleteModal: true, deleteFieldId: res })
  }
  _openExistingDocumentModal = () => {
    const id = this.state.currentDocumentID
    let res = id.split('_myDocuments_')
    res = res[1].split('_participantUpload_')
    let panelIndex = res[0]
    res = res[1].split('_')
    let index = res[0]
    let participantUpload = this.state.formData['myDocuments'][panelIndex][
      'participantUpload'
    ][index]
    const existingDocument = this.state.formData[
      'myDocuments'
    ][0].documentUpload.find(documentElement => {
      const docEnum = ['2', '18', '17']
      const docEnumNames = ['Birth Certificate', 'Court Order', 'Other']
      const fileIndex = docEnum.indexOf(participantUpload['docType'])
      const fileType = docEnumNames[fileIndex]
      return (
        participantUpload.applicantName === documentElement.prtcpName &&
        participantUpload.docType &&
        fileType === documentElement.docVerfNm
      )
    })
    if (existingDocument) {
      const removalIndex = _.indexOf(
        this.state.formData['myDocuments'][0].documentUpload,
        existingDocument
      )
      const allDocuments = this.state.formData['myDocuments'][0].documentUpload
      const newDocumentList = allDocuments.splice(removalIndex, 1)
      this.setState(prevState => {
        return {
          ...prevState,
          formData: {
            ...prevState.formData,
            documentUpload: allDocuments
          },
          formContext: {
            ...prevState.formContext,
            formData: {
              ...prevState.formData,
              documentUpload: allDocuments
            }
          },
          showExistingDocumentModal: false,
          existingDocumentType: ''
        }
      })
      this._onUploadClick(this.state.currentDocumentID)
    }
  }

  _onDeleteModalClick = () => {
    this.props.deleteErrorMessage()
  }
  _closeExistingDocumentModal = () => {
    const id = this.state.currentDocumentID
    let res = id.split('_myDocuments_')
    res = res[1].split('_participantUpload_')
    let panelIndex = res[0]
    res = res[1].split('_')
    let index = res[0]
    let participantUpload = this.state.formData['myDocuments'][panelIndex][
      'participantUpload'
    ][index]
    const participantUploadArray = this.state.formData['myDocuments'][
      panelIndex
    ]['participantUpload']
    let newParticipantUpload = {
      applicantName: participantUpload.applicantName
    }
    let newParticipantUploadArray = participantUploadArray.splice(
      index,
      1,
      newParticipantUpload
    )
    this.setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          participantUpload: newParticipantUploadArray
        },
        formContext: {
          ...prevState.formContext,
          formData: {
            ...prevState.formData,
            participantUpload: newParticipantUploadArray
          }
        },
        showExistingDocumentModal: false,
        existingDocumentType: ''
      }
    })
  }

  _closeOtherDocumentTypeModal = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showExistingDocumentModal: false
      }
    })
  }

  _closeUploadDocumentModal = () => {
    this.setState({
      showUploadDocumentModal: false,
      showUploadWrongDocumentModal: false
    })
  }

  _closeLimitModal = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showLimitModal: false
      }
    })
  }

  render() {
    const { presentation, minHeight, appIntake, serviceErrors } = this.props
    const {
      showModal,
      formData,
      formSchema,
      formContext,
      modalData,
      showSelectDocumentTypeModal,
      showExistingDocumentModal,
      loaded,
      showDeleteModal,
      existingDocumentType,
      showUploadDocumentModal,
      showLimitModal,
      showUploadWrongDocumentModal
    } = this.state

    const presentationProps = {
      showModal,
      formData,
      formSchema,
      formContext,
      modalData,
      loaded,
      onSubmit: this._onSubmit,
      onFormDataChange: this._onFormDataChange,
      appIntake,
      open: this._open,
      showDeleteModal,
      showSelectDocumentTypeModal,
      openExistingDocumentModal: this._openExistingDocumentModal,
      closeExistingDocumentModal: this._closeExistingDocumentModal,
      showExistingDocumentModal,
      showLimitModal,
      existingDocumentType,
      closeOtherDocumentTypeModal: this._closeOtherDocumentTypeModal,
      closeSelectDocumentTypeModal: this._closeSelectDocumentTypeModal,
      closeUploadDocumentModal: this._closeUploadDocumentModal,
      closeLimitModal: this._closeLimitModal,
      closeDeleteModal: this._closeDeleteModal,
      onDeleteClick: this._onDeleteClick,
      minHeight,
      showUploadDocumentModal,
      showUploadWrongDocumentModal,
      serviceErrors,
      onDeleteModalClick: this._onDeleteModalClick
    }

    return <div>{presentation(presentationProps)}</div>
  }
}
function mapStateToProps(state, ownProps) {
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    roleId,
    orgId,
    locale: state.i18n.locale,
    serviceErrors: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDocumentsAccordionContainer)
