import * as React from 'react'
import { Panel } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Panel'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import * as actions from '@optum-wvie/dynamic-ui-framework/src/actions'
import { scrollToTop } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { Link } from 'react-router-dom'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
const baseUrl = process.env.PUBLIC_URL

export function _getPreInformation(key: string) {
  let title: string, summary: any, icon: JSX.Element

  switch (key) {
    case 'MEDI':
      title = I18n.t('BenefitsCatalog.medicaid.title')
      icon = <span className="fa fa-heart" />
      summary = I18n.t('BenefitsCatalog.medicaid1')
      break
    case 'MPAP':
      title = I18n.t('BenefitsCatalog.benefitMPAP')
      icon = <span className="fa fa-heart" />
      summary = I18n.t('BenefitsCatalog.benefitMPAP2')
      break
    case 'MLTC':
      title = I18n.t('BenefitsCatalog.benefitMLTC')
      icon = <span className="fa fa-heart" />
      summary = I18n.t('BenefitsCatalog.benefitMLTC1')
      break
    case 'SNAP':
      title = I18n.t('BenefitsCatalog.benefitSNAP1')
      icon = <span className="fa fa-apple" />
      summary = I18n.t('BenefitsCatalog.benefitSNAP2')

      break
    case 'TANF':
      title = I18n.t('BenefitsCatalog.benefitTANF1')
      icon = (
        <span
          className="glyphicon myglyphicon-parents"
          style={{ marginLeft: '10px' }}
        />
      )
      summary = I18n.t('BenefitsCatalog.benefitTANF2')
      break
    case 'SCLA':
      title = I18n.t('BenefitsCatalog.benefitSCA1')
      icon = (
        <span
          className="glyphicon myglyphicon-parents"
          style={{ marginLeft: '10px' }}
        />
      )
      summary = I18n.t('BenefitsCatalog.benefitSCADesc')
      break

    case 'LIEAP':
      title = I18n.t('BenefitsCatalog.benefitLIEAP1')
      icon = (
        <span
          className="glyphicon myglyphicon-parents"
          style={{ marginLeft: '10px' }}
        />
      )
      summary = I18n.t('BenefitsCatalog.benefitLIEAPDesc')
      break
    case '20DPS':
      title = I18n.t('BenefitsCatalog.benefitCWS1')
      icon = (
        <span
          className="glyphicon myglyphicon-parents"
          style={{ marginLeft: '10px' }}
        />
      )
      summary = I18n.t('BenefitsCatalog.benefitCWS2')
      break
    case 'CWS':
      title = I18n.t('BenefitsCatalog.benefitCWS3')
      icon = (
        <span
          className="glyphicon myglyphicon-parents"
          style={{ marginLeft: '10px' }}
        />
      )
      summary =
        I18n.t('BenefitsCatalog.cwsDetails') +
        I18n.t('BenefitsCatalog.cwsDetails1') +
        I18n.t('BenefitsCatalog.cwsDetails2') +
        I18n.t('BenefitsCatalog.cwsDetails3') +
        I18n.t('BenefitsCatalog.cwsDetails4') +
        I18n.t('BenefitsCatalog.cwsDetails4')
      break
    case 'APS':
      title = I18n.t('BenefitsCatalog.benefitAPS1')
      icon = (
        <span
          className="glyphicon myglyphicon-parents"
          style={{ marginLeft: '10px' }}
        />
      )
      summary =
        I18n.t('BenefitsCatalog.benefitAPSDetails1') +
        I18n.t('BenefitsCatalog.benefitAPSDetails2') +
        I18n.t('BenefitsCatalog.benefitAPSDetails3') +
        I18n.t('BenefitsCatalog.benefitAPSDetails4') +
        I18n.t('BenefitsCatalog.benefitAPSDetails5') +
        I18n.t('BenefitsCatalog.benefitAPSDetails6') +
        I18n.t('BenefitsCatalog.benefitAPSDetails7')
      break
    case 'CC':
      title = I18n.t('BenefitsCatalog.benefitCC')
      icon = (
        <span
          className="glyphicon myglyphicon-parents"
          style={{ marginLeft: '10px' }}
        />
      )
      summary = I18n.t('BenefitsCatalog.benefitCC2')
      break
    case 'QHP':
      title = I18n.t('BenefitsCatalog.benefitQHP')
      icon = <span className="fa fa-heart" />
      summary = I18n.t('BenefitsCatalog.benefitQHPDesc')
      break
    case 'CHIP':
      title = I18n.t('BenefitsCatalog.benefitCHIP')
      icon = <span className="fa fa-heart" />
      summary = I18n.t('BenefitsCatalog.benefitCHIP1')
      break
    case 'CSS':
      title = I18n.t('BenefitsCatalog.childSupport')
      icon = (
        <span className="fa fa-child fa-lg" style={{ marginLeft: '10px' }} />
      )
      summary = I18n.t('BenefitsCatalog.lienDesc')
      break
    default:
      title = I18n.t('General.noMyFavoritesFound')
      icon = <span />
      summary = I18n.t('General.noMyFavoritesFound')
  }

  return { title, icon, summary }
}

export function BenefitContent(key: string, showLIEAPhyperlinks?): JSX.Element {
  switch (key) {
    case 'MEDI':
      return (
        <div className="benefit benefit-medicaid">
          <h2 className="bold">{I18n.t('BenefitsCatalog.medicaid.title')}</h2>
          <p>{I18n.t('BenefitsCatalog.medicaid1')}</p>
          <p>{I18n.t('BenefitsCatalog.medicaid2')}</p>
          <ul>
            <li>{I18n.t('BenefitsCatalog.medicaid3')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid4')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid5')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid6')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid7')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid8')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid9')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid10')}</li>
          </ul>
          <h4 className="bold">{I18n.t('BenefitsCatalog.medicaid11')}</h4>
          <ul>
            <li>{I18n.t('BenefitsCatalog.medicaid12')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid13')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid14')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid15')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid16')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid17')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid18')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid19')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid20')}</li>
            <li>{I18n.t('BenefitsCatalog.medicaid21')}</li>
          </ul>
          <p>{I18n.t('BenefitsCatalog.medicaid22')}</p>
          <h4 className="bold">{I18n.t('BenefitsCatalog.medicaid23')}</h4>
          <ul>
            <li>
              <a
                href="https://dhhr.wv.gov/bms/Pages/default.aspx"
                target="_blank"
                className="link-line"
              >
                {I18n.t('BenefitsCatalog.medicaid24')}
              </a>
            </li>
            <li>
              <a
                href="https://dhhr.wv.gov/bms/BMSPUB/Documents/Guide%20to%20Medicaid%202021.pdf"
                target="_blank"
                className="link-line"
              >
                {I18n.t('BenefitsCatalog.medicaid25')}
              </a>
            </li>
          </ul>
        </div>
      )
    case 'SNAP':
      return (
        <div className="benefit benefit-snap">
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitSNAP1')}</h2>
          <p>{I18n.t('BenefitsCatalog.benefitSNAP2')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitSNAP3')}</h4>
          <p>{I18n.t('BenefitsCatalog.benefitSNAP4')}</p>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitSNAP5')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitSNAP6')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitSNAP7')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitSNAP8')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitSNAP9')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitSNAP10')}</li>
          </ul>
          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitSNAP11')}</h4>
          <p>{I18n.t('BenefitsCatalog.benefitSNAP12')}</p>
          <table className="table">
            <thead>
              <tr>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP13')}</th>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP14')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>$281</td>
              </tr>
              <tr>
                <td>2</td>
                <td>$516</td>
              </tr>
              <tr>
                <td>3</td>
                <td>$740</td>
              </tr>
              <tr>
                <td>4</td>
                <td>$939</td>
              </tr>
              <tr>
                <td>5</td>
                <td>$1,116</td>
              </tr>
              <tr>
                <td>6</td>
                <td>$1,339</td>
              </tr>
              <tr>
                <td>7</td>
                <td>$1,480</td>
              </tr>
              <tr>
                <td>8</td>
                <td>$1,691</td>
              </tr>
              <tr>
                <td>{I18n.t('BenefitsCatalog.benefitSNAP15')}</td>
                <td>$211</td>
              </tr>
            </tbody>
          </table>
          <p>{I18n.t('BenefitsCatalog.benefitSNAP16')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitSNAP17')}</h4>
          <p>{I18n.t('BenefitsCatalog.benefitSNAP18')}</p>
          <table className="table">
            <thead>
              <tr>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP19')}</th>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP20')}</th>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP21')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>5</td>
                <td>$1,116</td>
                <td>$2,300</td>
              </tr>
            </tbody>
          </table>
          <p>{I18n.t('BenefitsCatalog.benefitSNAP22')}</p>
          <table className="table">
            <thead>
              <tr>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP23')}</th>
                <th style={{ minWidth: '50px' }}>30%</th>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP24')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>$2,300</td>
                <td>X 0.3</td>
                <td>=$690</td>
              </tr>
            </tbody>
          </table>
          <p>{I18n.t('BenefitsCatalog.benefitSNAP25')}</p>
          <table className="table">
            <thead>
              <tr>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP26')}</th>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP27')}</th>
                <th>{I18n.t('BenefitsCatalog.benefitSNAP28')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>$1,116</td>
                <td>-$690</td>
                <td>=$426</td>
              </tr>
            </tbody>
          </table>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitSNAP29')}</h4>
          <p>{I18n.t('BenefitsCatalog.benefitSNAP30')}</p>
          <ul>
            <li>
              {I18n.t('BenefitsCatalog.benefitSNAP31')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitSNAP32')}</li>
              </ul>
            </li>
            <li>
              {I18n.t('BenefitsCatalog.benefitSNAP33')}
              <ul>
                <li>
                  {I18n.t('BenefitsCatalog.benefitSNAP34')}
                  <ul>
                    <li>{I18n.t('BenefitsCatalog.benefitSNAP35')}</li>
                    <li>{I18n.t('BenefitsCatalog.benefitSNAP36')}</li>
                    <li>{I18n.t('BenefitsCatalog.benefitSNAP37')}</li>
                    <li>{I18n.t('BenefitsCatalog.benefitSNAP38')}</li>
                    <li>{I18n.t('BenefitsCatalog.benefitSNAP39')}</li>
                    <li>{I18n.t('BenefitsCatalog.benefitSNAP40')}</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            {I18n.t('BenefitsCatalog.benefitSNAP41') + ' '}
            {config['preGoLiveTitlesVisibile'] && (
              <Link to={baseUrl + '/application?incomplete=true'}>
                {I18n.t('BenefitsCatalog.benefitSNAP42')}
              </Link>
            )}
            {!config['preGoLiveTitlesVisibile'] && (
              <span>{I18n.t('BenefitsCatalog.benefitSNAP43')}</span>
            )}
          </p>
        </div>
      )
    case 'TANF':
      return (
        <div className="benefit benefit-tanf">
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitTANF1')}</h2>
          <p>{I18n.t('BenefitsCatalog.benefitTANF2')}</p>
          <h3>{I18n.t('BenefitsCatalog.benefitTANF3')}</h3>
          <h3>{I18n.t('BenefitsCatalog.benefitTANF4')}</h3>
          <p>{I18n.t('BenefitsCatalog.benefitTANF5')}</p>
          <h3>{I18n.t('BenefitsCatalog.benefitTANF6')}</h3>
          <p>{I18n.t('BenefitsCatalog.benefitTANF7')}</p>
          <p>{I18n.t('BenefitsCatalog.benefitTANF8')}</p>
          <h3>{I18n.t('BenefitsCatalog.benefitTANF9')}</h3>
          <p>{I18n.t('BenefitsCatalog.benefitTANF10')}</p>
          <p>{I18n.t('BenefitsCatalog.benefitTANF11')}</p>
        </div>
      )
    case 'SCLA':
      return (
        <div>
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitSCA1')}</h2>
          <p>{I18n.t('BenefitsCatalog.benefitSCA2')}</p>
        </div>
      )
    case 'LIEAP':
      return (
        <div>
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitLIEAP1')}</h2>
          <p>{I18n.t('BenefitsCatalog.benefitLIEAP2')}</p>
          <p>{I18n.t('BenefitsCatalog.benefitLIEAP3')}</p>
          <br />
          {showLIEAPhyperlinks && (
            <>
              <a
                href="/assets/pdf-files/DFA_LIEAP_1_11_20.pdf"
                target="_blank"
                className="link-line"
              >
                {I18n.t('BenefitsCatalog.LIEAPApplication')}
              </a>
              <br />
              <a
                href="/assets/pdf-files/DFA_LIEAP_1a+Rev+11_2020.pdf"
                target="_blank"
                className="link-line"
              >
                {I18n.t('BenefitsCatalog.instructionsForCompletingApplication')}
              </a>
              <br />
              <a
                href="/assets/pdf-files/LIEAP+Fact+Sheet+2021.pdf"
                target="_blank"
                className="link-line"
              >
                {I18n.t('BenefitsCatalog.LIEAP Fact Sheet')}
              </a>
            </>
          )}
        </div>
      )
    case '20DPS':
      return (
        <div>
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitCWS1')} </h2>
          <p>{I18n.t('BenefitsCatalog.benefitCWS2')}</p>
        </div>
      )

    case 'CWS':
      return (
        <div>
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitCWS3')}</h2>
          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitCWS4')}</h3>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitCWS5')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCWS6')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCWS7')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCWS8')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCWS9')}</li>
          </ul>

          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitCWS10')}</h3>
          <p>{I18n.t('BenefitsCatalog.benefitCWS11')}</p>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitCWS12')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCWS13')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCWS14')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCWS15')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCWS16')}</li>
          </ul>
        </div>
      )
    case 'APS':
      return (
        <div>
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitAPS1')}</h2>
          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitAPS2')}</h3>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitAPS1')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitAPS4')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitAPS5')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitAPS6')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitAPS7')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitAPS8')}</li>
          </ul>

          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitAPS9')}</h3>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitAPS10')}</li>
            <li>
              {I18n.t('BenefitsCatalog.benefitAPS11')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitAPS12')}</li>
                <li>{I18n.t('BenefitsCatalog.benefitAPS13')}</li>
              </ul>
            </li>
          </ul>
        </div>
      )

    case 'CC':
      return (
        <div>
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitCC')}</h2>
          <p>{I18n.t('BenefitsCatalog.benefitCC1')}</p>
          <p>{I18n.t('BenefitsCatalog.benefitCC2')}</p>

          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitCC3')}</h3>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitCC4')}</li>
            <li>
              {I18n.t('BenefitsCatalog.benefitCC5')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitCC6')}</li>
              </ul>
            </li>
            <li>
              {I18n.t('BenefitsCatalog.benefitCC7')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitCC8')}</li>
              </ul>
            </li>
          </ul>
          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitCC9')}</h3>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitCC10')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCC11')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCC12')}</li>
            <li>
              {I18n.t('BenefitsCatalog.benefitCC13')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitCC14')}</li>
                <li>{I18n.t('BenefitsCatalog.benefitCC15')}</li>
              </ul>
            </li>
          </ul>
        </div>
      )

    case 'burial':
      return (
        <div>
          <h2 className="bold">
            {' '}
            {I18n.t('BenefitsCatalog.burialAssistance')}
          </h2>
          <p>{I18n.t('BenefitsCatalog.burialAssistanceText')}</p>
        </div>
      )
    case 'MPAP':
      return (
        <div className="benefit benefit-mpap">
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitMPAP')}</h2>
          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitMPAP1')}</h4>
          <p>{I18n.t('BenefitsCatalog.benefitMPAP2')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitMPAP3')}</h4>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitMPAP4')}</li>
            <li>
              {I18n.t('BenefitsCatalog.benefitMPAP5')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitMPAP6')}</li>
                <li>{I18n.t('BenefitsCatalog.benefitMPAP7')}</li>
              </ul>
            </li>
          </ul>

          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitMPAP8')}</h3>
          <p>{I18n.t('BenefitsCatalog.benefitMPAP9')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitMPAP10')}</h4>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitMPAP11')}</li>
            <li>
              {I18n.t('BenefitsCatalog.benefitMPAP5')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitMPAP6')}</li>
                <li>{I18n.t('BenefitsCatalog.benefitMPAP7')}</li>
              </ul>
            </li>
          </ul>

          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitMPAP12')}</h3>
          <p>{I18n.t('BenefitsCatalog.benefitMPAP13')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitMPAP14')}</h4>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitMPAP15')}</li>
            <li>
              {I18n.t('BenefitsCatalog.benefitMPAP5')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitMPAP6')}</li>
                <li>{I18n.t('BenefitsCatalog.benefitMPAP7')}</li>
              </ul>
            </li>
          </ul>
        </div>
      )
    case 'MLTC':
      return (
        <div className="benefit benefit-qhp">
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitMLTC')}</h2>
          <p>{I18n.t('BenefitsCatalog.benefitMLTC1')}</p>
          <p>{I18n.t('BenefitsCatalog.benefitMLTC2')}</p>

          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitMLTC3')}</li>
            <li>
              {I18n.t('BenefitsCatalog.benefitMPAP5')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitMLTC4')}</li>
                <li>{I18n.t('BenefitsCatalog.benefitMLTC5')}</li>
              </ul>
            </li>
          </ul>

          <h3 className="bold">{I18n.t('BenefitsCatalog.benefitMLTC6')}</h3>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitMLTC7')}</h4>
          <p>{I18n.t('BenefitsCatalog.benefitMLTC8')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitMLTC9')}</h4>
          <p>{I18n.t('BenefitsCatalog.benefitMLTC10')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitMLTC11')}</h4>
          <p>{I18n.t('BenefitsCatalog.benefitMLTC12')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitMLTC13')}</h4>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitMLTC14')}</li>
            <li>
              {I18n.t('BenefitsCatalog.benefitMPAP5')}
              <ul>
                <li>{I18n.t('BenefitsCatalog.benefitMLTC4')}</li>
              </ul>
            </li>
          </ul>

          <h5>{I18n.t('BenefitsCatalog.benefitMLTC15')}</h5>
          <ul>
            <li>
              <a
                href="https://dhhr.wv.gov/bms/Pages/default.aspx"
                target="_blank"
                className="link-line"
              >
                {I18n.t('BenefitsCatalog.benefitMLTC16')}
              </a>
            </li>
          </ul>
        </div>
      )
    case 'CHIP':
      return (
        <div className="benefit benefit-chip">
          <h2 className="bold">{I18n.t('BenefitsCatalog.benefitCHIP')}</h2>
          <p>{I18n.t('BenefitsCatalog.benefitCHIP1')}</p>
          <p>{I18n.t('BenefitsCatalog.benefitCHIP2')}</p>

          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitCHIP3')}</h4>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP4')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP5')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP6')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP7')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP8')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP9')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP10')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP11')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP12')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP13')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP14')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP15')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP16')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP17')}</li>
          </ul>
          <h4 className="bold">{I18n.t('BenefitsCatalog.benefitCHIP18')}</h4>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP19')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP20')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP21')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP22')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP23')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCHIP24')}</li>
          </ul>
          <h5>{I18n.t('BenefitsCatalog.benefitCHIP25')}</h5>
          <ul>
            <li>
              <a
                href="https://chip.wv.gov/Pages/default.aspx"
                target="_blank"
                className="link-line"
              >
                {I18n.t('BenefitsCatalog.benefitCHIP26')}
              </a>
            </li>
          </ul>
        </div>
      )

    case 'CSS':
      return (
        <div className="benefit benefit-chip">
          <p>{I18n.t('BenefitsCatalog.benefitCSS')}</p>
          <ul>
            <li>{I18n.t('BenefitsCatalog.benefitCSS1')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCSS2')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCSS3')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCSS4')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCSS5')}</li>
            <li>{I18n.t('BenefitsCatalog.benefitCSS6')}</li>
          </ul>

          <p>{I18n.t('BenefitsCatalog.benefitCSS7')}</p>
          <p>{I18n.t('BenefitsCatalog.benefitCSS8')}</p>
        </div>
      )
  }
}

export class BenefitBlock extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      open: this.props.open
    }
  }

  _favoriteToggleBtn = key => {
    const myFavorites = this.props.myFavorites ? this.props.myFavorites : []

    if (myFavorites.indexOf(key) > -1) {
      return (
        <button
          className="btn link my-benefit active"
          onClick={() => {
            this.props.removeMyFavorite(key)
          }}
        >
          {I18n.t('General.removeFavorite')}
        </button>
      )
    } else {
      return (
        <button
          className="btn link my-benefit"
          onClick={() => {
            this.props.addMyFavorite(key)
          }}
        >
          {I18n.t('General.addFavorite')}
        </button>
      )
    }
  }

  _expandButton = () => {
    if (!this.state.open)
      return (
        <button
          className="btn link read-more"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          {I18n.t('General.readMore')}
        </button>
      )
    else
      return (
        <button
          className="btn link read-more active"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          {I18n.t('General.showLess')}
        </button>
      )
  }

  render() {
    const benefitKey = this.props.benefitKey

    const { title, icon, summary } = _getPreInformation(benefitKey)

    let favoritesToggle
    if (this.props.enableFavorites)
      favoritesToggle = this._favoriteToggleBtn(benefitKey)
    else favoritesToggle = <div />
    const customStyle = { width: '100%' }
    return (
      <div className="my-benefits-block">
        <h3 className="title">
          {title}
          {icon}
        </h3>
        {favoritesToggle}
        <hr />
        <p style={customStyle}>{summary}</p>
        {this._expandButton()}
        <Panel collapsible expanded={this.state.open}>
          {BenefitContent(benefitKey)}
          <button className="btn link scroll-top" onClick={scrollToTop}>
            Back To Top
          </button>
        </Panel>
      </div>
    )
  }
}

class BenefitsView extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    let content = []
    if (this.props.benefits.length >= 1) {
      for (let i = 0; i < this.props.benefits.length; i++) {
        content.push(
          <BenefitBlock
            key={v4()}
            benefitKey={this.props.benefits[i]}
            open={this.props.expanded}
            myFavorites={this.props.myFavorites}
            addMyFavorite={this.props.addMyFavorite}
            removeMyFavorite={this.props.removeMyFavorite}
            enableFavorites={this.props.enableFavorites}
          />
        )
      }
    } else {
      content.push(<h3 key={v4()}>No Benefits Available</h3>)
    }

    return <div>{content}</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    myFavorites: state.myFavorites,
    isAuthenticated: state.auth.isAuthenticated
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addMyFavorite: key => {
      dispatch(actions.addMyFavorite(key))
    },
    removeMyFavorite: key => {
      dispatch(actions.removeMyFavorite(key))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsView)
