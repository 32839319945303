import * as React from 'react'
import * as _ from 'lodash'

function selectValue(value, selected, all) {
  const at = all.indexOf(value)
  const updated = selected.slice(0, at).concat(value, selected.slice(at))
  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b))
}

function deselectValue(value, selected) {
  return selected.filter(v => v !== value)
}

function CheckboxesWidgetWithHelp(props) {
  let snapIndex
  const newProps = props

  if (
    newProps.formContext.hasOwnProperty('prgmDateSCLA') &&
    newProps.formContext.prgmDateSCLA
  ) {
    newProps.options.enumOptions = newProps.options.enumOptions.filter(function(
      item
    ) {
      return item.value !== 'SCAL'
    })
    newProps.schema.items.enumNamesHelp = newProps.schema.items.enumNamesHelp.filter(
      function(item) {
        return !item.includes(
          'The School Clothing Allowance (SCA) program provides vouchers'
        )
      }
    )
  }

  if (
    newProps.formContext.hasOwnProperty('prgmDateHMEN') &&
    newProps.formContext.prgmDateHMEN
  ) {
    newProps.options.enumOptions = newProps.options.enumOptions.filter(function(
      item
    ) {
      return item.value !== 'HMEN'
    })
    newProps.schema.items.enumNamesHelp = newProps.schema.items.enumNamesHelp.filter(
      function(item) {
        return !item.includes(
          'The Low Income Energy Assistance Program (LIEAP) assists eligible households'
        )
      }
    )
  }

  if (
    newProps.formContext.hasOwnProperty('hideWVWORKS') &&
    newProps.formContext.hideWVWORKS
  ) {
    newProps.options.enumOptions = newProps.options.enumOptions.filter(function(
      item
    ) {
      return item.value !== 'WVWORKS'
    })
    newProps.schema.items.enumNamesHelp = newProps.schema.items.enumNamesHelp.filter(
      function(item) {
        return !item.includes(
          "WV WORKS: West Virginia's Temporary Assistance for Needy Families (TANF) Program is WV WORKS. WV WORKS is to provide temporary monthly cash assistance to eligible needy families with children and to provide parents with job preparation, work, and support services to enable them to leave the program and become self-sufficient. To be eligible for the WV WORKS benefits there must be a child who is under the age of 19 and is a natural, step, or adoptive child of the applicant. The benefit requirements consist of the application completion, Orientation to WV WORKS and a face-to-face interview. Once you have completed your on-line application, you will be contacted by a case manager to schedule your Orientation. WV WORKS Caretaker Relative Benefits: WV WORKS Caretaker Relative Benefits are to assist children that have been placed in a relative's care who are not natural, adoptive or step parents. To be eligible for this benefit the child must be under the age of 19 and is a relative of the applicant. Once you have completed your on-line application, you will be contacted by a case manager to schedule your face-to-face interview."
        )
      }
    )
  }

  const presumptiveEligibilityWorker =
    props.formContext.selectedEntitlements &&
    props.formContext.selectedEntitlements.find(entitlement => {
      return entitlement === 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
    })
  if (props.id) {
    if (
      presumptiveEligibilityWorker &&
      props.id === 'AppIntakePreQuestionnaire_Application_benefitCategorieslist'
    ) {
      const healthOption = newProps.options.enumOptions[3]
      newProps.options.enumOptions = [{ ...healthOption, disabled: true }]
    }
  }

  if (
    newProps.formContext.formData.Application &&
    newProps.formContext.formData.Application.someoneAsstYouFlg &&
    newProps.formContext.formData.Application.someoneAsstYouFlg === 'N' &&
    props.id === 'AppIntakePreQuestionnaire_Application_benefitCategorieslist'
  ) {
    newProps.options.enumOptions = newProps.options.enumOptions.map(option => {
      if (option.value === 'CHCR') {
        return {
          ...option,
          disabled: true
        }
      } else {
        return {
          ...option
        }
      }
    })
  }

  if (props.id) {
    if (
      (props.formContext.roleName === 'Community Partner' ||
        props.formContext.roleName === 'CP Admin') &&
      props.id === 'AppIntakePreQuestionnaire_Application_benefitCategorieslist'
    ) {
      newProps.options.enumOptions.splice(0, 3)
      newProps.schema.items.enumNamesHelp.splice(0, 3)
    }
  }

  if (props.options.removable) {
    const index =
      props.id.match('(?:.*)clients_([0-9]+)_?') &&
      props.id.match('(?:.*)clients_([0-9]+)_?')[1]
    if (index === '0') {
    } else {
      newProps.options.enumOptions.splice(1, 1)
    }
  }
  const {
    id,
    disabled,
    options,
    value,
    autofocus,
    readonly,
    onChange,
    schema
  } = newProps
  const { enumOptions, inline } = options
  let schemaFromProps = schema.items.enumNamesHelp
  let helpIconArray = []
  let showToolTip = id1 => {
    let tip = document.getElementById(id1.currentTarget.nextElementSibling.id)
    const overFlowWidth =
      tip.getBoundingClientRect().bottom -
      document.getElementsByClassName('body-content')[0].getBoundingClientRect()
        .bottom
    if (overFlowWidth > 0) {
      tip.style.top = '-' + overFlowWidth + 'px'
    }
    tip.classList.add('inline-tooltip')
  }

  let hideTooltip = () => {
    var tips = document.getElementsByClassName('inline-tooltip')
    for (var i = 0; i < tips.length; i++) {
      tips[i].classList.remove('inline-tooltip')
    }
  }

  if (props.formContext.isIncomplete && props.formContext.isAppIntake) {
    enumOptions.forEach((element, index) => {
      if (element.value == 'FDNT') {
        snapIndex = index
      }
    })
    schemaFromProps = [schemaFromProps[snapIndex]]

    _.remove(enumOptions, function(option) {
      return option.value != 'FDNT'
    })

    let snapOption = _.find(enumOptions, function(option) {
      return option.value === 'FDNT'
    })
    if (snapOption) {
      snapOption.disabled = true
    }
  }

  if (schemaFromProps !== undefined) {
    schemaFromProps.map((helpText, index) => {
      helpIconArray.push(
        <span
          className="tooltip-text-size tooltip-test noJS"
          id={'tooltip_' + `${id}_${index}`}
        >
          {helpText}
        </span>
      )
    })
  }
  return (
    <div className="checkboxes" id={id}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id="SVGsprites"
      >
        <defs>
          <g
            id="icon-exclamation"
            aria-labelledby="title-exclamation"
            aria-describedby="desc-exclamation"
            role="img"
          >
            <title id="title-exclamation" />
            <desc id="desc-exclamation" />
            <path d="M41 4H24a1 1 0 0 0-1 1l2 36 1 1h12l1-1 2-36V4zM38 46H26l-1 1v12l1 1h12l1-1V47l-1-1z" />
          </g>
        </defs>
      </svg>
      {enumOptions.map((option, index) => {
        const checked = value.indexOf(option.value) !== -1
        const disabledCls = disabled || readonly ? 'disabled' : ''
        const checkbox = (
          <span>
            <input
              type="checkbox"
              id={`${id}_${index}`}
              aria-describedby={
                'tooltip_button-' +
                `${id}_${index}` +
                ' ' +
                'tooltip_' +
                `${id}_${index}`
              }
              checked={checked}
              disabled={disabled || readonly || option.disabled}
              autoFocus={autofocus && index === 0}
              onChange={event => {
                const all = enumOptions.map(({ value }) => value)
                if (event.target.checked) {
                  onChange(selectValue(option.value, value, all))
                } else {
                  onChange(deselectValue(option.value, value))
                }
              }}
            />
            <span>{option.label}</span>
          </span>
        )
        const helpIcon = (
          <span>
            {helpIconArray.length > 0 && (
              <span>
                <button
                  type="button"
                  className="tooltip1 tooltip-style"
                  id={'tooltip_button-' + `${id}_${index}`}
                  aria-label="Tooltip, "
                  aria-labelledby={
                    'tooltip_button-' +
                    `${id}_${index}` +
                    ' ' +
                    'tooltip_' +
                    `${id}_${index}`
                  }
                  onFocus={showToolTip}
                  onMouseEnter={showToolTip}
                  onMouseOver={showToolTip}
                  onKeyUp={event => {
                    if (event.keyCode === 27) {
                      hideTooltip()
                    } else {
                      showToolTip
                    }
                  }}
                  onBlur={hideTooltip}
                  onMouseOut={hideTooltip}
                >
                  <svg viewBox="0 0 64 64" focusable="false">
                    <use
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="#icon-exclamation"
                    >
                      {' '}
                    </use>
                  </svg>
                </button>
                {helpIconArray[index]}
              </span>
            )}
          </span>
        )
        return inline ? (
          <div className="checkstyle">
            <label
              key={index}
              htmlFor={id + '_' + index}
              className={`checkbox-inline ${disabledCls}`}
            >
              {checkbox}
            </label>{' '}
            <span className="tooltipstyle">{helpIcon}</span>
          </div>
        ) : (
          <div key={index} className={`checkbox ${disabledCls}`}>
            <label htmlFor={id + '_' + index} className="checkstyle1">
              {checkbox}
            </label>{' '}
            <span className="tooltipstyle">{helpIcon}</span>
          </div>
        )
      })}
    </div>
  )
}

;(CheckboxesWidgetWithHelp as any).defaultProps = {
  autofocus: false,
  options: {
    inline: false
  }
}

export default CheckboxesWidgetWithHelp
