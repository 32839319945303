import * as React from 'react'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as serviceHelper from '../src/components/utils'
import * as _ from 'lodash' // extra functionality
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form' // json schema form library
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import BenefitsView from '../src/components/natives/BenefitsView1'
import MyFavoritesView from '../src/components/natives/MyFavoritesView'
import { connect } from 'react-redux'
import * as actions from '@optum-wvie/dynamic-ui-framework/src/actions'
import { I18n } from 'react-redux-i18n'
import * as wvActions from '../../../actions'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import {
  Tab,
  Tabs,
  Row,
  Col,
  Popover,
  Overlay,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'
import * as JSONSchemaEN from './benefitsfinder.json'
import * as JSONSchemaES from './benefitsfinder.es.json'
import { v4 } from 'uuid'
import MessageModal from '@optum-wvie/dynamic-ui-framework/src/components/natives/MessageModal'
import validateFormData from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/validate'
import ErrorList from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/ErrorList'
import '!style-loader!css-loader!../src/customStyles.css'
import customWidgets from '../src/components/widgets/index'
import { Redirect } from 'react-router-dom'
import * as validator from '../src/validation'
import { Modal, Button } from 'react-bootstrap'
import { UnauthorizedError } from '../src/components/utils'
import { Panel } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Panel'

const errorMessages = {
  en: [
    'Please choose a value for "Please select the programs for which you would like to screen:"',
    'You answered "Yes" to "Does your household reside in West Virginia?". Please provide an answer to "If yes, in what county does your household reside?".',
    'You may only provide a response to "If yes, in what county does your household reside?", if you answered "Yes" to "Does your household reside in West Virginia?"',
    'Please choose a value for "How old are the people in the household (check all that apply)?"',
    'You selected too many options for the question, "How old are the people in the household (check all that apply)?"',
    'Please choose a value for "If yes, what type of school:"',
    'Please choose a value for "If yes, how many?"',
    'You may only provide a response to "If yes, what type of school: ", if you answered "Yes" to "Does anyone in your household attend school?".',
    'Please choose a value for "How much money does the household earn per month?"',
    'Please choose a value for "How much money does the household get other than earnings per month?"',
    'Please choose a value for "Are the children needing child care U.S. Citizens or Qualified Aliens?"',
    'You\'ve given amount for "How much does the household pay for medical expenses for individuals 60 and older and/or disabled individuals per month?", but did not indicate that anyone in the household is over the age of 60 (General Questions section) or disabled (Health section).'
  ],
  es: [
    'Por favor, elija un valor para "Por favor, seleccione los programas que le gustaría examinar:"',
    'Usted respondió "Sí" a "¿Reside su hogar en Virginia Occidental?". Proporcione una respuesta a "En caso afirmativo, ¿en qué condado reside su hogar?".',
    'Solo puede proporcionar una respuesta a "En caso afirmativo, ¿en qué condado reside su hogar?", Si respondió "Sí" a "¿Reside su hogar en Virginia Occidental?"',
    'Por favor, elija un valor para "¿Qué edad tienen las personas en el hogar (marque todo lo que corresponda)?"',
    'Seleccionó demasiadas opciones para la pregunta, "¿Qué edad tienen las personas en el hogar (marque todas las que correspondan)?"',
    'Por favor elija un valor para "En caso afirmativo, qué tipo de escuela:"',
    'Por favor, elija un valor para "En caso afirmativo, ¿cuántos?"',
    'Solo puede proporcionar una respuesta a "En caso afirmativo, qué tipo de escuela: ", si respondió "Sí" a "¿Alguien en su hogar asiste a la escuela?"',
    'Por favor elija un valor para "¿Cuánto dinero gana el hogar por mes?"',
    'Por favor, elija un valor para "¿Cuánto dinero recibe el hogar aparte de los ingresos por mes?"',
    'Elija un valor para "¿Los niños que necesitan cuidado infantil son ciudadanos estadounidenses o extranjeros calificados?"',
    'Usted proporcionó una cantidad para "¿Cuánto paga el hogar por gastos médicos para personas mayores de 60 años y/o personas discapacitadas por mes?", pero no indicó que alguien en el hogar tenga más de 60 años (General Sección de preguntas) o deshabilitado (sección de Salud).'
  ]
}
declare var process

const baseUrl = process.env.PUBLIC_URL

/* TODO: The translation function is based on this format*/
const blankAnswerJSON = {
  core: [
    {
      quesId: 1,
      quesText:
        'Please select the programs for which you would like to screen:',
      quesCategory: 'BF',
      quesCategorySection: 'core',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'checkbox',
      benefitQuestionOptions: [
        {
          name: 'Select All',
          booleanValue: false,
          textValue: 'Screen',
          sortOrder: 0
        },
        {
          name: 'Health Care',
          booleanValue: false,
          textValue: 'Healthcare',
          sortOrder: 0
        },
        {
          name: 'Child Care',
          booleanValue: false,
          textValue: 'Childcare',
          sortOrder: 0
        },
        {
          name: 'Family Assistance',
          booleanValue: false,
          textValue: 'WVWorks',
          sortOrder: 0
        },
        {
          name: 'Home Energy Services',
          booleanValue: false,
          textValue: 'LIEAP',
          sortOrder: 0
        },
        {
          name: 'Food & Nutrition',
          booleanValue: false,
          textValue: 'SNAP',
          sortOrder: 0
        },
        {
          name: 'Long Term Care (Nursing or In-Home Care)',
          booleanValue: false,
          textValue: 'Nursing',
          sortOrder: 0
        },
        {
          name: 'Medicare Premium Assistance',
          booleanValue: false,
          textValue: 'Medicare',
          sortOrder: 0
        },
        {
          name: 'School Clothing Allowance',
          booleanValue: false,
          textValue: 'SCA',
          sortOrder: 0
        },
        {
          name: 'Child Support Services',
          booleanValue: false,
          textValue: 'CSP',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    }
  ],
  general: [
    {
      quesId: 2,
      quesText: 'Does your household reside in West Virginia?',
      quesCategory: 'BF',
      quesCategorySection: 'general',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 3,
      quesText: 'What county do you live in?',
      quesCategory: 'BF',
      quesCategorySection: 'general',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Anoka',
          booleanValue: false,
          textValue: 'ano',
          sortOrder: 0
        },
        {
          name: 'Dakota',
          booleanValue: false,
          textValue: 'dak',
          sortOrder: 0
        },
        {
          name: 'Hennepin',
          booleanValue: false,
          textValue: 'hen',
          sortOrder: 0
        },
        {
          name: 'Olmsted',
          booleanValue: false,
          textValue: 'olm',
          sortOrder: 0
        },
        {
          name: 'Ramsey',
          booleanValue: false,
          textValue: 'ram',
          sortOrder: 0
        },
        {
          name: 'St Louis',
          booleanValue: false,
          textValue: 'stl',
          sortOrder: 0
        },
        {
          name: 'Scott',
          booleanValue: false,
          textValue: 'sco',
          sortOrder: 0
        },
        {
          name: 'Stearns',
          booleanValue: false,
          textValue: 'ste',
          sortOrder: 0
        },
        {
          name: 'Wright',
          booleanValue: false,
          textValue: 'wri',
          sortOrder: 0
        },
        {
          name: 'Washington',
          booleanValue: false,
          textValue: 'was',
          sortOrder: 0
        },
        {
          name: 'Barbour',
          booleanValue: false,
          textValue: 'Barbour',
          sortOrder: 0
        },
        {
          name: 'Berkeley',
          booleanValue: false,
          textValue: 'Berkeley',
          sortOrder: 0
        },
        {
          name: 'Boone',
          booleanValue: false,
          textValue: 'Boone',
          sortOrder: 0
        },
        {
          name: 'Braxton',
          booleanValue: false,
          textValue: 'Braxton',
          sortOrder: 0
        },
        {
          name: 'Brooke',
          booleanValue: false,
          textValue: 'Brooke',
          sortOrder: 0
        },
        {
          name: 'Cabell',
          booleanValue: false,
          textValue: 'Cabell',
          sortOrder: 0
        },
        {
          name: 'Calhoun',
          booleanValue: false,
          textValue: 'Calhoun',
          sortOrder: 0
        },
        {
          name: 'Clay',
          booleanValue: false,
          textValue: 'Clay',
          sortOrder: 0
        },
        {
          name: 'Doddridge',
          booleanValue: false,
          textValue: 'Doddridge',
          sortOrder: 0
        },
        {
          name: 'Fayette',
          booleanValue: false,
          textValue: 'Fayette',
          sortOrder: 0
        },
        {
          name: 'Gilmer',
          booleanValue: false,
          textValue: 'Gilmer',
          sortOrder: 0
        },
        {
          name: 'Grant',
          booleanValue: false,
          textValue: 'Grant',
          sortOrder: 0
        },
        {
          name: 'Greenbrier',
          booleanValue: false,
          textValue: 'Greenbrier',
          sortOrder: 0
        },
        {
          name: 'Hampshire',
          booleanValue: false,
          textValue: 'Hampshire',
          sortOrder: 0
        },
        {
          name: 'Hancock',
          booleanValue: false,
          textValue: 'Hancock',
          sortOrder: 0
        },
        {
          name: 'Hardy',
          booleanValue: false,
          textValue: 'Hardy',
          sortOrder: 0
        },
        {
          name: 'Harrison',
          booleanValue: false,
          textValue: 'Harrison',
          sortOrder: 0
        },
        {
          name: 'Jackson',
          booleanValue: false,
          textValue: 'Jackson',
          sortOrder: 0
        },
        {
          name: 'Jefferson',
          booleanValue: false,
          textValue: 'Jefferson',
          sortOrder: 0
        },
        {
          name: 'Kanawha',
          booleanValue: false,
          textValue: 'Kanawha',
          sortOrder: 0
        },
        {
          name: 'Lewis',
          booleanValue: false,
          textValue: 'Lewis',
          sortOrder: 0
        },
        {
          name: 'Lincoln',
          booleanValue: false,
          textValue: 'Lincoln',
          sortOrder: 0
        },
        {
          name: 'Logan',
          booleanValue: false,
          textValue: 'Logan',
          sortOrder: 0
        },
        {
          name: 'Marion',
          booleanValue: false,
          textValue: 'Marion',
          sortOrder: 0
        },
        {
          name: 'Marshall',
          booleanValue: false,
          textValue: 'Marshall',
          sortOrder: 0
        },
        {
          name: 'Mason',
          booleanValue: false,
          textValue: 'Mason',
          sortOrder: 0
        },
        {
          name: 'McDowell',
          booleanValue: false,
          textValue: 'McDowell',
          sortOrder: 0
        },
        {
          name: 'Mercer',
          booleanValue: false,
          textValue: 'Mercer',
          sortOrder: 0
        },
        {
          name: 'Mineral',
          booleanValue: false,
          textValue: 'Mineral',
          sortOrder: 0
        },
        {
          name: 'Mingo',
          booleanValue: false,
          textValue: 'Mingo',
          sortOrder: 0
        },
        {
          name: 'Monongalia',
          booleanValue: false,
          textValue: 'Monongalia',
          sortOrder: 0
        },
        {
          name: 'Monroe',
          booleanValue: false,
          textValue: 'Monroe',
          sortOrder: 0
        },
        {
          name: 'Morgan',
          booleanValue: false,
          textValue: 'Morgan',
          sortOrder: 0
        },
        {
          name: 'Nicholas',
          booleanValue: false,
          textValue: 'Nicholas',
          sortOrder: 0
        },
        {
          name: 'Ohio',
          booleanValue: false,
          textValue: 'Ohio',
          sortOrder: 0
        },
        {
          name: 'Pendleton',
          booleanValue: false,
          textValue: 'Pendleton',
          sortOrder: 0
        },
        {
          name: 'Pleasants',
          booleanValue: false,
          textValue: 'Pleasants',
          sortOrder: 0
        },
        {
          name: 'Pocahontas',
          booleanValue: false,
          textValue: 'Pocahontas',
          sortOrder: 0
        },
        {
          name: 'Preston',
          booleanValue: false,
          textValue: 'Preston',
          sortOrder: 0
        },
        {
          name: 'Putnam',
          booleanValue: false,
          textValue: 'Putnam',
          sortOrder: 0
        },
        {
          name: 'Raleigh',
          booleanValue: false,
          textValue: 'Raleigh',
          sortOrder: 0
        },
        {
          name: 'Randolph',
          booleanValue: false,
          textValue: 'Randolph',
          sortOrder: 0
        },
        {
          name: 'Ritchie',
          booleanValue: false,
          textValue: 'Ritchie',
          sortOrder: 0
        },
        {
          name: 'Roane',
          booleanValue: false,
          textValue: 'Roane',
          sortOrder: 0
        },
        {
          name: 'Summers',
          booleanValue: false,
          textValue: 'Summers',
          sortOrder: 0
        },
        {
          name: 'Taylor',
          booleanValue: false,
          textValue: 'Taylor',
          sortOrder: 0
        },
        {
          name: 'Tucker',
          booleanValue: false,
          textValue: 'Tucker',
          sortOrder: 0
        },
        {
          name: 'Tyler',
          booleanValue: false,
          textValue: 'Tyler',
          sortOrder: 0
        },
        {
          name: 'Upshur',
          booleanValue: false,
          textValue: 'Upshur',
          sortOrder: 0
        },
        {
          name: 'Wayne',
          booleanValue: false,
          textValue: 'Wayne',
          sortOrder: 0
        },
        {
          name: 'Webster',
          booleanValue: false,
          textValue: 'Webster',
          sortOrder: 0
        },
        {
          name: 'Wetzel',
          booleanValue: false,
          textValue: 'Wetzel',
          sortOrder: 0
        },
        {
          name: 'Wirt',
          booleanValue: false,
          textValue: 'Wirt',
          sortOrder: 0
        },
        {
          name: 'Wood',
          booleanValue: false,
          textValue: 'Wood',
          sortOrder: 0
        },
        {
          name: 'Wyoming',
          booleanValue: false,
          textValue: 'Wyoming',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 4,
      quesText: 'How many people are in the household?',
      quesCategory: 'BF',
      quesCategorySection: 'general',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: '1',
          booleanValue: false,
          textValue: '1',
          sortOrder: 0
        },
        {
          name: '2',
          booleanValue: false,
          textValue: '2',
          sortOrder: 0
        },
        {
          name: '3',
          booleanValue: false,
          textValue: '3',
          sortOrder: 0
        },
        {
          name: '4',
          booleanValue: false,
          textValue: '4',
          sortOrder: 0
        },
        {
          name: '5',
          booleanValue: false,
          textValue: '5',
          sortOrder: 0
        },
        {
          name: '6',
          booleanValue: false,
          textValue: '6',
          sortOrder: 0
        },
        {
          name: '7',
          booleanValue: false,
          textValue: '7',
          sortOrder: 0
        },
        {
          name: '8',
          booleanValue: false,
          textValue: '8',
          sortOrder: 0
        },
        {
          name: '9',
          booleanValue: false,
          textValue: '9',
          sortOrder: 0
        },
        {
          name: '10',
          booleanValue: false,
          textValue: '10',
          sortOrder: 0
        },
        {
          name: '11',
          booleanValue: false,
          textValue: '11',
          sortOrder: 0
        },
        {
          name: '12',
          booleanValue: false,
          textValue: '12',
          sortOrder: 0
        },
        {
          name: '13',
          booleanValue: false,
          textValue: '13',
          sortOrder: 0
        },
        {
          name: '14',
          booleanValue: false,
          textValue: '14',
          sortOrder: 0
        },
        {
          name: '15',
          booleanValue: false,
          textValue: '15',
          sortOrder: 0
        },
        {
          name: '16',
          booleanValue: false,
          textValue: '16',
          sortOrder: 0
        },
        {
          name: '17',
          booleanValue: false,
          textValue: '17',
          sortOrder: 0
        },
        {
          name: '18',
          booleanValue: false,
          textValue: '18',
          sortOrder: 0
        },
        {
          name: '19',
          booleanValue: false,
          textValue: '19',
          sortOrder: 0
        },
        {
          name: '20',
          booleanValue: false,
          textValue: '20',
          sortOrder: 0
        },
        {
          name: '21',
          booleanValue: false,
          textValue: '21',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 5,
      quesText:
        'How old are the people in the household (check all that apply)?',
      quesCategory: 'BF',
      quesCategorySection: 'general',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'checkbox',
      benefitQuestionOptions: [
        {
          name: '0 - 4 years',
          booleanValue: false,
          textValue: '0 - 4 years',
          sortOrder: 0
        },
        {
          name: '5 - 12 years',
          booleanValue: false,
          textValue: '5 - 12 years',
          sortOrder: 0
        },
        {
          name: '13 - 18 years',
          booleanValue: false,
          textValue: '13 - 18 years',
          sortOrder: 0
        },
        {
          name: '19 - 59 years',
          booleanValue: false,
          textValue: '19 - 59 years',
          sortOrder: 0
        },
        {
          name: '60 - 64 years',
          booleanValue: false,
          textValue: '60 - 64 years',
          sortOrder: 0
        },
        {
          name: '65 years or older',
          booleanValue: false,
          textValue: '65 years or older',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    }
  ],
  household: [
    {
      quesId: 6,
      quesText:
        'Does anyone in your household require a level of care compatable to the level provided in a nursing facility?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 7,
      quesText:
        'Does anyone in your household have intellectual and/or developmental disability?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 8,
      quesText:
        'Does anyone in your household required or receive the type of in-home care which is usually provided in a medical or psychiatric hospital and/or nursing facility?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 9,
      quesText: 'Is any adult in the household the parent of a minor child?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 10,
      quesText: 'Does anyone in your household attend school?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 11,
      quesText: 'If yes, what type of school:',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'checkbox',
      benefitQuestionOptions: [
        {
          name: 'Elementary School',
          booleanValue: false,
          textValue: 'Elementary School',
          sortOrder: 0
        },
        {
          name: 'Middle School',
          booleanValue: false,
          textValue: 'Middle School',
          sortOrder: 0
        },
        {
          name: 'High School',
          booleanValue: false,
          textValue: 'High School',
          sortOrder: 0
        },
        {
          name: 'College, University, or Vocational School',
          booleanValue: false,
          textValue: 'College, University, or Vocational School',
          sortOrder: 0
        },
        {
          name: 'School for people with disabilities',
          booleanValue: false,
          textValue: 'School for people with disabilities',
          sortOrder: 0
        },
        {
          name: 'Other',
          booleanValue: false,
          textValue: 'Other',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 12,
      quesText:
        'How much does the household pay for the rent/mortgage per month?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'text',
      benefitQuestionOptions: [
        {
          name: 'mortgagePerMonth',
          booleanValue: false,
          textValue: '',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 13,
      quesText:
        'Is anyone in household billed seperately for heating or cooling expenses?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 14,
      quesText: 'Does the household pay for other utilities?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 15,
      quesText: 'If yes, how many?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'radio',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Yes',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'No',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 26,
      quesText:
        'Does anyone in your household have an existing Spousal Support order?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 27,
      quesText:
        'Is any child in the household under court supervision or identified as special needs (Y/N) *',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 28,
      quesText:
        'Are the children needing child care US Citizens or Qualified Aliens?',
      quesCategory: 'BF',
      quesCategorySection: 'household',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    }
  ],
  health: [
    {
      quesId: 16,
      quesText: 'Is anyone pregnant?',
      quesCategory: 'BF',
      quesCategorySection: 'health',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 17,
      quesText: 'Is anyone disabled?',
      quesCategory: 'BF',
      quesCategorySection: 'health',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 18,
      quesText: 'Is any one blind?',
      quesCategory: 'BF',
      quesCategorySection: 'health',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 19,
      quesText:
        'Does anyone in the household have Medicare (Red, White, and Blue card)?',
      quesCategory: 'BF',
      quesCategorySection: 'health',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'select',
      benefitQuestionOptions: [
        {
          name: 'Yes',
          booleanValue: false,
          textValue: 'Y',
          sortOrder: 0
        },
        {
          name: 'No',
          booleanValue: false,
          textValue: 'N',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    }
  ],
  income: [
    {
      quesId: 20,
      quesText: 'How much money does the household earn per month?',
      quesCategory: 'BF',
      quesCategorySection: 'income',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'text',
      benefitQuestionOptions: [
        {
          name: 'moneyPerMonth',
          booleanValue: false,
          textValue: '',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 21,
      quesText:
        'How much money does the household get other than earnings per month?',
      quesCategory: 'BF',
      quesCategorySection: 'income',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'text',
      benefitQuestionOptions: [
        {
          name: 'otherEarnings',
          booleanValue: false,
          textValue: '',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 22,
      quesText:
        'How much does the household pay for child/adult care per month?',
      quesCategory: 'BF',
      quesCategorySection: 'income',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'text',
      benefitQuestionOptions: [
        {
          name: 'childCare',
          booleanValue: false,
          textValue: '',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 23,
      quesText:
        'How much does the household pay for medical expenses for individuals 60 and older and/or disabled individuals per month?',
      quesCategory: 'BF',
      quesCategorySection: 'income',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'text',
      benefitQuestionOptions: [
        {
          name: 'expenseForIndividuals',
          booleanValue: false,
          textValue: '',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 24,
      quesText:
        'How much does the household pay for child support for children outside the home per month?',
      quesCategory: 'BF',
      quesCategorySection: 'income',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'text',
      benefitQuestionOptions: [
        {
          name: 'childSupport',
          booleanValue: false,
          textValue: '',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    },
    {
      quesId: 25,
      quesText: "What is the total value of the household's assets?",
      quesCategory: 'BF',
      quesCategorySection: 'income',
      quesRequiredInd: 'Y',
      value: '',
      quesFormElementType: 'text',
      benefitQuestionOptions: [
        {
          name: 'householdsAsset',
          booleanValue: false,
          textValue: '',
          sortOrder: 0
        }
      ],
      quesOrder: 0
    }
  ]
}

const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  tabs: Fields.TabsField,
  table: Fields.DataTableField
}

const widgets = {
  radio: Widgets.CustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkboxes: customWidgets.CustomCheckboxesWidgetWithHelp,
  'alt-date': Widgets.DatePickerWidget,
  review: Widgets.ReviewWidget,
  dollar: Widgets.DollarWidget,
  validatedText: Widgets.ValidatedTextWidget,
  checkboxesWithoutHelp: Widgets.CustomCheckboxesWidget
}

class BenefitFinderHeader extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="row">
        <div className="headerpadding">
          <h1 className="heading print">
            <i className="myglyphicon-halflings myglyphicon-compass myaccount-icon" />{' '}
            {I18n.t('General.title19')}
          </h1>
        </div>
      </div>
    )
  }
}

interface VerticalTabsProps {
  _changeTab: any
  activeTab: number
  tabs: any
  temp1: any
  mainTab: number
  hasError: boolean
}

class VerticalTabs extends React.Component<any, {}> {
  constructor(props: any) {
    super(props)
  }

  _changeTab = (val: number): void => {
    this.props._changeTab(val)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  isEqualArrayElelments(chkArr, inputArr) {
    var count = 0
    if (chkArr.length > 0) {
      for (var i = 0; i < inputArr.length; i++) {
        for (var j = 0; j < chkArr.length; j++) {
          if (inputArr[i] === chkArr[j]) {
            return true
          }
        }
      }
    }
    return false
  }

  isEqualArrayElelments1(chkArr, inputArr) {
    var count = 0
    var flag = false
    chkArr.sort()
    inputArr.sort()
    if (chkArr.length > 2) {
      flag = false
    }
    if (chkArr.length == 1) {
      if (chkArr[0] == inputArr[0] || chkArr[0] == inputArr[1]) {
        flag = true
      }
    } else if (chkArr.length == inputArr.length) {
      for (var i = 0; i < chkArr.length; i++) {
        if (inputArr[i] != chkArr[i]) {
          flag = false
          break
        } else {
          flag = true
        }
      }
    }
    return flag
  }

  render() {
    const tabs = this.props.tabs
    const activeTab = this.props.activeTab
    const hasError = this.props.hasError
    let tabsHTML = [] // tab info into html tagged tabs
    let li_classes = [] // li tag classes
    li_classes = _.fill(Array(tabs.length), '')
    li_classes[activeTab] = li_classes[activeTab] + ' ' + 'active'
    //Not showing final form as that will be displayed in ViewResults component.
    let showTabs = [tabs[0]]
    let noTabs = []
    const style = { width: '100%' }

    if (this.props.temp1.length > 0) {
      showTabs.push(tabs[1])
      let healthEnabledFlag = this.isEqualArrayElelments(this.props.temp1, [
        _HEALTHCARE,
        _SNAP,
        _MEDICARE
      ])
      let householdDisabledFlag = this.isEqualArrayElelments1(
        this.props.temp1,
        [_MEDICARE]
      )
      let incomeDisabledFlag = this.isEqualArrayElelments1(this.props.temp1, [
        _CSP
      ])

      if (!householdDisabledFlag) {
        showTabs.push(tabs[2])
      }
      if (healthEnabledFlag) {
        showTabs.push(tabs[3])
      }
      if (!incomeDisabledFlag) {
        showTabs.push(tabs[4])
      }
    }

    for (let i = 0; i < showTabs.length; i++) {
      let activeFlag = this.props.activeTab
      let flag =
        showTabs[activeFlag].tabOptions.tabName ==
        showTabs[i].tabOptions.tabName
          ? true
          : false
      tabsHTML.push(
        <li key={i} className={li_classes[i]} style={style}>
          <button
            className="btnBF btn-link-bf btn btn-link"
            onClick={() => this._changeTab(i)}
            aria-current={flag}
          >
            <i
              className={
                showTabs[i].tabOptions.iconClassName + ' icon large-bf'
              }
              aria-hidden="true"
            />
            <span className="vcenter col-lg-10 spaceText">
              {showTabs[i].tabOptions.tabName}
            </span>
          </button>
        </li>
      )
      if (i === this.props.activeTab && this.props.mainTab === 0) {
        document.title =
          (hasError ? 'Error' + ' - ' : '') +
          showTabs[i].tabOptions.tabName +
          ' - Screen for Assistance'
      }
    }

    return (
      <ul style={style} className="nav nav-pills nav-stacked tab-active">
        {tabsHTML}
      </ul>
    )
  }
}

interface BenefitFinderContentProps {
  application: {}
  form: boolean
  formData: {}
  tabs: any
  forms: any
  formContext: any
  liveValidate: boolean
  activeTab: number
  temp1: any
  programFormContext: any
  tabEnabled: boolean
  mainTab: number
  errors
  errorMessage: Array<any>
  onDeleteClick: () => void
  logoutUser
}

interface FormProps {
  schema: {}
  uiSchema?: {}
  formData?: any
  formContext?: any
  widgets?: {}
  fields?: {}
  noValidate?: boolean
  noHtml5Validate?: boolean
  safeRenderCompletion?: boolean
}

class BenefitFinderContent extends React.Component<any, any> {
  private divContent: any
  private hasError: boolean = false
  fC1: {}
  stateFormContext: any
  constructor(props: BenefitFinderContentProps) {
    super(props)
    this.divContent = (React as any).createRef()
    this.state = {
      application: {},
      form: false, // dictates if form is loaded
      formData: {},
      tabs: null,
      forms: null,
      formContext: null,
      activeTab: this.props.activeTab, // open first tab by default
      tabEnabled: this.props.tabEnabled,
      temp1: this.props.temp1,
      dispErrors: [],
      programFormContext: {},
      liveValidate: false
    }
  }

  UNSAFE_componentWillMount() {
    this._loadApp() // UNCOMMENT FOR INITAL APPJSON LOAD
    document.title = 'Benefits Finder'
  }

  _changeTab = (val: number) => {
    //Set the clicked tab to editing status.
    this._showQuestions()
    this.props._updateActiveTab(val)
    this.setState({ activeTab: val })
    this.divContent.current.focus()
  }

  _loadApp = () => {
    if (this.props.locale === 'en') {
      this._loadAll(JSONSchemaEN)
    } else {
      this._loadAll(JSONSchemaES)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      if (this.props.locale === 'en') {
        this._loadAll(JSONSchemaEN)
      } else {
        this._loadAll(JSONSchemaES)
      }
    }
  }

  _loadAll = (json: object) => {
    let tabs = [] // navigation tabs
    let forms, application, formData, formContext
    application = json['app']
    forms = application['forms']

    for (let i = 0; i < forms.length; i++) {
      //Initialize tabs using uiSchema, with status set as pristine
      if (typeof forms[i]['uiSchema']['ui:options'] !== 'undefined') {
        tabs.push({
          tabOptions: forms[i]['uiSchema']['ui:options']
        })
      }
    }
    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      forms: forms,
      reviewForms: forms,
      refs: application['metaData']['refs']
    }

    //load the pre-filled form data
    let formDataLoad = application['formData']

    //New application, initialize to empty object
    formData = helper.createObjectFromMasterSchema(null, formContext)
    //Augment the context with formData
    formContext = { ...formContext, formData }

    this.setState({ application, forms, tabs, formData, formContext })

    this.props._formContextUpdated(formContext)
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({ tabEnabled: false, temp1: formData['benefitList'] })
    this.setState({ formData }, () => this._refreshFormContext())
    var stateData = this.state
    if (
      stateData.formContext.formData.otherUtilities === 'N' &&
      stateData.formContext.formData.howMany != undefined
    ) {
      formData.howMany = undefined
    }
    if (formData.attendSchool == 'Y') {
      for (let i = 0; i < this.state.forms.length; i++) {
        if (
          this.state.forms[i].schema.properties.hasOwnProperty('typeSchool')
        ) {
          this.state.forms[i].schema.properties.typeSchool.title =
            'If yes, what type of school: *'
        }
      }
    } else {
      for (let i = 0; i < this.state.forms.length; i++) {
        if (
          this.state.forms[i].schema.properties.hasOwnProperty('typeSchool')
        ) {
          this.state.forms[i].schema.properties.typeSchool.title =
            'If yes, what type of school: '
        }
      }
    }
  }

  arrayHasValue(inputArr, value) {
    if (inputArr == undefined) return false
    for (var i = 0; i < inputArr.length; i++) {
      if (inputArr[i] == value) {
        return true
      }
    }
    return false
  }

  _onSubmit = ({ formData }) => {
    // This executes after validation has passed.
    const { tabs, forms } = this.state
    const nextTab = this.props.activeTab + 1

    formData = { ...this.state.formData, ...formData }

    this.setState({ formData })
    //Check for ages > 60 if amount is given for "individual60Over" question.
    if (nextTab === forms.length) {
      if (this.arrayHasValue(this.state.temp1, 'SNAP')) {
        this.props._fetchEligibleBenefits(formData)
      } else {
        this.props._fetchEligibleBenefits(formData)
      }
    }
  }

  // _ageCheck() {
  //   var dispErrors = []
  //   var oldPeopleArr = this.state.formData.oldPeople
  //   if (
  //     this.state.formData.individual60Over !== '' &&
  //     this.state.formData.anyoneDisabled == 'N'
  //   ) {
  //     if (
  //       this.state.formData.individual60Over !== '0.00' &&
  //       this.state.formData.individual60Over !== '0'
  //     ) {
  //       var checkAge = ['60 - 64 years', '65 years or older']
  //       for (var i = 0; i < oldPeopleArr.length; i++) {
  //         for (var j = 0; j < checkAge.length; j++) {
  //           if (checkAge[j] !== oldPeopleArr[i]) {
  //             var errArr = []
  //             errArr.push(
  //               'You\'ve given amount for "How much does the household pay for medical expenses for individuals 60 and older and/or disabled individuals per month?", but did not indicate that anyone in the household is over the age of 60 (General Questions section) or disabled (Health section).'
  //             )
  //             dispErrors = _.uniq(errArr)
  //             this._focusToTop()
  //           } else {
  //             dispErrors = []
  //             break
  //           }
  //         }
  //       }
  //     }
  //   }
  //   this.setState(prevState => {
  //     return {
  //       ...prevState,
  //       dispErrors: dispErrors
  //     }
  //   })

  //   if (dispErrors.length > 0) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  _focusToTop() {
    this.divContent.current.focus()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  lieapCheck(inputArr) {
    if (inputArr == undefined) return false
    if (inputArr.length == 1 && inputArr[0] == 'LIEAP') {
      return false
    } else {
      return true
    }
  }

  validateTest() {
    if (this.state.dispErrors.length > 0) {
      return (
        <div className="panel panel-danger errors">
          <div className="panel-heading">
            <h3 className="panel-title">Errors</h3>
          </div>
          <ul className="list-group">
            {this.state.dispErrors.map((error, i) => {
              return (
                <li key={i} className="list-group-item text-danger">
                  {error}
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
    return null
  }

  validate(formData, schema) {
    const { validate, transformErrors, jsonValidator } = this.props
    return validateFormData(
      formData,
      schema,
      validate,
      transformErrors,
      jsonValidator,
      null
    )
  }

  _isExactEqualArrayElelments(chkArr, inputArr) {
    var flag = false
    chkArr.sort()
    inputArr.sort()
    if (chkArr.length === inputArr.length) {
      for (var i = 0; i < chkArr.length; i++) {
        if (chkArr[i] !== inputArr[i]) {
          return false
        } else {
          flag = true
        }
      }
    }
    return flag
  }

  newEqualArrayElelments(chkArr, inputArr) {
    var count = 0
    if (chkArr.length > 0) {
      for (var i = 0; i < inputArr.length; i++) {
        for (var j = 0; j < chkArr.length; j++) {
          if (inputArr[i] === chkArr[j]) {
            return true
          }
        }
      }
    }
    return false
  }

  isEqualArrayElelments1(chkArr, inputArr) {
    var count = 0
    var flag = false
    chkArr.sort()
    inputArr.sort()
    if (chkArr.length > 2) {
      flag = false
    }
    if (chkArr.length == 1) {
      if (chkArr[0] == inputArr[0] || chkArr[0] == inputArr[1]) {
        flag = true
      }
    } else if (chkArr.length == inputArr.length) {
      for (var i = 0; i < chkArr.length; i++) {
        if (inputArr[i] != chkArr[i]) {
          flag = false
          break
        } else {
          flag = true
        }
      }
    }
    return flag
  }

  _addQuestionsToFormContext(reqQues) {
    if (this.props.activeTab == 0) {
      var reqQuesKeys = Object.keys(reqQues)
      var temp = this.state.formData.benefitList
      let newForms = []
      newForms[0] = this.stateFormContext[0]
      newForms[1] = this.stateFormContext[1]
      if (temp.length > 0) {
        let healthEnabledFlag = this.newEqualArrayElelments(temp, [
          _HEALTHCARE,
          _SNAP,
          _MEDICARE
        ])
        let householdDisabledFlag = this.isEqualArrayElelments1(temp, [
          _MEDICARE
        ])
        let incomeDisabledFlag = this.isEqualArrayElelments1(temp, [_CSP])
        if (!householdDisabledFlag) {
          newForms[2] = this.stateFormContext[2]
        }
        if (healthEnabledFlag) {
          newForms[3] = this.stateFormContext[3]
        }
        if (!incomeDisabledFlag) {
          newForms[4] = this.stateFormContext[4]
        }
      }

      for (var i = 0; i < reqQuesKeys.length; i++) {
        var questions = reqQues[reqQuesKeys[i]]
        if (questions.length > 0) {
          newForms[i + 1] = this.stateFormContext[i + 1]
        }
      }

      for (var i = 0; i < reqQuesKeys.length; i++) {
        var questions = reqQues[reqQuesKeys[i]]
        if (questions.length > 0) {
          for (var j = 0; j < questions.length; j++) {
            if (
              newForms[i + 1] !== undefined &&
              newForms[i + 1].schema !== undefined
            )
              newForms[i + 1].schema.properties[questions[j]] = this.fC1[
                reqQuesKeys[i]
              ][questions[j]]
          }
        }
      }
      const filledTabs = newForms.filter(form => {
        let properties = form.schema.properties
        return Object.keys(properties).length > 0
      })
      this.setState(prevState => {
        return {
          ...prevState,
          formContext: {
            ...prevState.formContext,
            forms: filledTabs
          }
        }
      })
      this.setState(prevState => {
        return {
          ...prevState,
          forms: filledTabs
        }
      })
    }
  }

  _showQuestions() {
    if (this.fC1 == undefined) {
      this.fC1 = {}
      this.stateFormContext = this.state.formContext.forms
      for (var i = 0; i < this.stateFormContext.length; i++) {
        if (i > 0 && i < 5) {
          var properties = this.stateFormContext[i].schema.properties
          var propKeys = Object.keys(properties)
          for (var j = 0; j < propKeys.length; j++) {
            if (
              this.fC1.hasOwnProperty(this.stateFormContext[i].schema.title)
            ) {
              this.fC1[this.stateFormContext[i].schema.title][propKeys[j]] =
                properties[propKeys[j]]
            } else {
              this.fC1[this.stateFormContext[i].schema.title] = {}
              this.fC1[this.stateFormContext[i].schema.title][propKeys[j]] =
                properties[propKeys[j]]
            }
          }
        }
      }
    }

    if (this.props.activeTab == 0) {
      var tabKeys = Object.keys(this.fC1)
      for (var i = 0; i < tabKeys.length; i++) {
        var delProps = this.fC1[tabKeys[i]]
        var delPropArr = Object.keys(delProps)
        for (var j = 0; j < delPropArr.length; j++) {
          if (this.state.formContext.forms[i + 1] !== undefined) {
            delete this.state.formContext.forms[i + 1]['schema'].properties[
              delPropArr[j]
            ]
          }
        }
      }
    }
    this.questionList()
  }

  questionList() {
    var count = 0
    var chkArr = []
    var temp = this.state.formData.benefitList
    if (temp !== undefined) {
      for (var i = 0; i < temp.length; i++) {
        var reqQues = {}
        if (this.props.locale === 'en') {
          if (temp[i] === _SCREEN) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': [
                'careCompatible',
                'developmentDisability',
                'nursingFacility',
                'parentOfMinorChild',
                'attendSchool',
                'typeSchool',
                'mortgagePerMonth',
                'hcExpenses',
                'otherUtilities',
                'howMany'
              ],
              'Health Questions': [
                'anyonePregnant',
                'anyoneDisabled',
                'anyoneBlind',
                'householdMedicare'
              ],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth',
                'childCarePerMonth',
                'individual60Over',
                'childSupportOutsideHome',
                'householdAssets'
              ]
            }
          } else if (temp[i] === _HEALTHCARE) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': [
                'careCompatible',
                'developmentDisability',
                'nursingFacility'
              ],
              'Health Questions': [
                'anyonePregnant',
                'anyoneDisabled',
                'anyoneBlind'
              ],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          } else if (temp[i] === _TANF) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': ['parentOfMinorChild'],
              'Health Questions': [],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth',
                'householdAssets'
              ]
            }
          } else if (temp[i] === _LIEAP) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold'
              ],
              'Household Questions': ['hcExpenses'],
              'Health Questions': [],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          } else if (temp[i] === _SNAP) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': [
                'mortgagePerMonth',
                'hcExpenses',
                'otherUtilities',
                'howMany'
              ],
              'Health Questions': ['anyoneDisabled'],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth',
                'childCarePerMonth',
                'individual60Over',
                'childSupportOutsideHome'
              ]
            }
          } else if (temp[i] === _NURSING) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': [
                'careCompatible',
                'developmentDisability',
                'nursingFacility'
              ],
              'Health Questions': [],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          } else if (temp[i] === _MEDICARE) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': [],
              'Health Questions': ['householdMedicare'],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth',
                'householdAssets'
              ]
            }
          } else if (temp[i] === _SCA) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': ['attendSchool', 'typeSchool'],
              'Health Questions': [],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          } else if (temp[i] === _CSP) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': ['existingSSO'],
              'Health Questions': [],
              'Income & Resources Questions': []
            }
          } else if (temp[i] === _ChildCare) {
            reqQues = {
              'General Questions ': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Household Questions': [
                'isChildUnderSuperVision',
                'childrenChildCare'
              ],
              'Health Questions': [],
              'Income & Resources Questions': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          }
        } else {
          if (temp[i] === _SCREEN) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': [
                'careCompatible',
                'developmentDisability',
                'nursingFacility',
                'parentOfMinorChild',
                'attendSchool',
                'typeSchool',
                'mortgagePerMonth',
                'hcExpenses',
                'otherUtilities',
                'howMany'
              ],
              'Preguntas sobre la salud': [
                'anyonePregnant',
                'anyoneDisabled',
                'anyoneBlind',
                'householdMedicare'
              ],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth',
                'childCarePerMonth',
                'individual60Over',
                'childSupportOutsideHome',
                'householdAssets'
              ]
            }
          } else if (temp[i] === _HEALTHCARE) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': [
                'careCompatible',
                'developmentDisability',
                'nursingFacility'
              ],
              'Preguntas sobre la salud': [
                'anyonePregnant',
                'anyoneDisabled',
                'anyoneBlind'
              ],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          } else if (temp[i] === _TANF) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': ['parentOfMinorChild'],
              'Preguntas sobre la salud': [],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth',
                'householdAssets'
              ]
            }
          } else if (temp[i] === _LIEAP) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold'
              ],
              'Preguntas sobre el hogar': ['hcExpenses'],
              'Preguntas sobre la salud': [],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          } else if (temp[i] === _SNAP) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': [
                'mortgagePerMonth',
                'hcExpenses',
                'otherUtilities',
                'howMany'
              ],
              'Preguntas sobre la salud': ['anyoneDisabled'],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth',
                'childCarePerMonth',
                'individual60Over',
                'childSupportOutsideHome'
              ]
            }
          } else if (temp[i] === _NURSING) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': [
                'careCompatible',
                'developmentDisability',
                'nursingFacility'
              ],
              'Preguntas sobre la salud': [],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          } else if (temp[i] === _MEDICARE) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': [],
              'Preguntas sobre la salud': ['householdMedicare'],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth',
                'householdAssets'
              ]
            }
          } else if (temp[i] === _SCA) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': ['attendSchool', 'typeSchool'],
              'Preguntas sobre la salud': [],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          } else if (temp[i] === _CSP) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': ['existingSSO'],
              'Preguntas sobre la salud': [],
              'Preguntas sobre ingresos y recursos': []
            }
          } else if (temp[i] === _ChildCare) {
            reqQues = {
              'Preguntas generales': [
                'householdWV',
                'county',
                'peopleInHousehold',
                'oldPeople'
              ],
              'Preguntas sobre el hogar': [
                'isChildUnderSuperVision',
                'childrenChildCare'
              ],
              'Preguntas sobre la salud': [],
              'Preguntas sobre ingresos y recursos': [
                'householdEarn',
                'earningPerMonth'
              ]
            }
          }
        }
        this._addQuestionsToFormContext(reqQues)
        ++count
      }
    }
  }

  _isEqualArrayElelments(chkArr, inputArr) {
    var flag = false
    chkArr.sort()
    inputArr.sort()
    if (chkArr.length === inputArr.length) {
      for (var i = 0; i < chkArr.length; i++) {
        if (chkArr[i] !== inputArr[i]) {
          return false
        } else {
          flag = true
        }
      }
    }
    return flag
  }

  _updateFromFormcontext(mapObj, index) {
    var que = this.state.formContext.forms[index]['schema'].properties
    var keys = Object.keys(mapObj)
    for (var i = 0; i < keys.length; ++i) {
      var values = mapObj[keys[i]][0]
      for (var j = 0; j < values.length; ++j) {
        if (!que.hasOwnProperty(values[j])) {
          var reqObj = this._getRequiredProperty(values[j])
          if (reqObj != undefined) {
            this.state.formContext.forms[index]['schema'].properties[
              values[j]
            ] = reqObj
          }
        }
      }
    }
  }

  _getRequiredProperty(prop) {
    var keys = Object.keys(this.fC1)
    for (var i = 0; i < keys.length; ++i) {
      var valObj = this.fC1[keys[i]]
      if (valObj !== undefined && valObj.hasOwnProperty(prop)) {
        return valObj[prop]
      }
    }
  }

  isNotEqualArrayElelments(chkArr, inputArr) {
    var flag = false
    if (chkArr.length > 0) {
      for (var j = 0; j < chkArr.length; j++) {
        if (chkArr[j] === inputArr[0] || chkArr[j] === inputArr[1]) {
          flag = true
        } else {
          flag = false
        }
      }
    }
    return flag
  }

  _checkSelectedQuestions = (temp, category) => {
    return false
  }

  _onNext = () => {
    this._showQuestions()
    this._changeTab(this.props.activeTab + 1)
    this._toTop()
    this.divContent.current.focus()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  _onPrevious = () => {
    this._switchOnLiveValidate()
    const activeTab = this.state.activeTab - 1
    this.setState({ activeTab: activeTab })
    this._changeTab(this.props.activeTab - 1)
    // return to top of form
    this._toTop()
    this.divContent.current.focus()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  _toTop = () => {
    window.location.href = '#app'
  }

  _removeStringInAArray = (array, search_term) => {
    for (var i = array.length - 1; i >= 0; i--) {
      if (array[i] === search_term) {
        array.splice(i, 1)
        break
      }
    }
    return array
  }

  _refreshFormContext = () => {
    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          formData: helper.deepClone(this.state.formData),
          reviewFormData: helper.deepClone(this.state.formData)
        }
      },
      () => {
        this.props._formContextUpdated(this.state.formContext)
        let errorKeys = this.state.errorKeys
        let dispErrors = this.state.dispErrors
        var selQues = this.state.formContext.formData
        if (errorKeys) {
          if (errorKeys.indexOf('benefitList') >= 0) {
            if (
              this.state.formContext.formData['benefitList'] != undefined ||
              this.state.formContext.formData['benefitList'].length > 0
            ) {
              errorKeys = this._removeStringInAArray(errorKeys, 'benefitList')
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'Please choose a value for "Please select the programs for which you would like to screen:"'
              )
            }
          }
          if (errorKeys.indexOf('county') >= 0) {
            if (
              (selQues['householdWV'] == 'N' &&
                selQues['county'] == undefined) ||
              (selQues['householdWV'] == 'Y' && selQues['county'] != undefined)
            ) {
              errorKeys = this._removeStringInAArray(errorKeys, 'county')
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'You may only provide a response to "If yes, in what county does your household reside?", if you answered "Yes" to "Does your household reside in West Virginia?"'
              )
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'You answered "Yes" to "Does your household reside in West Virginia?". Please provide an answer to "If yes, in what county does your household reside?".'
              )
            }
          }
          if (errorKeys.indexOf('oldPeople') >= 0) {
            if (selQues['oldPeople'] && selQues['oldPeople'].length > 0) {
              errorKeys = this._removeStringInAArray(errorKeys, 'oldPeople')
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'Please choose a value for "How old are the people in the household (check all that apply)?"'
              )
            }
          }

          if (errorKeys.indexOf('oldPeopleM') >= 0) {
            if (
              selQues['oldPeople'] &&
              selQues['oldPeople'].length >= +selQues['peopleInHousehold']
            ) {
              errorKeys = this._removeStringInAArray(errorKeys, 'oldPeople')
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'You selected too many options for the question, "How old are the people in the household (check all that apply)?"'
              )
            }
          }
          if (errorKeys.indexOf('typeSchool') >= 0) {
            if (
              (selQues['attendSchool'] == 'Y' &&
                (selQues['typeSchool'] != undefined ||
                  selQues['typeSchool'].length > 0)) ||
              (selQues['attendSchool'] == 'N' &&
                (selQues['typeSchool'] == undefined ||
                  selQues['typeSchool'].length == 0))
            ) {
              errorKeys = this._removeStringInAArray(errorKeys, 'typeSchool')
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'You may only provide a response to "If yes, what type of school: ", if you answered "Yes" to "Does anyone in your household attend school?".'
              )
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'Please choose a value for "If yes, what type of school:"'
              )
            }
          }
          if (errorKeys.indexOf('howMany') >= 0) {
            if (
              selQues['otherUtilities'] == 'N' ||
              (selQues['otherUtilities'] == 'Y' &&
                selQues['howMany'] != undefined)
            ) {
              errorKeys = this._removeStringInAArray(errorKeys, 'howMany')
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'Please choose a value for "If yes, how many?"'
              )
            }
          }

          if (errorKeys.indexOf('householdEarn') >= 0) {
            if (
              selQues['householdEarn'] != undefined &&
              selQues['householdEarn'] != ''
            ) {
              errorKeys = this._removeStringInAArray(errorKeys, 'householdEarn')
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'Please choose a value for "How much money does the household earn per month?"'
              )
            }
          }
          if (errorKeys.indexOf('earningPerMonth') >= 0) {
            if (
              selQues['earningPerMonth'] != undefined &&
              selQues['earningPerMonth'] != ''
            ) {
              errorKeys = this._removeStringInAArray(
                errorKeys,
                'earningPerMonth'
              )
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'Please choose a value for "How much money does the household get other than earnings per month?"'
              )
            }
          }
          if (errorKeys.indexOf('childrenChildCare') > -1) {
            if (
              (selQues['childrenChildCare'] != undefined &&
                selQues['childrenChildCare'] != '') ||
              !(
                selQues.benefitList &&
                selQues.benefitList.indexOf('Childcare') > -1
              )
            ) {
              errorKeys = this._removeStringInAArray(
                errorKeys,
                'childrenChildCare'
              )
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'Please choose a value for "Are the children needing child care US Citizens or Qualified Aliens?"'
              )
            }
          }
        }

        this.setState({ dispErrors: dispErrors, errorKeys: errorKeys })
      }
    )
  }

  getSubmitButtonTabIndex = () => {
    let healthEnabledFlag = this._isEqualArrayElelments(this.state.temp1, [
      _HEALTHCARE
    ])
    let cspEnabledFlag = this._isEqualArrayElelments(this.state.temp1, [_CSP])
    if (healthEnabledFlag || cspEnabledFlag) {
      return 3
    } else {
      return 4
    }
  }

  _disableNext = (): boolean => {
    const se = this.state.formData
    let hasProgram = false
    if (_.isNil(se.benefitList)) {
      hasProgram = true
    }

    return hasProgram
  }

  _errorListTemplate = (props: any) => {
    const { errors } = props
    return (
      <div
        className="panel panel-danger errors"
        id="benefitfinder-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <span className="panel-title">Errors</span>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this._onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  _onErrorClick = error => {
    const { forms } = this.state
    let errorId = error.id
    for (let tab = 0; tab < forms.length; tab++) {
      if (forms[tab].schema.properties.hasOwnProperty(errorId.toString())) {
        if (error.step != forms[tab]) {
          this.props.formContext.goToTab(tab)
        }
      }
    }

    try {
      setTimeout(function() {
        window.requestAnimationFrame(function() {
          const element = document.getElementById(`root_${error.id}`)
          let offset = helper.getOffsetTop(
            document.getElementById(`root_${error.id}`)
          )
          element.focus()
          window.scroll(0, offset - 100)
        })
      }, 0)
    } catch (e) {
      console.error('Benefit Finder viewId setTimeout failed with ex', e)
    }
  }

  _transformErrors = (errors: object) => {
    return errors
  }

  _switchOnLiveValidate = () => {
    if (!this.state.liveValidate) {
      this.setState({ liveValidate: true })
    }
  }

  _switchOffLiveValidate = () => {
    if (this.state.liveValidate) {
      this.setState({ liveValidate: false })
    }
  }

  _checkForErrors(errors) {
    if (this.state.activeTab == 0) {
      if (errors.benefitList.__errors.length == 0) {
        return true
      }
    }
  }
  _onValidate(formData: object, errors: object) {
    if (this.state.liveValidate) {
      validator.validateBenefitFinder(
        formData,
        errors,
        this.state.activeTab,
        this.state.forms,
        errorMessages[this.props.locale]
      )
    }
    let foundErrors = helper.findErrors(errors)
    if (foundErrors && foundErrors.length > 0) {
      this.hasError = true
    } else {
      this.hasError = false
    }
    return errors
  }

  _setLiveValidate() {
    this._switchOnLiveValidate()
  }

  _goToNextTab() {
    this._switchOffLiveValidate()
    if (this.state.forms.length - 1 == this.state.activeTab) {
      this._onSubmit(this.state.formData)
    } else {
      const activeTab = this.state.activeTab + 1
      this.setState({ activeTab: activeTab })
      this._onNext()
    }
  }

  _onError = (errors: object) => {
    this._toTop()
    const errorContent = document.getElementById('benefitfinder-errors')
    if (errorContent) {
      window.scroll(0, errorContent.offsetTop)
      errorContent.focus()
    }
    this.setState({
      reRender: true
    })
  }

  render() {
    const {
      schema,
      uiSchema,
      tabs,
      formContext,
      application,
      formData,
      liveValidate
    } = this.state

    const activeTab = this.props.activeTab
    const tabEnabled = this.state.tabEnabled
    const forms = formContext.forms || []
    const temp1 = this.state.temp1
    const disableNextButton = !this._disableNext()
    let submitButton, prevButton
    const dispErrors = this.state.dispErrors
    let validatorBenefitFinder
    if (forms && activeTab) {
      validatorBenefitFinder = new CustomValidator(
        forms[activeTab].schema,
        forms[activeTab].uiSchema,
        formContext,
        'en'
      )
    }
    const bnfMargin = { margin: '22px' }
    let programSelection =
      this.state.formContext.formData.benefitList &&
      this.state.formContext.formData.benefitList.length > 0 &&
      this.state.formContext.formData.benefitList
    let hasChildCare = _.find(programSelection, program => {
      return program == 'Childcare'
    })
    if (activeTab > 0) {
      prevButton = (
        <button
          type="button"
          style={bnfMargin}
          className={`btn btn-default float-left ${
            this.hasError ? 'btn-danger' : 'btn-default'
          }`}
          onClick={this._onPrevious}
        >
          <i className="fa fa-chevron-left arrow-left" />
          {I18n.t('General.previousButtonLabel')}
        </button>
      )
    }
    if (activeTab < forms.length - 1) {
      submitButton = this.hasError ? (
        <button
          type="submit"
          className={`btn btn-default float-right benefits-error-margin-top next btn-danger`}
          aria-label="There are errors on this page"
          onClick={this._setLiveValidate.bind(this)}
        >
          {I18n.t('General.labelErrors')}
          <i className="fa fa-chevron-right arrow-right" />
        </button>
      ) : (
        <button
          type="submit"
          style={bnfMargin}
          className="btn btn-default float-right next"
          onClick={this._setLiveValidate.bind(this)}
        >
          {I18n.t('General.nextButton')}
          <i className="fa fa-chevron-right arrow-right" />
        </button>
      )
    } else {
      submitButton = this.hasError ? (
        <button
          type="submit"
          id="submit"
          style={bnfMargin}
          className={`btn btn-default float-right benefits-error-margin-top next btn-danger`}
          aria-label="There are errors on this page"
          onClick={this._setLiveValidate.bind(this)}
        >
          {I18n.t('General.submitButtonLabel')}
        </button>
      ) : (
        <button
          type="submit"
          id="submit"
          style={bnfMargin}
          className="btn btn-default float-right"
          onClick={this._setLiveValidate.bind(this)}
        >
          {I18n.t('General.submitButtonLabel')}
        </button>
      )
    }
    return (
      <div
        className="container-fluid benefits-wrapper"
        style={{ backgroundColor: '#1274b2' }}
      >
        <a href="#side-nav" className="skip-nav">
          Skip to side navigation
        </a>
        <ErrorModal
          errorMessage={this.props.errorMessage}
          onDeleteClick={this.props.onDeleteClick}
        />
        <a href="#benefits" className="skip-nav">
          Skip to benefits information
        </a>
        <div className="row">
          <div id="side-nav" className="col-lg-2 side-pane">
            <div className="row">
              <VerticalTabs
                tabs={tabs}
                hasError={this.hasError}
                _changeTab={this._changeTab}
                activeTab={activeTab}
                mainTab={this.props.mainTab}
                displayNone={tabEnabled}
                temp1={temp1}
              />
            </div>
          </div>
          <div
            id="benefit-content"
            ref={this.divContent}
            className="col-12 col-md-9 col-lg-9 col-xl-10 main-pane accessibility-focus"
          >
            <div>{dispErrors.length > 0 && this.validateTest()}</div>
            {forms.length > 0 && (
              <Form
                schema={forms[activeTab]['schema']}
                onSubmit={this._goToNextTab.bind(this)}
                uiSchema={forms[activeTab]['uiSchema']}
                formData={this.state.formData}
                onChange={this._onFormDataChange}
                formContext={formContext}
                fields={fields}
                widgets={widgets}
                ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                FieldTemplate={Templates.CustomFieldTemplate}
                validate={this._onValidate.bind(this)}
                liveValidate={liveValidate}
                ErrorList={this._errorListTemplate}
                transformErrors={this._transformErrors}
                jsonValidator={validatorBenefitFinder}
                onError={this._onError}
              >
                {activeTab == 0 && hasChildCare && (
                  <div>
                    <h2>{I18n.t('General.title48')}</h2>

                    <p>{I18n.t('General.title49')}</p>
                    <br />
                    <p>{I18n.t('General.title50')}</p>
                    <br />

                    <p>{I18n.t('General.title51')}</p>

                    <ul>
                      <li>{I18n.t('General.title52')}</li>

                      <li>
                        {I18n.t('General.titl53')}
                        <ul>
                          <li>{I18n.t('General.title54')}</li>
                        </ul>
                      </li>

                      <li>
                        {I18n.t('General.title55')}
                        <ul>
                          <li>{I18n.t('General.title56')}</li>
                        </ul>
                      </li>
                    </ul>

                    <br />

                    <p>{I18n.t('General.title57')}</p>

                    <ul>
                      <li>{I18n.t('General.title58')}</li>

                      <li>{I18n.t('General.title59')}</li>

                      <li>{I18n.t('General.title60')}</li>

                      <li>
                        {I18n.t('General.title61')}
                        <ul>
                          <li>{I18n.t('General.title62')}</li>

                          <li>{I18n.t('General.title63')}</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                )}
                {prevButton}
                {submitButton}
              </Form>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class EligibileBenefitsPanelContent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      open: {}
    }
  }

  componentDidMount() {
    document.title = 'Benefits Finder'

    this._setupPanelExpanded(this.props.benefits)
  }

  _setupPanelExpanded = benefits => {
    let open = {}

    for (let i = 0; i < benefits.length; i++) {
      open[benefits[i].benefitCd] = false
    }

    this.setState({ open })
  }

  _toggleOpen = benefitCd => {
    let open = this.state.open
    open[benefitCd] = !open[benefitCd]
    this.setState({ open })
  }

  _toggleFavoriteButton = (key: string) => {
    const myFavorites = this.props.myFavorites

    if (myFavorites && myFavorites.indexOf(key) > -1) {
      return (
        <div>
          <button
            className="btn btn-primary float-left"
            onClick={() => {
              this.props.removeMyFavorite(key)
            }}
          >
            Remove Favorite
          </button>
        </div>
      )
    } else {
      return (
        <button
          className="btn btn-primary float-left"
          onClick={() => {
            this.props.addMyFavorite(key)
          }}
        >
          Add Favorite
        </button>
      )
    }
  }

  _generateContent = obj => {
    const myFavorites = this.props.myFavorites
    const key = obj.benefitCd
    let favoriteBtn
    if (this.props.enableFavorites) {
      favoriteBtn = this._toggleFavoriteButton(obj.benefitCd)
    } else {
      favoriteBtn = <div />
    }
    if (obj['eligibilityStatus']) {
      return (
        <Panel
          className=""
          collapsible
          key={key}
          header={obj.benLongName}
          expanded={this.state.open[obj.benefitCd]}
          onSelect={() => this._toggleOpen(obj.benefitCd)}
        >
          <p>{obj.benfShortDescription}</p>

          {favoriteBtn}

          <button
            className="btn btn-primary float-right"
            onClick={() => {
              this.props._setBenefitsModalType(obj.benefitCd)
            }}
          >
            Learn more
          </button>
        </Panel>
      )
    } else {
      return null
    }
  }

  render() {
    const { benefits } = this.props
    return <div>{benefits.map(this._generateContent)}</div>
  }
}

class ViewResults extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0
    }
  }
  _updateTab = (reviewWidget, tab) => {
    if (reviewWidget) {
      this.setState({ tab: 0 })
      this.props.formContext.goToTab(tab)
    } else {
      this.setState({ tab })
    }
  }

  render() {
    let tab = this.state.tab
    if (!_.isEmpty(this.props.formContext)) {
      let formContext = {
        ...this.props.formContext,
        updateTab: this._updateTab
      }
      switch (tab) {
        case 0:
          return (
            <div>
              <h1>{I18n.t('General.title21')}</h1>
              <div className="col-12">
                <p>{I18n.t('General.title25')}</p>
              </div>
              <div className="col-12">
                <BenefitsView
                  benefits={this.props.benefits.programEligibility}
                  enableFavorites={this.props.enableFavorites}
                />
              </div>
            </div>
          )
        default:
          return (
            <div>
              <h1>{I18n.t('BenefitsFinder.viewResults')}</h1>
              <h2>{I18n.t('BenefitsFinder.noResults')}</h2>
            </div>
          )
      }
    } else return null
  }
}

interface bFProps {
  programFormContext: {}
}

const _SCREEN = 'Screen'
const _HEALTHCARE = 'Healthcare'
const _MEDICARE = 'Medicare'
const _SNAP = 'SNAP'
const _TANF = 'WVWorks'
const _LIEAP = 'LIEAP'
const _NURSING = 'Nursing'
const _SCA = 'SCA'
const _CSP = 'CSP'
const _ChildCare = 'Childcare'
const amountDefaultValue = '0.00'

class BenefitsFinder extends React.Component<any, any> {
  private divContent: any
  constructor(props: any) {
    super(props)
    this.divContent = (React as any).createRef()
    this.state = {
      benefits: [],
      numBenefits: 0,
      answers: {},
      formContext: {},
      formActiveTab: 0,
      mainTab: 0,
      loaded: true,
      temp1: [],
      jsonbenefits: {},
      redirect: null,
      showExitModal: false,
      showErrorModal: false
    }
  }

  componentDidMount() {
    this.setState(prevState => {
      return {
        ...prevState,
        programFormContext: this.state.formContext
      }
    })

    let sideNavLink = document.createElement('a')
    sideNavLink.setAttribute('class', 'skip')
    sideNavLink.setAttribute('href', '#side-nav')
    sideNavLink.setAttribute('id', 'skip-to-side-nav')
    let sideNavText = document.createTextNode(I18n.t('General.skipToSideNav'))
    sideNavLink.appendChild(sideNavText)
    let benefitsInfoLink = document.createElement('a')
    benefitsInfoLink.setAttribute('class', 'skip')
    benefitsInfoLink.setAttribute('href', '#benefits')
    benefitsInfoLink.setAttribute('id', 'skip-to-benefits')
    let benefitsInfoText = document.createTextNode(
      I18n.t('General.skipToBenefits')
    )
    benefitsInfoLink.appendChild(benefitsInfoText)
    const navContent = document.getElementById('nav-content')
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      navContent.insertBefore(sideNavLink, mainNavContent)
      navContent.insertBefore(benefitsInfoLink, mainNavContent)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    let benefitsInfoLink = document.getElementById('skip-to-benefits')
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      if (!sideNavLink) {
        let sideNavLink = document.createElement('a')
        sideNavLink.setAttribute('class', 'skip')
        sideNavLink.setAttribute('href', '#side-nav')
        sideNavLink.setAttribute('id', 'skip-to-side-nav')
        let sideNavText = document.createTextNode(
          I18n.t('General.skipToSideNav')
        )
        sideNavLink.appendChild(sideNavText)
        navContent.insertBefore(sideNavLink, mainNavContent)
      }
      if (!benefitsInfoLink) {
        let benefitsInfoLink = document.createElement('a')
        benefitsInfoLink.setAttribute('class', 'skip')
        benefitsInfoLink.setAttribute('href', '#benefits')
        benefitsInfoLink.setAttribute('id', 'skip-to-benefits')
        let benefitsInfoText = document.createTextNode(
          I18n.t('General.skipToBenefits')
        )
        benefitsInfoLink.appendChild(benefitsInfoText)
        navContent.insertBefore(benefitsInfoLink, mainNavContent)
      }
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({ formContext: { goToTab: this._goToTab } })
  }

  componentWillUnmount() {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    let benefitsInfoLink = document.getElementById('skip-to-benefits')

    if (navContent) {
      if (sideNavLink) {
        navContent.removeChild(sideNavLink)
      }
      if (benefitsInfoLink) {
        navContent.removeChild(benefitsInfoLink)
      }
    }
  }

  _translateAnswersFormToEP = answers => {
    // manipulate blankAnswerJSON is the requestJSON
    let requestJSON = helper.deepClone(blankAnswerJSON)
    // QUESTIONS: DF - ENDPOINT VALUE
    let didChange = false

    let temp // array are relative!
    /** Core
     *	benefitList - core[0]['quesId'] = 1
     */

    // benefitList Translation
    if (typeof answers['benefitList'] !== 'undefined') {
      // if the benefitList has no answer
      temp = requestJSON['core'][0]['benefitQuestionOptions'] // relative assignment
      if (_.isEmpty(answers['benefitList'])) {
        // core[0] is first question
        for (let i = 0; i < temp.length; i++) {
          temp[i]['booleanValue'] = false
        }
        // if benefitList has some answers
      } else {
        //loop through and check if they match, if so set true, else set false
        for (let i = 0; i < temp.length; i++) {
          didChange = false //if the value wasn't changed set boolean to false
          for (let j = 0; j < answers['benefitList'].length; j++) {
            if (temp[i]['textValue'] === answers['benefitList'][j]) {
              temp[i]['booleanValue'] = true
              didChange = true // was changed so don't set to false
            }
          }
          if (!didChange) {
            temp[i]['booleanValue'] = false
          }
        }
      }
      this.setState({ temp1: temp })
    }

    /** General
     * householdWV
     * county
     * peopleInHousehold
     * oldPeople
     */

    // householdWV
    if (typeof answers['householdWV'] !== 'undefined') {
      requestJSON['general'][0]['value'] = answers['householdWV']
    }

    // county
    if (typeof answers['county'] !== 'undefined') {
      requestJSON['general'][1]['value'] = answers['county']
    }

    // peopleInHousehold
    if (typeof answers['peopleInHousehold'] !== 'undefined') {
      requestJSON['general'][2]['value'] = answers['peopleInHousehold']
    }

    // oldPeople
    if (typeof answers['oldPeople'] !== 'undefined') {
      // if the oldPeople value is not undefined enter the function block
      temp = requestJSON['general'][3]['benefitQuestionOptions']

      if (_.isEmpty(answers['oldPeople'])) {
        for (let i = 0; i < temp.length; i++) {
          temp[i]['booleanValue'] = false
        }
      } else {
        //if oldPeople has answers, loop through it and set true or false accordingly.
        for (let i = 0; i < temp.length; i++) {
          didChange = false //if value doesn't change, set boolean(didChange) to false.
          for (let j = 0; j < answers['oldPeople'].length; j++) {
            if (temp[i]['textValue'] === answers['oldPeople'][j]) {
              temp[i]['booleanValue'] = true
              didChange = true // if value changes, set didChange to true.
            }
          }
          if (!didChange) {
            temp[i]['booleanValue'] = false
          }
        }
      }
    }

    /** household
     * careCompatible
     * developmentDisability
     * nursingFacility
     * parentOfMinorChild
     * attendSchool
     * typeSchool---
     * mortgagePerMonth
     * hcExpenses
     * otherUtilities
     * howMany
     * existingSSO
     * isChildUnderSuperVision
     */

    // careCompatible
    if (typeof answers['careCompatible'] !== 'undefined') {
      requestJSON['household'][0]['value'] = answers['careCompatible']
    }

    // developmentDisability
    if (typeof answers['developmentDisability'] !== 'undefined') {
      requestJSON['household'][1]['value'] = answers['developmentDisability']
    }

    // nursingFacility
    if (typeof answers['nursingFacility'] !== 'undefined') {
      requestJSON['household'][2]['value'] = answers['nursingFacility']
    }

    // parentOfMinorChild
    if (typeof answers['parentOfMinorChild'] !== 'undefined') {
      requestJSON['household'][3]['value'] = answers['parentOfMinorChild']
    }

    // attendSchool
    if (typeof answers['attendSchool'] !== 'undefined') {
      requestJSON['household'][4]['value'] = answers['attendSchool']
    }

    // typeSchool
    if (typeof answers['typeSchool'] !== 'undefined') {
      // if the typeSchool value is not undefined enter the function block
      temp = requestJSON['household'][5]['benefitQuestionOptions']

      if (_.isEmpty(answers['typeSchool'])) {
        for (let i = 0; i < temp.length; i++) {
          temp[i]['booleanValue'] = false
        }
      } else {
        //if typeSchool has answers, loop through it and set true or false accordingly.
        for (let i = 0; i < temp.length; i++) {
          didChange = false //if value doesn't change, set boolean(didChange) to false.
          for (let j = 0; j < answers['typeSchool'].length; j++) {
            if (temp[i]['textValue'] === answers['typeSchool'][j]) {
              temp[i]['booleanValue'] = true
              didChange = true // if value changes, set didChange to true.
            }
          }
          if (!didChange) {
            temp[i]['booleanValue'] = false
          }
        }
      }
    }

    // mortgagePerMonth
    if (typeof answers['mortgagePerMonth'] !== 'undefined') {
      requestJSON['household'][6]['value'] =
        answers['mortgagePerMonth'] !== ''
          ? answers['mortgagePerMonth']
          : amountDefaultValue
    }

    // hcExpenses
    if (typeof answers['hcExpenses'] !== 'undefined') {
      requestJSON['household'][7]['value'] = answers['hcExpenses']
    }

    // otherUtilities
    if (typeof answers['otherUtilities'] !== 'undefined') {
      requestJSON['household'][8]['value'] = answers['otherUtilities']
    }

    // howMany
    if (typeof answers['howMany'] !== 'undefined') {
      requestJSON['household'][9]['value'] = answers['howMany']
    }

    // existingSSO
    if (typeof answers['existingSSO'] !== 'undefined') {
      requestJSON['household'][10]['value'] = answers['existingSSO']
    }

    if (typeof answers['isChildUnderSuperVision'] !== 'undefined') {
      requestJSON['household'][11]['value'] = answers['isChildUnderSuperVision']
    }

    if (typeof answers['childrenChildCare'] !== 'undefined') {
      requestJSON['household'][12]['value'] = answers['childrenChildCare']
    }

    /** Health
     * anyonePregnant
     * anyoneDisabled
     * anyoneBlind
     * householdMedicare
     */

    // anyonePregnant
    if (typeof answers['anyonePregnant'] !== 'undefined') {
      requestJSON['health'][0]['value'] = answers['anyonePregnant']
    }

    // anyoneDisabled
    if (typeof answers['anyoneDisabled'] !== 'undefined') {
      requestJSON['health'][1]['value'] = answers['anyoneDisabled']
    }

    // anyoneBlind
    if (typeof answers['anyoneBlind'] !== 'undefined') {
      requestJSON['health'][2]['value'] = answers['anyoneBlind']
    }

    // householdMedicare
    if (typeof answers['householdMedicare'] !== 'undefined') {
      requestJSON['health'][3]['value'] = answers['householdMedicare']
    }

    /** income & resources
     * householdEarn
     * earningPerMonth
     * childCarePerMonth
     * individual60Over
     * childSupportOutsideHome
     * householdAssets
     */

    // householdEarn
    if (typeof answers['householdEarn'] !== 'undefined') {
      requestJSON['income'][0]['value'] =
        answers['householdEarn'] !== ''
          ? answers['householdEarn']
          : amountDefaultValue
    }

    // earningPerMonth
    if (typeof answers['earningPerMonth'] !== 'undefined') {
      requestJSON['income'][1]['value'] =
        answers['earningPerMonth'] !== ''
          ? answers['earningPerMonth']
          : amountDefaultValue
    }

    // childCarePerMonth
    if (typeof answers['childCarePerMonth'] !== 'undefined') {
      requestJSON['income'][2]['value'] =
        answers['childCarePerMonth'] !== ''
          ? answers['childCarePerMonth']
          : amountDefaultValue
    }
    // individual60Over
    if (typeof answers['individual60Over'] !== 'undefined') {
      requestJSON['income'][3]['value'] =
        answers['individual60Over'] !== ''
          ? answers['individual60Over']
          : amountDefaultValue
    }

    // childSupportOutsideHome
    if (typeof answers['childSupportOutsideHome'] !== 'undefined') {
      requestJSON['income'][4]['value'] =
        answers['childSupportOutsideHome'] !== ''
          ? answers['childSupportOutsideHome']
          : amountDefaultValue
    }

    // householdAssets
    if (typeof answers['householdAssets'] !== 'undefined') {
      requestJSON['income'][5]['value'] =
        answers['householdAssets'] !== ''
          ? answers['householdAssets']
          : amountDefaultValue
    }
    // maybe call the fetch instead of returning or setting state here
    return requestJSON
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _fetchEligibleBenefits = answers => {
    var json1
    this.setState({ loaded: false })

    const submitAnswersEndpoint =
      this.props.config['gatewayWvUrl'] + this.props.config['benifitFinder']
    const transformedAnswers = this._translateAnswersFormToEP(answers)
    serviceHelper
      .fetchJson(
        submitAnswersEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json',
            uuid: this.props.auth.userAccount
              ? this.props.auth.userAccount.uuid || ''
              : '',
            tenantCode: config.tCode,
            Authorization: config.bearer + (this.props.auth.accessToken || ''),
            portalName: config.portalName
          },
          body: JSON.stringify(transformedAnswers)
        },
        this.props.showErrorMessage
      )
      .then(response => {
        let home_Energy_Assistance = _.map(response.programEligibility, ele => {
          if (ele.programName == 'LIHEAP') {
            return {
              ...ele,
              programName: 'Home Energy Assistance'
            }
          }
          return ele
        })
        let program = _.find(
          response.programEligibility,
          program => program.programName == 'Food & Nutrition'
        )
        if (program) {
          program.description = program.description.replace(/&lt;/g, '<')
        }
        response = {
          ...response,
          programEligibility: home_Energy_Assistance
        }
        if (!response.error) {
          this._digestBenefitResults(response)
          this._updateMainTab(1)
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.log('Failure')
        this.setState({
          errors: true
        })
      })
  }

  _digestBenefitResults = (eligibiliyResultsString): void => {
    this.setState({ jsonbenefits: eligibiliyResultsString, loaded: true })
  }

  _formContextUpdated = formContext => {
    this.setState({
      formContext: { ...this.state.formContext, ...formContext }
    })
  }

  _updateActiveTab = formActiveTab => {
    this.setState({ formActiveTab })
  }

  _updateMainTab = mainTab => {
    this.setState({ mainTab })
    this.divContent.current.focus()
    if (mainTab === 1) {
      document.title = I18n.t('General.title18')
    }
    this.setState({ activeTab: 5 })
  }

  _goToTab = tab => {
    this.setState({ mainTab: 0, formActiveTab: tab })
  }

  _printWindow() {
    window.print()
  }

  _showExit = () => {
    this.setState({ showExitModal: true })
  }

  _hideError = () => {
    this.setState({ showErrorModal: false })
  }

  _confirmExit = () => {
    let redirectUrl = '/myaccount/dashboard'
    if (
      this.props.roleName &&
      (this.props.roleName === 'BH_ADMIN_ASST' ||
        this.props.roleName === 'BH_HR_SPEC' ||
        this.props.roleName === 'BH_RES_COOR')
    ) {
      redirectUrl = '/myaccount/eligibleApplicants'
    }
    this.setState({
      showExitModal: false,
      redirect: baseUrl + redirectUrl
    })
  }

  _cancelExit = () => {
    this.setState({ showExitModal: false })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }
    const {
      benefits,
      numBenefits,
      answers,
      formData,
      formContext,
      mainTab,
      loaded,
      temp1,
      tabEnabled,
      jsonbenefits,
      setRedirect,
      showExit,
      showExitModal,
      confirmExit,
      cancelExit
    } = this.state

    const { errorMessage } = this.props

    let { formActiveTab } = this.state

    let content
    let enableFavorites = true
    let helpPopover = (
      <Popover id="helpPopover">
        <a href="/help" target="_blank">
          {I18n.t('FAQ.helpCenter')}
        </a>
      </Popover>
    )
    let helpLink = (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={helpPopover}
      >
        <button className="print btn btn-tiny btn-white">
          <span className="fa fa-life-ring fa-lg btn-icon-sm" />
          {I18n.t('FAQ.help')}
        </button>
      </OverlayTrigger>
    )
    let exitButton
    if (
      this.props.roleName === 'Presumptive Eligibility Worker' ||
      this.props.roleName === 'Community Partner' ||
      this.props.roleName === 'BH_ADMIN_ASST' ||
      this.props.roleName === 'BH_HR_SPEC' ||
      this.props.roleName === 'BH_RES_COOR'
    ) {
      exitButton = (
        <button
          className="print btn btn-tiny btn-white"
          onClick={this._showExit}
        >
          <span className="fa fa-life-ring btn-icon-sm" />
          Exit
        </button>
      )
    } else {
      //no exit button
    }

    return (
      <div>
        <BenefitFinderHeader />
        <div id="benefits-finder-content" className="main-content">
          <div className="nav-wrapper">
            <ul className="nav nav-pills offset-lg-2">
              <li className={mainTab == 0 ? 'active' : ''}>
                <button
                  className="btnBF btn-link-bf btn-headerTab btn btn-link"
                  onClick={() => this._updateMainTab(0)}
                  aria-pressed={mainTab == 0}
                >
                  {I18n.t('General.title20')}
                </button>
              </li>
              <li className={mainTab == 1 ? 'active' : ''}>
                <button
                  className="btnBF btn-link-bf btn-headerTab btn btn-link"
                  onClick={() => this._updateMainTab(1)}
                  aria-pressed={mainTab == 1}
                >
                  {I18n.t('General.title21')}
                </button>
              </li>
              <li className="li-btn-style">
                <div className="float-right">
                  {exitButton}
                  {helpLink}
                  <button
                    className="print btn btn-tiny btn-white"
                    onClick={this._printWindow}
                  >
                    <span className="fa fa-print fa-lg btn-icon-sm" />
                    {I18n.t('General.title22')}
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <MessageModal
            show={showExitModal}
            confirm={this._confirmExit}
            cancel={async () => this._cancelExit}
            confirmBtnText="Yes"
            closeBtnText="No"
            title={
              <span>
                <h4 className="popup-title"> {I18n.t('General.title23')}</h4>
              </span>
            }
            body={
              <div className="row">
                <div className="col-lg-12">
                  <h6 className="headertxt"> {I18n.t('General.title24')}</h6>
                </div>
              </div>
            }
            bsSize={'lg'}
          />
          <div
            ref={this.divContent}
            className="tab-content clearfix accessibility-focus"
          >
            <div className={(mainTab == 0 ? 'active' : 'hidden') + ' tab-pane'}>
              <BenefitFinderContent
                _fetchEligibleBenefits={this._fetchEligibleBenefits}
                benefits={benefits}
                formContext={this.state.formContext}
                numBenefits={numBenefits}
                temp1={temp1}
                _formContextUpdated={this._formContextUpdated.bind(this)}
                _updateActiveTab={this._updateActiveTab.bind(this)}
                activeTab={formActiveTab}
                tabEnabled={true}
                _updateMainTab={this._updateMainTab}
                enableFavorites={enableFavorites}
                loaded={loaded}
                mainTab={this.state.mainTab}
                onDeleteClick={this._onDeleteClick}
                {...this.props}
              />
            </div>
            <div className={(mainTab == 1 ? 'active' : 'hidden') + ' tab-pane'}>
              <ViewResults
                formContext={formContext}
                benefits={jsonbenefits}
                formData={formData}
                enableFavorites={enableFavorites}
              />
            </div>
            <div className={(mainTab == 2 ? 'active' : 'hidden') + ' tab-pane'}>
              <MyFavoritesView />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const error = state.myMessagesError.myMessagesError.errorMessage || []
  return {
    myFavorites: state.myFavorites,
    auth: state.auth,
    roleId: _.get(state.userAccess, 'selectedUserRole.role.roleId'),
    roleName: _.get(state.userAccess, 'selectedUserRole.role.roleName'),
    locale: state.i18n.locale,
    errorMessage: error
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addMyFavorite: name => {
      dispatch(actions.addMyFavorite(name))
    },
    removeMyFavorite: name => {
      dispatch(actions.removeMyFavorite(name))
    },
    showErrorMessage: message => {
      dispatch(wvActions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(wvActions.myMessagesDeleteError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsFinder)
