import * as React from 'react'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
import * as validator from '../src/validation'
import { config } from '../../../config' // endpoints
import * as actions from '../../../actions'
import * as _ from 'lodash' // extra functionality
import * as queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { imageMap } from '../../../images'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import 'airbnb-js-shims'
import '!style-loader!css-loader!../src/customStyles.css'
import { I18n } from 'react-redux-i18n'
import {
  _moment,
  jsonPathToId,
  resolveSchemaDefinitions
} from '@optum-wvie/dynamic-ui-framework/src/utils'
const moment = require('moment-mini-ts')
//import * as appIntakeSchemaa from './__mockForms__/AppIntake.json'

declare const process
const baseUrl = process.env.PUBLIC_URL

const gatewayWvUrl = config['gatewayWvUrl']
const CountiesBaseUrl = gatewayWvUrl + config['countiesStateUrl']

const formsEndpoint = config['forms_AppIntake']
const forms_AppIntakeFACCA = config['forms_AppIntake']
const validationErrors = config['forms_AppIntake_Errors']
const loadEndpoint = config['getDraftApplication']
const saveDraftEndpoint = gatewayWvUrl + config['saveApplication']
const submitEndpoint = gatewayWvUrl + config['submitApplication']
const submitIncompleteEndPoint =
  gatewayWvUrl + config['submitInCompleteApplication']
let savePoint
const submitRIDPEndpoint = config['RIDPSubmit']
const savePEDraftEndPoint = gatewayWvUrl + config['savePEApplication']
const submitPEDraftEndPoint = gatewayWvUrl + config['submitPEApplication']
const MAIDValidateEndpoint = gatewayWvUrl + config['validatePeMaidId']
const prgmDateRangeAEndpoint = config['prgmDateRange']

//Nav tab classes
//TODO: Move to presentation somehow?
const pristineIcon = ''

const editingIcon = 'glyphicon myglyphicon-pencil'
const editingBadge = 'badge bg-white-alt'

const validatedIcon = 'glyphicon myglyphicon-ok'
const validatedBadge = 'badge bg-green-alt'

const errorIcon = 'glyphicon myglyphicon-circle_exclamation_mark'
const errorBadge = 'badge bg-yellow-alt'

//This is for step-specific logic to not assume the step number.  Keep this updated.
const STEPS = {
  START: 1,
  PROGRAM_SERVICE_SELECTION: 2,
  PERSONAL: 3,
  CITIZENSHIP_AND_RESIDENCY: 4,
  HEALTH: 5,
  FAMILY_AND_HOUSEHOLD: 6,
  CHILD_CARE: 7,
  CHILD_SUPPORT: 8,
  INCOME_AND_RESOURCES: 9,
  RETROACTIVE_REQUEST: 10,
  ADDITIONAL_QUESTIONS: 11,
  DOCUMENT_UPLOAD: 12,
  REVIEW_AND_SIGN: 13
}

interface MyApplicationAction {
  applId: string
  status: string
  applicationData: Object
}

interface AppIntakeContainerProps {
  presentation: any
  location: {
    search: string
  }
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userRoleId: string
  roleId: number
  ieOrgId: any
  userId: string
  activeApplicationId: string
  myApplicationData: any
  step: number
  saveMAID: {
    maid: string
    peId: string
  }
  addMyApplication: (...MyApplicationAction) => void
  removeMyApplication: (applId: string) => void
  updateMyApplication: (...MyApplicationAction) => void
  setActiveApplicationId: (applId: string) => void
  assignApplicationId: (applId: string) => void
  updateMyApplicationStep: (applId: string, step: number) => void
  updateMyApplicationTabStatus: (applId: string, tabStatus: any) => void
  isUnitTest: boolean
  tabStatus: {
    name: string
    status: string
    accordionActiveKey: number
  }[]
  selectedEntitlements: Array<string>
  orgId: string
  roleName: string
  serviceErrors: Array<any>
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  _switchOnLiveValidate: any
  logoutUser
  locale
}

interface AppIntakeContainerState {
  application: Object
  forms: {
    schema: Object
    uiSchema: Object
    metaData: Object
  }[]
  formContext: any
  liveValidate: boolean
  viewId: string
  errorMessage: object
  contributorModalProps: {
    show: boolean
    fieldType: string
    form: {
      schema: Object
      uiSchema: Object
      refs: Object
    }
    targetFieldId: string
  }
  redirect: string
  showDomesticViolenceModal?: boolean
  showNext: boolean
  formDataSubmitted: boolean
  callingApi: boolean
  showDraftModal: boolean
  showSubmissionModal: boolean
  tabs: {
    status: string
    leftIcon: string
    rightIcon: string
    rightSpan: string
    isVisible: boolean
  }[]
  RIDPQuestions: object[]
  slideIndex: number
  slides: Array<{
    localUiSchema: {
      clients: {
        items: object
      }
      assister: object
    }
  }>
  showExitModal: boolean
  peAppId: string
  mediId: string
  tabFocus: boolean
  incomplete: any
  reneCovInd: number
  lieapFailedText: boolean
  scaFailedText: boolean
  currentDate: any
  submitClicked?: any
  citizenShipOrChildSupportStep?: boolean
  errorMessages
}

class AppIntakeContainer extends React.Component<
  AppIntakeContainerProps,
  AppIntakeContainerState
> {
  private hasError = false
  constructor(props: AppIntakeContainerProps) {
    super(props)
    document.addEventListener('keydown', this.onFocus)
    this.state = {
      application: {},
      tabs: [],
      errorMessage: {
        courtOrder: false,
        birthCertificate: []
      },
      forms: null,
      formContext: {},
      liveValidate: false,
      viewId: null,
      contributorModalProps: {
        show: false,
        fieldType: null,
        form: null,
        targetFieldId: null
      },
      redirect: null,
      showNext: false,
      formDataSubmitted: false,
      callingApi: false,
      showDraftModal: false,
      showSubmissionModal: false,
      RIDPQuestions: null,
      slideIndex: 0,
      slides: [],
      showExitModal: false,
      peAppId: null,
      mediId: null,
      tabFocus: false,
      incomplete: this.getQueryParamFromUrl('incomplete') ? true : false,
      reneCovInd: null,
      lieapFailedText: false,
      scaFailedText: false,
      currentDate: getServerTime(),
      errorMessages: {}
    }
    this._exitDomesticViolenceModal = this._exitDomesticViolenceModal.bind(this)
  }

  getQueryParamFromUrl(field) {
    let url = window.location.href
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i')
    var string = reg.exec(url)

    return string ? decodeURIComponent(string[1]) : null
  }

  componentDidMount() {
    let applId = this.props.activeApplicationId
    switch (this.props.step) {
      case 1:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Start - Benefits Application'
        break
      case 2:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Personal - Benefits Application'
        break
      case 3:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Personal - Benefits Application'
        break
      case 4:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Citizenship And Residency - Benefits Application'
        break
      case 5:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Health - Benefits Application'
        break
      case 6:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Family And Household - Benefits Application'
        break
      case 7:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Child Care - Benefits Application'
        break
      case 8:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Child Support - Benefits Application'
        break
      case 9:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Income And Resources - Benefits Application'
        break
      case 10:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Retroactive Request - Benefits Application'
        break
      case 11:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Additional Questions - Benefits Application'
        break
      case 12:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Document Upload - Benefits Application'
        break
      case 13:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Review And Sign - Benefits Application'
        break
    }
    if (
      this.props.location &&
      this.props.location.search &&
      !this.state.incomplete
    ) {
      try {
        const query = queryString.parse(this.props.location.search)
        if (applId !== query.applId) {
          applId = query.applId
          this.props.setActiveApplicationId(applId)
        }
        if (applId !== query.peApplId) {
          this.setState({ peAppId: query.peApplId })
        }
        if (applId !== query.mediId) {
          this.setState({ mediId: query.mediId })
        }
      } catch (err) {
        console.error('Error on handling applId from URL query', err)
      }
    }

    const endpoint = config.childSupportIntegrated
      ? formsEndpoint.replace('{version}', '1.0')
      : forms_AppIntakeFACCA.replace('{version}', '1.0')
    const validationErrorsEndPoint = validationErrors.replace(
      '{version}',
      '1.0'
    )

    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid
          : '',
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }

    Promise.all([
      helper.fetchJson(endpoint, request),
      helper.fetchJson(validationErrorsEndPoint, request)
    ])
      .then(response => {
        let formJson = response[0]
        this.prgmDateRangeSelectionSCA()
        this.prgmDateRangeSelectionLIEAP()
        this.prgmDateRangeSelectionEmergencyLIEAP()
        if (applId !== '0' && !this.state.incomplete) {
          this._loadDraft(loadEndpoint, formJson, applId, response[1])
        } else {
          this._initializeForm(
            this.props.myApplicationData,
            formJson,
            applId,
            this.props.step,
            this.props.tabStatus,
            response[1]
          )
        }
      })
      .catch(error => {
        console.error('AppIntake form fetch failed due to ex', error)
      })

    if (this.state.formContext.formData.Application) {
      let tempFormData = { ...this.state.formContext.formData }
      this._addChildSupportsFeildsToSchema(tempFormData)
      this.setState(prevState => {
        return {
          ...prevState,
          formContext: {
            ...prevState.formContext,
            formData: this.state.formContext.formData
          }
        }
      })
    }
    let sideNavLink = this._createSkipLink(
      '#side-nav',
      'skip-to-side-nav',
      'Skip to side Navigation'
    )
    const navContent = document.getElementById('nav-content')
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      navContent.insertBefore(sideNavLink, mainNavContent)
    }
  }

  prgmDateRangeSelectionSCA() {
    const fetchEndpoint = prgmDateRangeAEndpoint.replace('{progCd}', 'SCA')
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid
          : '',
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(fetchEndpoint, request)
      .then(json => {
        if (json.length > 0) {
          let startDt = _moment(config, json[0].startDt, 'YYYY-MM-DD').format(
            'MM/DD/YYYY'
          )
          let endDate = _moment(config, json[0].endDt, 'YYYY-MM-DD').format(
            'MM/DD/YYYY'
          )

          let currentDate = _moment(config, this.state.currentDate).format(
            'MM/DD/YYYY'
          )

          let startDate = new Date(startDt)
          let endDt = new Date(endDate)
          let date = new Date(currentDate)
          if (!(startDate <= date && endDt >= date)) {
            this.setState({
              formContext: {
                ...this.state.formContext,
                prgmDateSCLA: true
              }
            })
          }
        }
        if (json.length === 0) {
          this.setState({
            formContext: {
              ...this.state.formContext,
              prgmDateSCLA: true
            }
          })
        }
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.setState({ redirect: baseUrl + '/home' })
          this.props.logoutUser()
        }
        this.setState({
          formContext: {
            ...this.state.formContext,
            prgmDateSCLA: true,
            scaFailedText: true
          }
        })
      })
  }

  prgmDateRangeSelectionEmergencyLIEAP() {
    const fetchEndpoint = prgmDateRangeAEndpoint.replace('{progCd}', 'ELIEAP')
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid
          : '',
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(fetchEndpoint, request)
      .then(json => {
        if (json.length > 0) {
          let startDt = _moment(config, json[0].startDt, 'YYYY-MM-DD').format(
            'MM/DD/YYYY'
          )
          let endDate = _moment(config, json[0].endDt, 'YYYY-MM-DD').format(
            'MM/DD/YYYY'
          )

          let currentDate = _moment(config, this.state.currentDate).format(
            'MM/DD/YYYY'
          )

          let startDate = new Date(startDt)
          let endDt = new Date(endDate)
          let date = new Date(currentDate)
          if (!(startDate <= date && endDt >= date)) {
            this.setState({
              formContext: {
                ...this.state.formContext,
                showELIEAP: false
              }
            })
          }
        }
        if (json.length === 0) {
          this.setState({
            formContext: {
              ...this.state.formContext,
              showELIEAP: false
            }
          })
        }
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.setState({ redirect: baseUrl + '/home' })
          this.props.logoutUser()
        }
        this.setState({
          formContext: {
            ...this.state.formContext,
            showELIEAP: false
          }
        })
      })
  }

  _setReneCovInd(value) {
    this.setState({
      reneCovInd: value
    })
  }

  prgmDateRangeSelectionLIEAP() {
    const fetchEndpoint = prgmDateRangeAEndpoint.replace('{progCd}', 'LIEAP')
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid
          : '',
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(fetchEndpoint, request)
      .then(json => {
        if (json.length > 0) {
          let startDt = _moment(config, json[0].startDt, 'YYYY-MM-DD').format(
            'MM/DD/YYYY'
          )
          let endDate = _moment(config, json[0].endDt, 'YYYY-MM-DD').format(
            'MM/DD/YYYY'
          )
          let currentDate = _moment(config, this.state.currentDate).format(
            'MM/DD/YYYY'
          )

          let startDate = new Date(startDt)
          let endDt = new Date(endDate)
          let date = new Date(currentDate)
          if (!(startDate <= date && endDt >= date)) {
            this.setState({
              formContext: {
                ...this.state.formContext,
                prgmDateHMEN: true
              }
            })
          }
        }
        if (json.length === 0) {
          this.setState({
            formContext: {
              ...this.state.formContext,
              prgmDateHMEN: true
            }
          })
        }
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({
          formContext: {
            ...this.state.formContext,
            prgmDateHMEN: true,
            lieapFailedText: true
          }
        })
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { viewId, tabs, tabFocus } = this.state
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    let elem: HTMLElement
    elem = document.getElementById('hideNoInfo')
    if (elem) {
      elem.parentElement.parentElement.classList.add('alginmentNoInfo')
    }

    if (viewId != null) {
      //Have to do this since componentDidUpdate runs before real DOM is updated.
      try {
        setTimeout(function() {
          window.requestAnimationFrame(function() {
            const element = document.getElementById(viewId)
            let offset = helper.getOffsetTop(document.getElementById(viewId))
            element.focus()
            window.scroll(0, offset - 100)
          })
        }, 500)
      } catch (e) {
        console.error('AppIntake viewId setTimeout failed with ex', e)
      }

      this.setState({ viewId: null })
    }

    this._updateTabs(prevProps, prevState)

    if (!_.isEqual(prevProps.userRoleId, this.props.userRoleId)) {
      //The user switched their role! Redirect back to home.
      //TODO: global config for target landing page by role/entitlement?
      if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
        )
      ) {
        this._setRedirect('/myaccount/dashboard')
      } else if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.COMMUNITY_PARTNERSHIP
        )
      ) {
        this._setRedirect('/myaccount/dashboard')
      } else {
        this._setRedirect('/home')
      }
    }
    if (prevProps.step === 2 && this.props.step === 1) {
      this.setState(prevState => {
        return {
          ...prevState,
          formContext: {
            ...prevState.formContext,
            slideIndex: 3
          }
        }
      })
    }
    if (navContent) {
      if (sideNavLink) {
        sideNavLink.textContent = 'Skip to side Navigation'
      }
    }
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      if (!sideNavLink) {
        let sideNavLink = this._createSkipLink(
          '#side-nav',
          'skip-to-side-nav',
          'Skip to side Navigation'
        )
        navContent.insertBefore(sideNavLink, mainNavContent)
      }
    }
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      const endpoint = config.childSupportIntegrated
        ? formsEndpoint.replace('{version}', '1.0')
        : forms_AppIntakeFACCA.replace('{version}', '1.0')
      const validationErrorsEndPoint = validationErrors.replace(
        '{version}',
        '1.0'
      )

      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount
            ? this.props.auth.userAccount.uuid
            : '',
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          locale: (this.props.locale + '_US').toUpperCase()
        }
      }
      Promise.all([
        helper.fetchJson(endpoint, request),
        helper.fetchJson(validationErrorsEndPoint, request)
      ])
        .then(res => {
          // const formJson = AppIntake
          const formJson = res[0]
          const errorMessages = res[1]
          this._initializeForm(
            this.props.myApplicationData,
            formJson,
            this.props.activeApplicationId,
            this.props.step,
            this.props.tabStatus,
            errorMessages
          )
        })
        .catch(error => {
          console.error('AppIntake form fetch failed due to ex', error)
        })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onFocus)
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (navContent) {
      if (sideNavLink) {
        navContent.removeChild(sideNavLink)
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.myApplicationData.Application &&
      props.myApplicationData.Application.clients &&
      Object.keys(props.myApplicationData).length > 0
    ) {
      for (
        let i = 0;
        i < props.myApplicationData.Application.clients.length;
        i++
      ) {
        if (
          props.myApplicationData.Application.clients[i].disabledHousehold ===
            'N' ||
          props.myApplicationData.Application.clients[i].disabledHousehold ===
            'false'
        ) {
          props.myApplicationData.Application.clients[
            i
          ].disabledHousehold = false
        } else if (
          props.myApplicationData.Application.clients[i].disabledHousehold ===
            'Y' ||
          props.myApplicationData.Application.clients[i].disabledHousehold ===
            'true'
        ) {
          props.myApplicationData.Application.clients[
            i
          ].disabledHousehold = true
        }
      }
    }
    return {
      formContext: {
        ...state.formContext,
        formData: props.myApplicationData,
        reviewFormData: props.myApplicationData
      }
    }
  }

  _createSkipLink = (href, id, text = '') => {
    let skipLink = document.createElement('a')
    skipLink.setAttribute('class', 'skip')
    skipLink.setAttribute('href', href)
    skipLink.setAttribute('id', id)
    if (text) {
      let skipLinkText = document.createTextNode(text)
      skipLink.appendChild(skipLinkText)
    }
    return skipLink
  }

  _focusMainContent = () => {
    const mainContent = document.getElementById('content')
    if (mainContent) {
      mainContent.focus()
    }
  }

  _changeActiveAccordionKey = activeKey => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        accordionActiveKey: activeKey
      }
    })
  }

  _changeView = (val: number) => {
    let { tabFocus } = this.state
    if (this.props.tabStatus[val].status === 'error') {
      this.setState({
        liveValidate: true
      })
      if (tabFocus) {
        this._focusMainContent()
      }
      this._changeStep(val + 1)
    } else {
      this.setState(
        {
          liveValidate: false
        },
        () => {
          if (tabFocus) {
            this._focusMainContent()
          }
          this._changeStep(val + 1)
        }
      )
    }
  }

  _loadDraft = (endpoint, formsJson, applId, errorMessages) => {
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        userRoleId: this.props.userRoleId
      }
    }

    const fetchEndpoint = endpoint.replace('{applId}', applId)

    this.setState({ callingApi: true })
    helper
      .fetchJson(fetchEndpoint, request)
      .then(formData => {
        const newModifiedFormData = formData
        this.setState({ callingApi: false })
        let newFormData = newModifiedFormData
        const clients = newModifiedFormData.Application.clients
        clients.forEach(client => {
          if (client.alternativeNameDetails) {
            client.altNameDetails = [client.alternativeNameDetails]
          }
        });
        let counter = 0
        if (this.props.saveMAID.maid) {
          const newClients = clients.map(client => {
            if (counter === 0) {
              counter = counter + 1
              return {
                ...client,
                temporaryMaidNumber: this.props.saveMAID.maid
              }
            } else {
              counter = counter + 1
              return client
            }
          })
          newFormData = {
            ...formData,
            Application: {
              ...formData.Application,
              clients: newClients
            }
          }
        }
        this._initializeForm(
          _.omit(newFormData, ['step', 'tabStatus']),
          formsJson,
          applId,
          newFormData.step ? STEPS[newFormData.step] : this.props.step,
          newFormData.tabStatus || this.props.tabStatus,
          errorMessages
        )
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_getFormData failed with error:', error)
      })
  }

  _saveFormDataDraft = (showModal: boolean, step: number, tabStatus: any) => {
    if (!this.state.incomplete) {
      let myApplicationData = _.cloneDeep(this.props.myApplicationData)
      let savePoint
      let request
      if (
        typeof myApplicationData['Application']['agreement'] !== 'undefined' ||
        typeof myApplicationData['Application']['signatureReceived'] !==
          'undefined'
      ) {
        _.unset(myApplicationData, 'Application.agreement')
        _.unset(myApplicationData, 'Application.signatureReceived')
      }

      if (!_.isEmpty(myApplicationData)) {
        let clients = myApplicationData.Application.clients || []
        if (clients.length > 0) {
          for (var i = 0; i < clients.length; i++) {
            clients[i].clientId = i
          }
        }
        _.set(myApplicationData, 'Application.clients', clients)
        if (clients.length > 0) {
          for (let m = 0; m < clients.length; m++) {
            let releationShips = clients[m].clientRelationships
            if (releationShips) {
              for (let n = 0; n < releationShips.length; n++) {
                releationShips[n].applicantBthDt = _moment(
                  config,
                  releationShips[n].applicantBthDt,
                  'MM/DD/YYYY'
                ).format('YYYY-MM-DD')
              }
            }
          }
        }
        if (
          _.includes(
            _.get(myApplicationData, 'Application.benefitCategorieslist', []),
            'HEWL'
          )
        ) {
          myApplicationData.Application.reneCovInd = this.state.reneCovInd
        }
      }
      if (_.invert(STEPS)[step] === 'PROGRAM_SERVICE_SELECTION') {
        _.set(myApplicationData, 'step', 'PERSONAL')
      } else {
        _.set(myApplicationData, 'step', _.invert(STEPS)[step])
      }
      _.set(myApplicationData, 'tabStatus', tabStatus)

      if (
        this.props.roleName.includes('Presumptive Eligibility') ||
        this.props.roleName.includes('PE Admin')
      ) {
        let _id = this.props.saveMAID.peId
        let _mediId = this.props.saveMAID.maid
        savePoint = savePEDraftEndPoint
        if (this.state.peAppId != undefined) {
          _id = this.state.peAppId
        }
        if (this.state.mediId != undefined) {
          _mediId = this.state.mediId
        }
        request = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            tenantId: '1',
            applType: 'APPL',
            uuid: this.props.auth.userAccount.uuid,
            orgId: this.props.orgId,
            userRoleId: this.props.userRoleId.toString(),
            roleId: this.props.roleId.toString(),
            userId: this.props.userId,
            maidId: _mediId,
            peId: _id,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            ieOrgId: this.props.ieOrgId
          },
          body: JSON.stringify(myApplicationData)
        }
      } else if (
        this.props.roleName.includes('Community Partner') ||
        this.props.roleName.includes('CP Admin')
      ) {
        savePoint = saveDraftEndpoint
        request = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            tenantId: '1',
            applType: 'APPL',
            uuid: this.props.auth.userAccount.uuid,
            orgId: this.props.orgId,
            userRoleId: this.props.userRoleId.toString(),
            roleId: this.props.roleId.toString(),
            userId: this.props.userId,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            ieOrgId: this.props.ieOrgId
          },
          body: JSON.stringify(myApplicationData)
        }
      } else {
        savePoint = saveDraftEndpoint
        if (
          this.props.activeApplicationId != '0' &&
          !myApplicationData.applId
        ) {
          myApplicationData = {
            ...myApplicationData,
            Application: {
              ...myApplicationData.Application,
              applId: this.props.activeApplicationId
            },
            applId: this.props.activeApplicationId
          }
        }
        request = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            tenantId: '1',
            applType: 'APPL',
            uuid: this.props.auth.userAccount.uuid,
            orgId: this.props.orgId,
            userRoleId: this.props.userRoleId.toString(),
            roleId: this.props.roleId.toString(),
            userId: this.props.userId,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            ieOrgId: this.props.ieOrgId
          },
          body: JSON.stringify(myApplicationData)
        }
      }

      this.setState({ callingApi: true })
      serviceHelper
        .fetchJson(savePoint, request, this.props.showErrorMessage)
        .then(response => {
          const applId = response.applId
          this.setState({
            callingApi: false,
            showDraftModal: showModal,
            showSubmissionModal: false
          })
          if (applId && this.props.activeApplicationId === '0') {
            let today = new Date(getServerTime())
            let submissionDate = today.toUTCString()
            let newFormData = {
              ...this.props.myApplicationData,
              Application: {
                ...(this.props.myApplicationData
                  ? this.props.myApplicationData.Application
                  : {}),
                applId: applId,
                submissionDate: submissionDate
              }
            }
            this.props.assignApplicationId(applId)
            this.props.setActiveApplicationId(applId)
            this.props.updateMyApplication(applId, 'DRAFT', newFormData)
          }
        })
        .catch(error => {
          if (error instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          this.setState({ callingApi: false })
          console.error('_saveFormDataDraft failed with error:   ', error)
        })
    }
  }

  _exitFromApplication = () => {
    this.setState(prevState => {
      return { ...prevState, showExitModal: !prevState.showExitModal }
    })
  }
  _determineTabStatus = tabs => {
    let newTabStatus
    if (this.props.tabStatus.length > 0) {
      newTabStatus = this.props.tabStatus.map(element => {
        return {
          ...element,
          status: tabs[STEPS[element.name] - 1].status
        }
      })
    } else {
      let i = 0
      newTabStatus = []
      _.forEach(STEPS, (value, key) => {
        newTabStatus.push({
          name: key,
          status: tabs[i++].status
        })
      })
    }
    return newTabStatus
  }

  _submitFormData = () => {
    let myApplicationData = helper.deepClone(this.props.myApplicationData)
    let today = new Date(getServerTime())
    let submissionDate = today.toUTCString()
    myApplicationData.Application.submissionDate = submissionDate
    if (
      _.includes(
        _.get(myApplicationData, 'Application.benefitCategorieslist', []),
        'HEWL'
      )
    ) {
      myApplicationData.Application.reneCovInd = this.state.reneCovInd
    }
    if (!_.isEmpty(myApplicationData)) {
      let clients = myApplicationData.Application.clients || []
      if (clients.length > 0) {
        for (var i = 0; i < clients.length; i++) {
          clients[i].clientId = i
        }
      }
      _.set(myApplicationData, 'Application.clients', clients)
      if (clients.length > 0) {
        for (let m = 0; m < clients.length; m++) {
          let releationShips = clients[m].clientRelationships
          if (releationShips) {
            for (let n = 0; n < releationShips.length; n++) {
              releationShips[n].applicantBthDt = _moment(
                config,
                releationShips[n].applicantBthDt
              ).format('YYYY-MM-DD')
            }
          }
        }
      }
    }
    if (
      (myApplicationData.Application.clientApplicant[0].roleOfApplicant ===
        'Y' &&
        myApplicationData.Application.clientsObligor[0]
          .nonCustodialParentObligor) ||
      myApplicationData.Application.clientApplicant[0].roleOfApplicant === 'N'
    ) {
      _.set(
        myApplicationData,
        'Application.clientsObligor[0].obligorEmployed.Address.st',
        _.noop()
      )
      _.set(
        myApplicationData,
        'Application.clientsObligor[0].Address.st',
        _.noop()
      )
    }

    if (myApplicationData.Application.clientsGrandParents[0] !== undefined) {
      if (
        myApplicationData.Application.clientsGrandParents[0].obligeeParents ===
        undefined
      ) {
        _.set(
          myApplicationData,
          'Application.clientsGrandParents[0].obligeeParents',
          []
        )
      }
      if (
        myApplicationData.Application.clientsGrandParents[0].obligorParents ===
        undefined
      ) {
        _.set(
          myApplicationData,
          'Application.clientsGrandParents[0].obligorParents',
          []
        )
      }
    }
    let request
    if (
      this.props.roleName.includes('Presumptive Eligibility') ||
      this.props.roleName.includes('PE Admin')
    ) {
      let _id = this.props.saveMAID.peId
      let _mediId = this.props.saveMAID.maid
      savePoint = submitPEDraftEndPoint
      if (this.state.peAppId != undefined) {
        _id = this.state.peAppId
      }
      if (this.state.mediId != undefined) {
        _mediId = this.state.mediId
      }
      request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          tenantId: '1',
          applType: 'APPL',
          uuid: this.props.auth.userAccount.uuid,
          orgId: this.props.orgId,
          userRoleId: this.props.userRoleId.toString(),
          roleId: this.props.roleId.toString(),
          userId: this.props.userId,
          maidId: _mediId,
          peId: _id,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          ieOrgId: this.props.ieOrgId
        },
        body: JSON.stringify(myApplicationData)
      }
    } else if (
      this.props.roleName.includes('Community Partner') ||
      this.props.roleName.includes('CP Admin')
    ) {
      savePoint = submitEndpoint
      request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          tenantId: '1',
          applType: 'APPL',
          uuid: this.props.auth.userAccount.uuid,
          orgId: this.props.orgId,
          userRoleId: this.props.userRoleId.toString(),
          roleId: this.props.roleId.toString(),
          userId: this.props.userId,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          ieOrgId: this.props.ieOrgId
        },
        body: JSON.stringify(myApplicationData)
      }
    } else {
      if (!this.state.incomplete) {
        savePoint = submitEndpoint
        myApplicationData.Application.inCompleteSNAPApplication = 'N'
      } else {
        savePoint = submitIncompleteEndPoint
        myApplicationData.Application.inCompleteSNAPApplication = 'Y'
      }
      request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          tenantId: '1',
          applType: 'APPL',
          uuid: this.props.auth.userAccount
            ? this.props.auth.userAccount.uuid
            : null,
          orgId: this.props.orgId,
          userRoleId: this.props.userRoleId.toString(),
          roleId: this.props.roleId.toString(),
          userId: this.props.userId,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          ieOrgId: this.props.ieOrgId
        },
        body: JSON.stringify(myApplicationData)
      }
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(savePoint, request, this.props.showErrorMessage)
      .then(json => {
        this.setState(
          {
            callingApi: false,
            showSubmissionModal: true,
            formDataSubmitted: true
          },
          () =>
            this.props.updateMyApplication(
              this.props.activeApplicationId,
              'SUBMITTED',
              myApplicationData
            )
        )
        this.setState({
          RIDPQuestions: _.get(json, 'questions', null)
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  submitIdentityProof = data => {
    const body = {
      ...this.props.myApplicationData,
      ridpAnswers: data
    }

    const request = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      },
      body: JSON.stringify(body)
    }

    this.setState({ callingApi: true })

    return serviceHelper
      .fetchJson(submitRIDPEndpoint, request, this.props.showErrorMessage)
      .then(json => {
        this.setState({ callingApi: false })

        return json
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error('error in submit RIDP response')
        this.setState({ callingApi: false })
      })
  }

  closeSubmissionModal = () => {
    this.setState({ showSubmissionModal: false })
  }

  _updateClientRelationship(json) {
    let application = json.Application
    let Application = _.cloneDeep(json.Application)
    if (!_.isEmpty(application)) {
      let clients = application.clients
      for (var i = 0; i < clients.length; i++) {
        let clientRelationships = []
        for (var j = 0, k = j + 1; j < clients.length; j++) {
          let clientRelationshipData
          if (i != j) {
            if (!_.isEmpty(clients[i].clientRelationships)) {
              if (j < i && !_.isEmpty(clients[i].clientRelationships[j])) {
                clientRelationshipData =
                  clients[i].clientRelationships[j].clientRelationship
              } else {
                if (!_.isEmpty(clients[i].clientRelationships[j - 1])) {
                  clientRelationshipData =
                    clients[i].clientRelationships[j - 1].clientRelationship
                }
              }
            }
            let caringfor = undefined
            if (clients[i].clientRelationships && k < clients.length)
              if (clients[i].clientRelationships[k - 1]) {
                let keys = Object.keys(clients[i].clientRelationships[k - 1])
                for (var l = 0; l < keys.length; l++) {
                  if (keys[l] === 'caringFor')
                    caringfor =
                      clients[i].clientRelationships[k - 1].caringFor !=
                      undefined
                        ? clients[i].clientRelationships[k - 1].caringFor
                        : undefined
                }
              }
            let clientRelationship = {
              applicantFstNm: clients[j].clientFstNm,
              applicantBthDt: _moment(
                config,
                clients[j].clientBthDt,
                'YYYY-MM-DD'
              ).format('MM/DD/YYYY'),
              clientRelationship: clientRelationshipData,
              caringFor: caringfor,
              clientId: i,
              clientRelationId: j
            }
            clientRelationships.push(clientRelationship)
          }
        }
        clients[i].clientRelationships = clientRelationships
      }
    }
    if (Application && Application.clients)
      for (let n = 0; n < Application.clients.length; n++) {
        for (let m = 0; m < Application.clients.length - 1; m++) {
          if (
            Application.clients[n].clientRelationships &&
            Application.clients[n].clientRelationships[m]
          ) {
            json.Application.clients[n].clientRelationships[m].caringFor =
              Application.clients[n].clientRelationships[m].caringFor
          }
        }
      }
    return json
  }

  _initializeForm = (
    formData,
    formsJson,
    applId,
    step,
    tabStatus,
    errorMessages
  ) => {
    let tabs = [] // navigation tabs
    let forms, application, formContext
    application = formsJson['app']
    forms = application['forms']

    // Used to remove null objects to prevent validation errors
    helper.cleanNullValues(formData)

    formData = this._updateClientRelationship(formData)
    for (let i = 0; i < forms.length; i++) {
      //Initialize tabs using uiSchema, with status set as pristine
      if (typeof forms[i].uiSchema['externalOptions'] !== 'undefined') {
        let options = forms[i].uiSchema['externalOptions']
        if (typeof options.tab !== 'undefined') {
          let status = 'pristine'
          let rightIcon = pristineIcon
          let rightSpan = null
          if (tabStatus && tabStatus[i]) {
            status = tabStatus[i].status
            switch (tabStatus[i].status) {
              case 'validated':
                rightIcon = validatedIcon
                rightSpan = validatedBadge
                break
              case 'editing':
                rightIcon = editingIcon
                rightSpan = editingBadge
                break
              case 'error':
                rightIcon = errorIcon
                rightSpan = errorBadge
                break
              case 'pristine':
              default:
                rightIcon = pristineIcon
                rightSpan = null
            }
          }
          tabs.push({
            title: options.tab.tabName,
            leftIcon: options.tab.iconClassName,
            rightIcon,
            rightSpan,
            status,
            visibleIf: options.tab.visibleIf,
            isVisible: true
          })
        }
        if (i === 0) {
          let columnArray = []
          columnArray.push('First Name')
          columnArray.push('Last Name')
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(1, 'months')
              .format('MMMM')
          )
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(2, 'months')
              .format('MMMM')
          )
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(3, 'months')
              .format('MMMM')
          )
          let slides = forms[0].uiSchema['Application']['ui:options']['slides']
          let slideIndex = _.findIndex(
            slides,
            'localUiSchema.clients.items.livesTogetherFirstMonth'
          )
          _.set(
            forms,
            '0.uiSchema.Application.ui:options.slides.' +
              slideIndex +
              '.localUiSchema.clients.ui:options.columns',
            columnArray
          )
          _.set(
            forms,
            '0.schema.definitions.client.properties.livesTogetherFirstMonth.title',
            columnArray[2]
          )
          _.set(
            forms,
            '0.schema.definitions.client.properties.livesTogetherSecondMonth.title',
            columnArray[3]
          )
          _.set(
            forms,
            '0.schema.definitions.client.properties.livesTogetherThirdMonth.title',
            columnArray[4]
          )
        }
        if (i === STEPS.RETROACTIVE_REQUEST) {
          let columnArray = []
          columnArray.push('Source')
          columnArray.push('Income Type')
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(1, 'months')
              .format('MMMM')
          )
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(2, 'months')
              .format('MMMM')
          )
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.earnedIncomeRetro.ui:options.columns',
            columnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.unEarnedIncomeRetro.ui:options.columns',
            columnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.selfEmpIncomeRetro.ui:options.columns',
            columnArray
          )
          let expenseColumnArray = []
          expenseColumnArray.push('Start Date')
          expenseColumnArray.push('Share with someone')
          expenseColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          expenseColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          expenseColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.expenseRetroDetails.expenseRetro.ui:options.columns',
            expenseColumnArray
          )
          let cashColumnArray = []
          cashColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          cashColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          cashColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.cashRetro.ui:options.columns',
            cashColumnArray
          )
          let bankAccountColumnArray = []
          bankAccountColumnArray.push('Source')
          bankAccountColumnArray.push('Account Number')
          bankAccountColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          bankAccountColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          bankAccountColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.bankAccountRetro.ui:options.columns',
            bankAccountColumnArray
          )
          let vehicleColumnArray = []
          vehicleColumnArray.push('Make')
          vehicleColumnArray.push('Model')
          vehicleColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          vehicleColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          vehicleColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.vehicleRetro.ui:options.columns',
            vehicleColumnArray
          )
          let fundsColumnArray = []
          fundsColumnArray.push('Type')
          fundsColumnArray.push('Account Number')
          fundsColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          fundsColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          fundsColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.trustFundsRetro.ui:options.columns',
            fundsColumnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.investmentRetro.ui:options.columns',
            fundsColumnArray
          )
          let realEstateColumnArray = []
          realEstateColumnArray.push('Type')
          realEstateColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          realEstateColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          realEstateColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.realEstateRetro.ui:options.columns',
            realEstateColumnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.otherRetro.ui:options.columns',
            realEstateColumnArray
          )
        }
      }
    }
    const presumptiveEligibilityWorker =
      this.props.selectedEntitlements &&
      this.props.selectedEntitlements.find(entitlement => {
        return entitlement === 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
      })
    if (presumptiveEligibilityWorker) {
      formData = {
        ...formData,
        Application: {
          ...formData.Application,
          benefitCategorieslist: ['HEWL']
        }
      }
    }
    if (this.state.incomplete) {
      formData = {
        Application: {
          benefitCategorieslist: ['FDNT']
        }
      }
      this.setState({
        formContext: {
          ...formContext,
          formData: formData,
          isIncomplete: true
        }
      })
      tabs = tabs.map(ele => {
        return {
          ...ele,
          status: 'pristine',
          rightIcon: ''
        }
      })
      this._changeStep(1)
    }
    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    let date1 = _moment(config, this.state.currentDate).format('MM/DD/YYYY')
    let date = new Date(date1)
    formContext = {
      ...this.state.formContext,
      changeActiveAccordionKey: this._changeActiveAccordionKey,
      refs: {
        ...application['metaData']['refs'],
        '{nameofOtherPerson star}': ({ formContext, fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          let dependentCareIndex = fieldId.match(
            '(?:.*)dependentCareArray_([0-9]+)_?'
          )[1]
          let assetOwnedBy = _.get(
            formContext,
            `formData.Application.clients[${index}].otherFamilyExpenseDetail[1].otherFamilyExpense[0].dependentCareArray[${dependentCareIndex}].whoElseOwnThisAssets`,
            ''
          )
          if (assetOwnedBy === 'Other') {
            return '*'
          } else {
            return ''
          }
        },
        '{show WVWORKS}': formContext => {
          return !formContext.hideWVWORKS
        },
        '{WVWORKSText}': formContext => {
          if (!formContext.hideWVWORKS) {
            return ' and/or WV WORKS benefits '
          } else {
            return ' benefit '
          }
        },
        '{firm name or gal}': ({ fieldId, formContext }) => {
          let obligeeAttorney = _.get(
            formContext,
            'formData.Application.clientsObligee[0].obligeeAttorney.attorney'
          )
          let obligorAttorney = _.get(
            formContext,
            'formData.Application.clientsObligor[0].obligorAttorney.attorney'
          )
          if (obligeeAttorney === 'A' || obligorAttorney === 'A') {
            return 'Attorney/Firm Name'
          } else if (obligeeAttorney === 'G' || obligorAttorney === 'G') {
            return 'Guardian Ad Litem Information'
          }
        },
        '{WVWORKS}': formContext => {
          if (
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            return (
              formContext.formContext.formData.Application.benefitCategorieslist.indexOf(
                'WVWORKS'
              ) > -1
            )
          }
        },
        '{forHEWLandMDPA and WVWORKS}': ({ formContext, fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          const optBenefitWVWORKS = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitWVWORKS`,
            false
          )
          const optBenefitFDNT = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitFDNT`,
            false
          )

          if (
            (formContext.formData.Application &&
              formContext.formData.Application.benefitCategorieslist &&
              (formContext.formData.Application.benefitCategorieslist.includes(
                'HEWL'
              ) ||
                formContext.formData.Application.benefitCategorieslist.includes(
                  'MDPA'
                ))) ||
            optBenefitWVWORKS ||
            optBenefitFDNT
          ) {
            return '*'
          }
          return ''
        },
        '{star forFDNT or WVWORKS Application}': formContext => {
          let fdnt = false
          let WVWORKS = formContext.formContext.formData.Application.benefitCategorieslist.includes(
            'WVWORKS'
          )
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            fdnt =
              formContext.formContext.formData.Application.clients[index]
                .optBenefitFDNT
          }
          return fdnt || WVWORKS ? '*' : ''
        },
        '{star forHEWLandMDPAandCHCR}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            (formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            ) ||
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'MDPA'
              ) ||
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'CHCR'
              ) ||
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'WVWORKS'
              ))
          ) {
            return '*'
          }
          return ''
        },
        '{WVWORKS and HEWLandMDPA Mandatory}': ({ formContext, fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          const optBenefitWVWORKS = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitWVWORKS`,
            false
          )
          const optBenefitFDNT = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitFDNT`,
            false
          )

          if (
            optBenefitWVWORKS ||
            optBenefitFDNT ||
            (formContext.formData.Application &&
              formContext.formData.Application.benefitCategorieslist &&
              (formContext.formData.Application.benefitCategorieslist.includes(
                'HEWL'
              ) ||
                formContext.formData.Application.benefitCategorieslist.includes(
                  'MDPA'
                )))
          )
            return '*'

          return ''
        },
        '{WVWORKS Mandatory}': ({ formContext, fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          const optBenefitWVWORKS = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitWVWORKS`,
            false
          )
          const optBenefitFDNT = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitFDNT`,
            false
          )

          if (optBenefitWVWORKS || optBenefitFDNT) return '*'

          return ''
        },
        '{WVWORKS MDPASpouse Mandatory}': ({ formContext, fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          const optBenefitWVWORKS = _.get(
            formContext,
            `formData.Application.benefitCategorieslist`,
            []
          ).includes('WVWORKS')
          const optBenefitFDNT = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitFDNT`,
            false
          )
          if (optBenefitWVWORKS || optBenefitFDNT) return '*'
          let spouseFlag =
            +index === 0 ||
            _.get(
              formContext,
              `formData.Application.clients[${index}].relationship.key`
            ) === 'REGESP28901'
          if (
            formContext?.formData?.Application?.benefitCategorieslist?.includes(
              'MDPA'
            ) &&
            spouseFlag
          ) {
            return '*'
          }
          return ''
        },
        '{show mailingadrschktitle}': formContext => {
          let value = 'Add Physical Address'
          if (this.props.step === STEPS.REVIEW_AND_SIGN) {
            return value
          } else return ''
        },
        '{has wvWorks}': ({ formContext }) => {
          return (
            _.get(
              formContext,
              'formData.Application.benefitCategorieslist',
              []
            ).indexOf('WVWORKS') > -1
          )
        },
        '{has only wvWorks}': ({ formContext }) => {
          return (
            !(
              _.get(
                formContext,
                'formData.Application.benefitCategorieslist',
                []
              ).indexOf('FDNT') > -1 ||
              _.get(
                formContext,
                'formData.Application.benefitCategorieslist',
                []
              ).indexOf('HEWL') > -1
            ) &&
            _.get(
              formContext,
              'formData.Application.benefitCategorieslist',
              []
            ).indexOf('WVWORKS') > -1
          )
        },
        '{PSNAPORWVWORKS Enums}': ({ formContext, fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          const optBenefitWVWORKS = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitWVWORKS`,
            false
          )
          const optBenefitFDNT = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitFDNT`,
            false
          )
          if (
            optBenefitFDNT &&
            ((optBenefitFDNT && index === '0') || optBenefitWVWORKS)
          ) {
            return ['1', '10']
          } else if (optBenefitFDNT) {
            return ['1', '10']
          } else if (optBenefitWVWORKS) {
            return ['10']
          }
        },
        '{PSNAPORWVWORKS Enumnames}': ({ formContext, fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          const optBenefitWVWORKS = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitWVWORKS`,
            false
          )
          const optBenefitFDNT = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitFDNT`,
            false
          )
          if (
            optBenefitFDNT &&
            ((optBenefitFDNT && index === '0') || optBenefitWVWORKS)
          ) {
            return [
              `${I18n.t('Appintake.childSupportPayments')}`,
              `${I18n.t('Appintake.dependentCare')}`
            ]
          } else if (optBenefitFDNT) {
            return [
              `${I18n.t('Appintake.childSupportPayments')}`,
              `${I18n.t('A.dependentCare')}`
            ]
          } else if (optBenefitWVWORKS) {
            return [`${I18n.t('Appintake.dependentCare')}`]
          }
        },
        '{onlyWvWorksApplicant}': ({ formContext, fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          const optBenefitWVWORKS = _.get(
            formContext,
            `formData.Application.clients[${index}].optBenefitWVWORKS`,
            false
          )
          if (optBenefitWVWORKS) return true

          return false
        },
        '{child}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          let cur = _moment(config, this.state.currentDate)
          let birthDate = _moment(
            config,
            formContext.formContext.formData.Application.clients[index]
              .clientBthDt
          )
          let testFlag = cur.diff(birthDate, 'years') < 18
          return testFlag
        },
        '{has lieapFailed}': formContext => {
          return formContext.formContext.lieapFailedText
        },
        '{has scaFailed}': formContext => {
          return formContext.formContext.scaFailedText
        },
        '{female over 10}': formContext => {
          let femaleOverTenFlag = false
          for (
            let i = 0;
            i < formContext.formContext.formData.Application.clients.length;
            i++
          ) {
            let thisApplicant =
              formContext.formContext.formData.Application.clients[i]
            if (
              thisApplicant.genderCode === '2' ||
              thisApplicant.genderCode === '3'
            ) {
              let birthdate = _moment(config, thisApplicant.clientBthDt)
              let cur = _moment(config, this.state.currentDate)
              if (cur.diff(birthdate, 'years') >= 10) {
                return (femaleOverTenFlag = true)
              } else {
                return (femaleOverTenFlag = false)
              }
            }
          }
          return femaleOverTenFlag
        },
        '{maid in pe}': formContext => {
          let temporaryMaidNumberStr = ''
          if (
            _.includes(
              this.props.selectedEntitlements,
              ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
            )
          ) {
            let index =
              formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
              formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
            if (index && index === '0') {
              const thisApplicant =
                formContext.formContext.formData.Application.clients[index]
              if (thisApplicant) {
                if (
                  thisApplicant.presumptiveEligibilityCoverage === 'Y' &&
                  thisApplicant.optBenefitHEWL
                ) {
                  return '*'
                } else return temporaryMaidNumberStr
              } else {
                return temporaryMaidNumberStr
              }
            } else {
              return temporaryMaidNumberStr
            }
          } else {
            return temporaryMaidNumberStr
          }
        },
        '{primary heating source}': formContext => {
          for (
            let i = 0;
            i < formContext.formContext.formData.Application.clients.length;
            i++
          ) {
            if (
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'HMEN'
              ) &&
              formContext.formContext.formData.Application.clients[i]
                .shelterOrUtilityCostQuestions.primaryHeatingSource !=
                undefined &&
              formContext.formContext.formData.Application.clients[i]
                .shelterOrUtilityCostQuestions.primaryHeatingSource != '2'
            ) {
              return '*'
            }
          }
          return ' '
        },
        '{star ethnicity}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'CHCR'
            )
          ) {
            return '*'
          }
          return ' '
        },
        '{usCtznOrNatInd required}': formContext => {
          if (
            (formContext.formContext.formData.Application &&
              formContext.formContext.formData.Application
                .benefitCategorieslist &&
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'CHCR'
              )) ||
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'MDPA'
            ) ||
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            ) ||
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'WVWORKS'
            )
          ) {
            return '*'
          }
          return ' '
        },
        '{electric account LIEAP}': formContext => {
          for (
            let i = 0;
            i < formContext.formContext.formData.Application.clients.length;
            i++
          ) {
            if (
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'HMEN'
              )
            ) {
              return '*'
            }
          }
          return ' '
        },
        '{other applicants below 18}': formContext => {
          let applicantBelowEighteenFlag = false
          for (
            let i = 0;
            i < formContext.formContext.formData.Application.clients.length;
            i++
          ) {
            if (i !== 0) {
              let thisApplicant =
                formContext.formContext.formData.Application.clients[i]
              let birthdate = _moment(config, thisApplicant.clientBthDt)
              let cur = _moment(config, this.state.currentDate)
              if (cur.diff(birthdate, 'years') < 18) {
                applicantBelowEighteenFlag = true
                return applicantBelowEighteenFlag
              } else {
                applicantBelowEighteenFlag = false
              }
            }
          }
          return applicantBelowEighteenFlag
        },
        '{heating questions}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index && index === '0') {
            const thisApplicant =
              formContext.formContext.formData.Application.clients[index]
            if (thisApplicant) {
              return (
                thisApplicant.optBenefitHMEN || thisApplicant.optBenefitFDNT
              )
            } else {
              return false
            }
          } else {
            return false
          }
        },
        '{applying healthcare}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const thisApplicant =
              formContext.formContext.formData.Application.clients[index]
            if (thisApplicant) {
              return thisApplicant.optBenefitHEWL
            } else {
              return false
            }
          } else {
            return false
          }
        },
        '{show schedule}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const thisApplicant =
              formContext.formContext.formData.Application.clients[index]
            if (thisApplicant) {
              return thisApplicant.ccDueToWork === 'Y'
            } else {
              return false
            }
          } else {
            return false
          }
        },
        '{unpaid medical bills1}': formContext => {
          let unpaidMedBill = false
          for (
            let i = 0;
            i < formContext.formContext.formData.Application.clients.length;
            i++
          ) {
            let unpaidBills =
              formContext.formContext.formData.Application.clients[i]
                .unpaidMedicalBills
            if (unpaidBills === 'Y') {
              return (unpaidMedBill = true)
            }
          }
          return unpaidMedBill
        },
        '{FDNT}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const client =
              formContext.formContext.formData.Application.clients[index]
            return client.optBenefitFDNT
          }
        },
        '{HMEN}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const client =
              formContext.formContext.formData.Application.clients[index]
            return client.optBenefitHMEN
          }
        },
        '{HEWL}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const client =
              formContext.formContext.formData.Application.clients[index]
            return client.optBenefitHEWL
          }
        },
        '{CHCR}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const client =
              formContext.formContext.formData.Application.clients[index]
            return client.optBenefitCHCR
          }
        },
        '{CHSP}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const client =
              formContext.formContext.formData.Application.clients[index]
            return client.optBenefitCHSP
          }
        },
        '{SCAL}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (
            index &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'SCAL'
            )
          ) {
            const client =
              formContext.formContext.formData.Application.clients[index]
            return client.optBenefitSCAL
          }
        },
        '{MDPA}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const client =
              formContext.formContext.formData.Application.clients[index]
            return client.optBenefitMDPA
          }
        },
        '{FDNTBFC}': formContext => {
          if (
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            return (
              formContext.formContext.formData.Application.benefitCategorieslist.indexOf(
                'FDNT'
              ) > -1
            )
          }
        },

        '{HMENBFC}': formContext => {
          if (
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            return (
              formContext.formContext.formData.Application.benefitCategorieslist.indexOf(
                'HMEN'
              ) > -1
            )
          }
        },
        '{HEWLBFC}': formContext => {
          if (
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            return (
              formContext.formContext.formData.Application.benefitCategorieslist.indexOf(
                'HEWL'
              ) > -1
            )
          }
        },
        '{CHCRBFC}': formContext => {
          if (
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            return (
              formContext.formContext.formData.Application.benefitCategorieslist.indexOf(
                'CHCR'
              ) > -1
            )
          }
        },
        '{CHSPBFC}': formContext => {
          if (
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            return (
              formContext.formContext.formData.Application.benefitCategorieslist.indexOf(
                'CHSP'
              ) > -1
            )
          }
        },
        '{SCALBFC}': formContext => {
          if (
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            return (
              formContext.formContext.formData.Application.benefitCategorieslist.indexOf(
                'SCAL'
              ) > -1
            )
          }
        },
        '{MDPABFC}': formContext => {
          if (
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            return (
              formContext.formContext.formData.Application.benefitCategorieslist.indexOf(
                'MDPA'
              ) > -1
            )
          }
        },
        '{insCoNM has value}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            if (
              formContext.formContext.formData.Application.clients[index]
                .healthInsuranceDetails[2].hlthInsSrc[0].insCoNm ===
                undefined ||
              formContext.formContext.formData.Application.clients[index]
                .healthInsuranceDetails[2].hlthInsSrc[0].insCoNm === '' ||
              formContext.formContext.formData.Application.clients[index]
                .healthInsuranceDetails[2].hlthInsSrc[0].insCoNm === ' '
            ) {
              return false
            } else {
              return true
            }
          } else {
            return false
          }
        },
        '{below18AgeCheck}': formContext => {
          for (
            let i = 0;
            i < formContext.formContext.formData.Application.clients.length;
            i++
          ) {
            let clientBirthDate = _moment(
              config,
              formContext.formContext.formData.Application.clients[i]
                .clientBthDt
            )
            if (clientBirthDate !== undefined) {
              if (
                _moment(config, this.state.currentDate).diff(
                  clientBirthDate,
                  'days'
                ) <= 6574
              ) {
                return true
              }
            }
          }
          return false
        },
        '{eachClientAgeCheck}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            let thisApplicant =
              formContext.formContext.formData.Application.clients[index]
            if (thisApplicant.clientBthDt !== undefined) {
              let birthdate = _moment(config, this.state.currentDate).diff(
                thisApplicant.clientBthDt,
                'days',
                false
              )
              if (birthdate <= 6574) {
                return true
              }
            } else {
              return false
            }
          }
        },
        '{birthDateQuestion}': formContext => {
          let name
          if (
            _.includes(
              formContext.formContext.formData.Application
                .benefitCategorieslist,
              'CHSP'
            )
          ) {
            name = 'Birth Date*'
          } else {
            name = 'Birth Date'
          }
          return name
        },
        '{ssnCitizenshipTab}': formContext => {
          let ssnQuestion, selectedCategories
          let categoryCheck = ['FDNT', 'HMEN', 'CHSP']
          selectedCategories =
            formContext.formContext.formData.Application.benefitCategorieslist
          var found = selectedCategories.some(
            i => categoryCheck.indexOf(i) >= 0
          )
          if (found) {
            ssnQuestion = `${I18n.t('Appintake.socialSecurityNumber')}`
          } else {
            ssnQuestion = `${I18n.t('Appintake.socialSecurityNumber')}`
          }
          return ssnQuestion
        },
        '{farmWorker farmer job end}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            let applicant =
              formContext.formContext.formData.Application.clients[index]
            if (applicant.clientFarmWorker === 'Y') {
              return false
            }
            return true
          }
        },
        '{farmWorker 25 dollar quesCheck}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            let applicant =
              formContext.formContext.formData.Application.clients[index]
            if (
              applicant.clientFarmWorker === 'Y' &&
              applicant.farmerJobEnd === 'Y'
            ) {
              return false
            }
            return true
          }
        },
        '{client number}': ({ fieldId }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          return (parseInt(index) + 1).toString()
        },
        '{healthEnum}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (
            index &&
            formContext.formContext.formData.Application.clients[index]
              .clientEnrlHlthInsPln === 'Y' &&
            formContext.formContext.formData.Application.clients[index]
              .optBenefitMDPA
          ) {
            return ['6', '1', '3', '8', '4', '11']
          } else if (
            formContext.formContext.formData.Application.clients[index]
              .clientEnrlHlthInsPln === 'Y' &&
            !formContext.formContext.formData.Application.clients[index]
              .optBenefitMDPA
          ) {
            return ['6', '1', '3', '8', '4', '11']
          }
        },
        '{healthEnumName}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (
            index &&
            formContext.formContext.formData.Application.clients[index]
              .clientEnrlHlthInsPln === 'Y' &&
            formContext.formContext.formData.Application.clients[index]
              .optBenefitMDPA
          ) {
            return [
              I18n.t('General.title7'),
              I18n.t('General.title8'),
              I18n.t('General.title9'),
              I18n.t('General.title10'),
              I18n.t('General.title11'),
              I18n.t('General.title12')
            ]
          } else if (
            formContext.formContext.formData.Application.clients[index]
              .clientEnrlHlthInsPln === 'Y' &&
            !formContext.formContext.formData.Application.clients[index]
              .optBenefitMDPA
          ) {
            return [
              I18n.t('General.title7'),
              I18n.t('General.title8'),
              I18n.t('General.title9'),
              I18n.t('General.title10'),
              I18n.t('General.title11'),
              I18n.t('General.title12')
            ]
          }
        },
        '{clientsObligee}': formContext => {
          let thisApplicant =
            formContext.formContext.formData.Application.clients[0]
          let prmyaplctname = []
          if (
            formContext.formContext.formData.Application.clientApplicant[0]
              .roleOfApplicant === 'Y' &&
            thisApplicant &&
            thisApplicant.clientFstNm
          ) {
            const prmyaplctnameStr = thisApplicant.clientFstNm
              .concat(' ')
              .concat(thisApplicant.clientLstNm)
            //CPC
            const prmyaplctnameStr1 = I18n.t('Appintake.CPC')
              .concat(' ')
              .concat(prmyaplctnameStr)
            prmyaplctname.push(prmyaplctnameStr1)
            return prmyaplctname
          } else if (
            formContext.formContext.formData.Application.clientApplicant[0]
              .roleOfApplicant === 'N' &&
            thisApplicant &&
            thisApplicant.clientFstNm
          ) {
            return I18n.t('Appintake.CPC')
          } else {
            return I18n.t('Appintake.obligee')
          }
        },
        '{schoolEnrollmentDetails}': formContext => {
          if (
            formContext.formContext.formData.Application.benefitCategorieslist
              .length > 0
          ) {
            let index1 = formContext.fieldId.match(
              '(?:.*)clients_([0-9]+)_?'
            )[1]
            if (
              formContext.formContext.formData.Application.clients[index1]
                .optBenefitCHCR &&
              formContext.formContext.formData.Application.benefitCategorieslist
                .length === 1
            ) {
              return false
            } else return true
          }
        },
        '{clientsObligor}': formContext => {
          let thisApplicant =
            formContext.formContext.formData.Application.clients[0]
          let prmyaplctname = []
          if (
            formContext.formContext.formData.Application.clientApplicant[0]
              .roleOfApplicant === 'N' &&
            thisApplicant &&
            thisApplicant.clientFstNm
          ) {
            //nonCustodialParent
            const prmyaplctnameStr = thisApplicant.clientFstNm
              .concat(' ')
              .concat(thisApplicant.clientLstNm)
            const prmyaplctnameStr1 = I18n.t('Appintake.nonCustodialParent')
              .concat(' ')
              .concat(prmyaplctnameStr)
            prmyaplctname.push(prmyaplctnameStr1)
            return prmyaplctname
          } else if (
            formContext.formContext.formData.Application.clientApplicant[0]
              .roleOfApplicant === 'Y' &&
            thisApplicant &&
            thisApplicant.clientFstNm
          ) {
            return I18n.t('Appintake.nonCustodialParent')
          } else {
            return I18n.t('Appintake.obligor')
          }
        },
        '{is authorized}': formContext => {
          if (formContext.formContext.isIncomplete) {
            return false
          } else {
            return true
          }
        },
        '{applicant live}': formContext => {
          if (formContext.formContext.isIncomplete) {
            return ''
          } else {
            return '*'
          }
        },
        '{name On Bill}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            let value =
              formContext.formContext.formData.Application.clients[index]
                .otherHouseholdUtilityExpenses
            if (
              value.includes('16') ||
              value.includes('17') ||
              value.includes('19')
            ) {
              return false
            }
            return false
          }
        },
        '{show timeFromTimeToAsRequired}': formContext => {
          if (
            formContext.formContext.formData.Application.clients[0]
              .optBenefitCHCR
          ) {
            const schoolSchedule = _.get(
              formContext.formContext.formData,
              'Application.clients[' + 0 + '].schoolSchedule',
              []
            )
            for (
              let schedule = 0;
              schedule < schoolSchedule.length;
              schedule++
            ) {
              const scheduleObject = schoolSchedule[schedule]
              if (
                scheduleObject['monday'] ||
                scheduleObject['tuesday'] ||
                scheduleObject['wednesday'] ||
                scheduleObject['thursday'] ||
                scheduleObject['friday'] ||
                scheduleObject['saturday'] ||
                scheduleObject['sunday']
              ) {
                return '*'
              } else {
                return ''
              }
            }
          }
        },
        '{relationshiplabel}': formContext => {
          if (this.props.step && this.props.step <= 6) {
            for (
              let l = 0;
              l < formContext.formContext.formData.Application.clients.length;
              l++
            ) {
              if (
                formContext.formContext.formData.Application &&
                formContext.formContext.formData.Application &&
                (formContext.formContext.formData.Application.clients[l]
                  .optBenefitHEWL ||
                  formContext.formContext.formData.Application.clients[l]
                    .optBenefitMDPA)
              ) {
                return `${I18n.t('Appintake.relationship')}*`
              }
              return `${I18n.t('Appintake.relationship')}`
            }
          } else return ' '
        },
        '{clmDepnFlgDisable}': ({ fieldId, formContext }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          let clients = _.get(formContext.formData, 'Application.clients', [])
          let clemFlag = false
          clients.forEach(client => {
            if (client.dependents && client.dependents.length > 0) {
              client.dependents.forEach(dependent => {
                if (dependent === index) {
                  clemFlag = true
                }
              })
            }
          })
          return clemFlag
        },
        '{is Parent}': ({ fieldId, formContext }) => {
          try {
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
              fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
            let clients = _.get(formContext.formData, 'Application.clients', [])
            let index =
              fieldId.match('(?:.*)clients_([0-9]+)_?') &&
              fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
            return _.get(clients, `[${index}].role`) === 'parentorcaregiver'
          } catch (e) {
            console.log(e)
          }
        },
        '{citizenshipNote ageCheck}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

          if (
            formContext.formData.Application.benefitCategorieslist.includes(
              'CHCR'
            )
          ) {
            let thisApplicant = formContext.formData.Application.clients[index]
            let clientBirthDate = _moment(
              config,
              formContext.formData.Application.clients[index].clientBthDt
            )
            if (clientBirthDate !== undefined) {
              if (
                _moment(config, this.state.currentDate).diff(
                  clientBirthDate,
                  'years'
                ) < 18
              ) {
                return I18n.t('General.title16')
              } else {
                return ' '
              }
            }
          }

          return I18n.t('General.title17')
        },
        '{ssn failure description}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

          let thisApplicant = formContext.formData.Application.clients[index]
          if (
            thisApplicant.optBenefitHEWL ||
            (thisApplicant.optBenefitHEWL &&
              (thisApplicant.optBenefitFDNT ||
                thisApplicant.optBenefitMDPA ||
                thisApplicant.optBenefitSCAL ||
                thisApplicant.optBenefitHMEN))
          ) {
            return I18n.t('General.title13')
          } else if (
            thisApplicant.optBenefitFDNT ||
            thisApplicant.optBenefitMDPA ||
            thisApplicant.optBenefitSCAL ||
            thisApplicant.optBenefitHMEN ||
            thisApplicant.optBenefitWVWORKS
          ) {
            return I18n.t('General.title14')
          } else {
            return I18n.t('General.title15')
          }
        },
        '{Over 14}': ({ fieldId, formContext }) => {
          let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          let thisApplicant = formContext.formData.Application.clients[index]
          if (!thisApplicant.clientBthDt || thisApplicant.clientBthDt === '') {
            return true
          } else if (thisApplicant.clientBthDt) {
            let birthdate = +new Date(thisApplicant.clientBthDt)
            let cur = _moment(config, this.state.currentDate)
            let diff = cur - birthdate
            return Math.floor(diff / 31557600000) >= 14
          } else {
            return false
          }
        },
        '{householdDescription}': ({ formContext, fieldId }) => {
          const benefitCatList = _.get(
            formContext,
            'formData.Application.benefitCategorieslist',
            []
          )
          if (_.includes(benefitCatList, 'HEWL')) {
            return 'List the following information about yourself and ALL persons in your household. This includes family members and all others living under the same roof. \n\nIf you are applying for Medicaid and/or WVCHIP, include all individuals listed on your federal income tax return.'
          } else {
            return 'List the following information about yourself and ALL persons in your household. This includes family members and all others living under the same roof.'
          }
        },
        '{star maritalStatus}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            (formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'MDPA'
            ) ||
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'WVWORKS'
              ))
          ) {
            return '*'
          }
          return ''
        },
        '{star childCare}': formContext => {
          const benefitCatList = _.get(
            formContext,
            'formContext.formData.Application.benefitCategorieslist',
            []
          )
          if (_.includes(benefitCatList, 'CHCR')) {
            return '*'
          }
          return ''
        },
        '{star gender}': formContext => {
          let index = formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          for (
            let l = 0;
            l < formContext.formContext.formData.Application.clients.length;
            l++
          ) {
            if (
              formContext.formContext.formData.Application &&
              (formContext.formContext.formData.Application.clients[l]
                .optBenefitHEWL ||
                formContext.formContext.formData.Application.clients[index]
                  .optBenefitMDPA ||
                formContext.formContext.formData.Application.clients[l]
                  .optBenefitCHCR)
            ) {
              return '*'
            }
            return ''
          }
        },
        '{star birthdate}': formContext => {
          let index = formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

          for (
            let l = 0;
            l < formContext.formContext.formData.Application.clients.length;
            l++
          ) {
            if (
              formContext.formContext.formData.Application &&
              (formContext.formContext.formData.Application.clients[l]
                .optBenefitHEWL ||
                formContext.formContext.formData.Application.clients[index]
                  .optBenefitMDPA ||
                formContext.formContext.formData.Application.clients[l]
                  .optBenefitCHCR)
            ) {
              return '*'
            }
            return ''
          }
        },
        '{star mainPerson}': formContext => {
          if (
            (formContext.formContext.formData.Application &&
              formContext.formContext.formData.Application
                .benefitCategorieslist &&
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'HEWL'
              )) ||
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'WVWORKS'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star disabilityReqCheck}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star whoLivesWithYou}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            (_.includes(
              formContext.formContext.formData.Application
                .benefitCategorieslist,
              'MDPA'
            ) ||
              _.includes(
                formContext.formContext.formData.Application
                  .benefitCategorieslist,
                'HEWL'
              ))
          ) {
            return '*'
          }
          return ''
        },
        '{star unpaidMedicalBills}': formContext => {
          for (
            let i = 0;
            i < formContext.formContext.formData.Application.clients.length;
            i++
          ) {
            if (
              formContext.formContext.formData.Application.clients[i]
                .optBenefitHEWL
            ) {
              return '*'
            }
          }
          return ''
        },
        '{star unpaidMonthsIfTrue}': formContext => {
          for (
            let i = 0;
            i < formContext.formContext.formData.Application.clients.length;
            i++
          ) {
            if (
              formContext.formContext.formData.Application.clients[i]
                .optBenefitHEWL
            ) {
              return '*'
            }
          }
          return ''
        },
        '{star previous 1-3 months}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            _.includes(
              formContext.formContext.formData.Application
                .benefitCategorieslist,
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star eligibleforHealthInsuranceCoverage}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            (formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            ) ||
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'MDPA'
              ))
          ) {
            return '*'
          }
          return ''
        },
        '{star federalIncomeTaxReturnNextYear}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star fileTaxesJointly}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star claimAnyDependents}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star claimingAsAdependent}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star claimedAsAdependentOnSomeoneElse}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star whoWillClaimAsDependent}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star forHEWLandMDPA}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            (formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            ) ||
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'MDPA'
              ) ||
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'WVWORKS'
              ))
          ) {
            return '*'
          }
          return ''
        },
        // Code changes for US3059258
        '{star forFDNT}': formContext => {
          let fdnt = false
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            fdnt =
              formContext.formContext.formData.Application.clients[index]
                .optBenefitFDNT
          }
          return fdnt ? '*' : ''
        },
        '{star onlyForHEWL}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'HEWL'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star onlyForMDPA}': formContext => {
          if (
            formContext.formContext.formData.Application &&
            formContext.formContext.formData.Application
              .benefitCategorieslist &&
            formContext.formContext.formData.Application.benefitCategorieslist.includes(
              'MDPA'
            )
          ) {
            return '*'
          }
          return ''
        },
        '{star onlyForMDPA Spouse}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          let spouseFlag =
            +index === 0 ||
            _.get(
              formContext,
              `formContext.formData.Application.clients[${index}].relationship`
            ) === '61'
          if (
            (formContext.formContext.formData.Application &&
              formContext.formContext.formData.Application
                .benefitCategorieslist &&
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'MDPA'
              ) &&
              spouseFlag) ||
            (formContext.formContext.formData.Application &&
              formContext.formContext.formData.Application
                .benefitCategorieslist &&
              formContext.formContext.formData.Application.benefitCategorieslist.includes(
                'WVWORKS'
              ))
          ) {
            return '*'
          }
          return ''
        },
        '{star onlyForCHCR}': formContext => {
          let chcr = false
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            chcr =
              formContext.formContext.formData.Application.clients[index]
                .optBenefitCHCR
          }
          return chcr ? '*' : ''
        },
        '{clmDisabled}': formContext => {
          let index =
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          let clients = _.get(
            formContext.formContext.formData,
            'Application.clients',
            []
          )
          let isDisabled = false
          clients.forEach(client => {
            if (client.dependents && client.dependents.length > 0) {
              client.dependents.forEach(dependent => {
                if (dependent === index) {
                  isDisabled = true
                }
              })
            }
          })
          return isDisabled
        },
        '{HEWLDependentCheck}': formContext => {
          let enable = false
          let clients = _.get(
            formContext.formContext.formData,
            'Application.clients',
            []
          )
          clients.forEach(client => {
            if (client.optBenefitHEWL && client.optBenefitHEWL === true)
              enable = true
          })
          return enable
        },
        '{CHCRAgeRangeCheck}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (formContext.formData.Application.clients[index].optBenefitCHCR) {
            let clientBirthDate = _moment(
              config,
              formContext.formData.Application.clients[index].clientBthDt
            )
            let date = _moment(config, this.state.currentDate)
            let age = date.diff(clientBirthDate, 'years')
            if (clientBirthDate !== undefined && !_.inRange(age, 5, 18)) {
              return true
            }
          }
          return false
        },
        '{mandate for CHCR}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (
            formContext.formData.Application.clients[index].optBenefitCHCR ||
            (formContext.formData.Application.benefitCategorieslist.includes(
              'SCAL'
            ) &&
              formContext.formData.Application.clients[index].optBenefitSCAL)
          ) {
            return '*'
          }
          return ''
        },
        '{raceCd American Indian}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          return _.includes(
            _.get(
              formContext.formData,
              `Application.clients[${index}].raceCd`,
              []
            ),
            '1'
          )
        },
        //Code Changes for US3173420
        '{mandate for SCAL}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (
            formContext.formData.Application.benefitCategorieslist.includes(
              'SCAL'
            ) &&
            formContext.formData.Application.clients[index].optBenefitSCAL
          ) {
            return '*'
          }
          return ''
        },
        '{mandate for CHCR with Range}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          let clientBirthDate = _moment(
            config,
            formContext.formData.Application.clients[index].clientBthDt
          )
          let cur = _moment(config, this.state.currentDate)
          let age = cur.diff(clientBirthDate, 'years')
          //Code Changes for US3173420
          if (
            formContext?.formData?.Application?.benefitCategorieslist?.includes(
              'WVWORKS'
            ) &&
            formContext?.formData?.Application?.clients[index]
              ?.optBenefitWVWORKS
          ) {
            return '*'
          }
          if (
            (formContext.formData.Application.clients[index].optBenefitCHCR &&
              !_.inRange(age, 5, 18)) ||
            (formContext.formData.Application.benefitCategorieslist.includes(
              'SCAL'
            ) &&
              formContext.formData.Application.clients[index].optBenefitSCAL)
          ) {
            return '*'
          }
          return ''
        },
        '{MDPAQuestionsForSpouse}': ({ formContext, fieldId }) => {
          //let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          //let spouseFlag = false
          /*if (clientIndex === 0) {
            const clients = _.get(
              formContext,
              'formData.Application.clients',
              []
            )
            clients.forEach(client => {
              if (client.relationship === '61' && client.optBenefitMDPA) {
                spouseFlag = true
              }
            })
          }*/
          // Code Fix for US3447722
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          let spouseFlag =
            (+index === 0 &&
              formContext.formData.Application.benefitCategorieslist.includes(
                'MDPA'
              )) ||
            (_.get(
              formContext,
              `formData.Application.clients[${index}].relationship`
            ) === '61' &&
              formContext.formData.Application.clients[0].optBenefitMDPA)
          if (
            (formContext.formData.Application.clients[index].relationship ===
              '61' &&
              formContext.formData.Application.clients[index].optBenefitMDPA) ||
            spouseFlag
          ) {
            return true
          }
          return false
        },
        '{disable workDay}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const thisApplicant =
              formContext.formData.Application.clients[index]
            if (thisApplicant) {
              const wagesIndex =
                fieldId.match('(?:.*)wagesDetails_([0-9]+)_?') &&
                fieldId.match('(?:.*)wagesDetails_([0-9]+)_?')[1]
              const wagesDetails =
                thisApplicant.incometypeDetails.earnedIncome.wagesDetails[
                  wagesIndex
                ]
              const setSchedule = wagesDetails.setSchedule
              return setSchedule === undefined || setSchedule === 'N'
                ? true
                : false
            }
          } else {
            return false
          }
        },
        '{star for WorkdayTimings salaryIncomeDetail}': ({
          formContext,
          fieldId
        }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const thisApplicant =
              formContext.formData.Application.clients[index]
            if (thisApplicant) {
              const wagesIndex =
                fieldId.match('(?:.*)wagesDetails_([0-9]+)_?') &&
                fieldId.match('(?:.*)wagesDetails_([0-9]+)_?')[1]
              const wagesDetails =
                thisApplicant.incometypeDetails.earnedIncome.wagesDetails[
                  wagesIndex
                ]
              const setSchedule = wagesDetails.setSchedule
              return setSchedule === 'Y' ? '*' : ''
            }
          } else {
            return ''
          }
        },
        '{star for WorkdayTimings selfEmploymentIncomeDetail}': ({
          formContext,
          fieldId
        }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const thisApplicant =
              formContext.formData.Application.clients[index]
            if (thisApplicant) {
              const selfEmploymentIndex =
                fieldId.match('(?:.*)selfEmploymentIncomeDetails_([0-9]+)_?') &&
                fieldId.match('(?:.*)selfEmploymentIncomeDetails_([0-9]+)_?')[1]
              const incomeIndex =
                fieldId.match('(?:.*)income_([0-9]+)_?') &&
                fieldId.match('(?:.*)income_([0-9]+)_?')[1]
              const selfEmploymentDetails =
                thisApplicant.incometypeDetails.selfemploymentIncome
                  .selfEmploymentIncomeDetails[selfEmploymentIndex].income[
                  incomeIndex
                ]
              const setSchedule = selfEmploymentDetails.setSchedule
              return setSchedule === 'Y' ? '*' : ''
            }
          } else {
            return ''
          }
        },
        '{disable fromandto}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const thisApplicant =
              formContext.formData.Application.clients[index]
            if (thisApplicant) {
              const wagesIndex =
                fieldId.match('(?:.*)wagesDetails_([0-9]+)_?') &&
                fieldId.match('(?:.*)wagesDetails_([0-9]+)_?')[1]
              const workDayIndex =
                fieldId.match('(?:.*)workdayTimings_([0-9]+)_?') &&
                fieldId.match('(?:.*)workdayTimings_([0-9]+)_?')[1]
              const wagesDetails =
                thisApplicant.incometypeDetails.earnedIncome.wagesDetails[
                  wagesIndex
                ]
              const isDaySelected =
                wagesDetails.workdayTimings[workDayIndex].monday ||
                wagesDetails.workdayTimings[workDayIndex].tuesday ||
                wagesDetails.workdayTimings[workDayIndex].wednesday ||
                wagesDetails.workdayTimings[workDayIndex].thursday ||
                wagesDetails.workdayTimings[workDayIndex].friday ||
                wagesDetails.workdayTimings[workDayIndex].saturday ||
                wagesDetails.workdayTimings[workDayIndex].sunday
              const setSchedule = wagesDetails.setSchedule
              if (setSchedule && setSchedule === 'Y') {
                if (!isDaySelected) {
                  return true
                } else {
                  return false
                }
              } else {
                return true
              }
            }
          } else {
            return false
          }
        },
        '{disable workDaySE}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const thisApplicant =
              formContext.formData.Application.clients[index]
            if (thisApplicant) {
              const selfEmploymentIndex =
                fieldId.match('(?:.*)selfEmploymentIncomeDetails_([0-9]+)_?') &&
                fieldId.match('(?:.*)selfEmploymentIncomeDetails_([0-9]+)_?')[1]
              const incomeIndex =
                fieldId.match('(?:.*)income_([0-9]+)_?') &&
                fieldId.match('(?:.*)income_([0-9]+)_?')[1]
              const selfEmploymentDetails =
                thisApplicant.incometypeDetails.selfemploymentIncome
                  .selfEmploymentIncomeDetails[selfEmploymentIndex].income[
                  incomeIndex
                ]
              const setSchedule = selfEmploymentDetails.setSchedule
              return setSchedule === undefined || setSchedule === 'N'
                ? true
                : false
            }
          } else {
            return false
          }
        },
        '{disable fromandtoSE}': ({ formContext, fieldId }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          if (index) {
            const thisApplicant =
              formContext.formData.Application.clients[index]
            if (thisApplicant) {
              const selfEmploymentIndex =
                fieldId.match('(?:.*)selfEmploymentIncomeDetails_([0-9]+)_?') &&
                fieldId.match('(?:.*)selfEmploymentIncomeDetails_([0-9]+)_?')[1]
              const incomeIndex =
                fieldId.match('(?:.*)income_([0-9]+)_?') &&
                fieldId.match('(?:.*)income_([0-9]+)_?')[1]
              const selfEmploymentDetails =
                thisApplicant.incometypeDetails.selfemploymentIncome
                  .selfEmploymentIncomeDetails[selfEmploymentIndex].income[
                  incomeIndex
                ]
              const workDayIndex =
                fieldId.match('(?:.*)workdayTimings_([0-9]+)_?') &&
                fieldId.match('(?:.*)workdayTimings_([0-9]+)_?')[1]
              const isDaySelected =
                selfEmploymentDetails.workdayTimings[workDayIndex].monday ||
                selfEmploymentDetails.workdayTimings[workDayIndex].tuesday ||
                selfEmploymentDetails.workdayTimings[workDayIndex].wednesday ||
                selfEmploymentDetails.workdayTimings[workDayIndex].thursday ||
                selfEmploymentDetails.workdayTimings[workDayIndex].friday ||
                selfEmploymentDetails.workdayTimings[workDayIndex].saturday ||
                selfEmploymentDetails.workdayTimings[workDayIndex].sunday
              const setSchedule = selfEmploymentDetails.setSchedule
              if (setSchedule && setSchedule === 'Y') {
                if (!isDaySelected) {
                  return true
                } else {
                  return false
                }
              } else {
                return true
              }
            }
          } else {
            return false
          }
        },
        '{is primary applicant and not child care}': ({
          formContext,
          fieldId
        }) => {
          let index =
            fieldId.match('(?:.*)clients_([0-9]+)_?') &&
            fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
          const benefitCatList = _.get(
            formContext,
            'formData.Application.benefitCategorieslist',
            []
          )
          if (
            _.get(formContext, 'formData.Application.clients[0].address.st') ==
            'WV'
          ) {
            return false
          }
          if (
            _.size(benefitCatList) === 1 &&
            _.includes(benefitCatList, 'WVWORKS')
          ) {
            return false
          }

          if (
            index === '0' &&
            _.size(benefitCatList) === 1 &&
            _.includes(benefitCatList, 'CHCR')
          ) {
            return false
          } else if (index > 0) {
            return false
          } else {
            return true
          }
        }
      },
      forms: forms,
      showELIEAP: true,
      reviewForms: forms,
      hideWVWORKS: config.hideWVWORKS,
      openAddContributor: this._openAddContributor,
      setShowNext: this._setShowNext,
      imageMap: imageMap,
      checkChildCareTabEnable: this._checkChildCareTabEnable,
      checkChildSupportTabEnable: this._checkChildSupportTabEnable,
      checkChildSupportEnable: this._checkChildSupportEnable,
      isClientEligibleForCHCR: this._isClientEligibleForCHCR,
      isIncomplete: this.state.incomplete,
      isAppIntake: true,
      isUnitTest: this.props.isUnitTest,
      selectedEntitlements: this.props.selectedEntitlements,
      currentDate: date,
      config,
      onUpdateCarouselSlide: this._onUpdateCarouselSlides,
      deleteOnHide: true, //This will cause form data to be removed when it becomes invisible via ui:visibleIf.
      deleteArrayOnHide: true, //This will cause form data to be removed when it becomes invisible via ui:eachVisibleIf.
      CountiesBaseUrl: CountiesBaseUrl,
      uuid: this.props.auth.userAccount ? this.props.auth.userAccount.uuid : '',
      onFormDataChange: this._onFormDataChange,
      fetchJson: serviceHelper.fetchJson,
      showErrorMessage: this.props.showErrorMessage,
      setReneCovInd: this._setReneCovInd.bind(this),
      setVictimOfAFamOrDomestViol: this._setVictimOfAFamOrDomestViol.bind(this),
      locale: this.props.locale
    }
    //Create a default empty application and merge it with our application data
    let newFormData
    if (!_.isEmpty(formData)) {
      newFormData = _.merge(
        helper.createObjectFromMasterSchema(null, formContext),
        formData
      )
    } else {
      newFormData = helper.createObjectFromMasterSchema(null, formContext)
    }
    //Setting assisterRoleId for CP Role
    if (
      (this.props.roleName === 'Community Partner' ||
        this.props.roleName === 'CP Admin') &&
      (applId === 0 || applId === '0')
    ) {
      _.set(newFormData, 'Application.assister.assisterRoleCd', '10')
    }

    //Set the application ID
    if (applId !== '0') {
      _.set(newFormData, 'Application.applId', applId)
    }

    //Set the tenant ID
    if (config.tenant.code) {
      _.set(newFormData, 'Application.tenantId', config.tenant.code)
    }

    //Clear the review signature info.
    _.unset(newFormData, 'Application.agreement')
    _.unset(newFormData, 'Application.signatureReceived')

    //Default primary to relationship to "Self"
    //TODO: update once we are aligned to MCR
    const relationshipSchema = _.get(
      application.forms[0].schema,
      'definitions.client.properties.relationship'
    )
    if (relationshipSchema) {
      _.set(
        newFormData,
        'Application.clients[0].relationship',
        relationshipSchema.enum[relationshipSchema.enumNames.indexOf('Self')]
      )
    }

    if (!_.isEmpty(this.props.myApplicationData)) {
      if (!_.isEqual(newFormData, formData)) {
        this.props.updateMyApplication(applId, 'DRAFT', newFormData)
      }
    } else {
      this.props.addMyApplication(applId, 'DRAFT', newFormData)
    }

    formContext.formData = newFormData
    formContext.reviewFormData = newFormData
    this.setState(
      { application, forms, tabs, formContext, errorMessages },
      () => {}
    )
    this._ObligeeOrObligor(step, formData)
  }

  _ObligeeOrObligor = (step, formData) => {
    //Check for obligor and obligee
    if (step === 8) {
      let ObligeeCheck = formData.Application.clientApplicant[0].roleOfApplicant
      let ObligeeBtn = document.getElementById(
        'AppIntake_Application_clientsObligee_addBtn'
      )
      let ObligorBtn = document.getElementById(
        'AppIntake_Application_clientsObligor_addBtn'
      )
      if (ObligeeBtn != undefined) {
        ObligeeBtn.className = 'tableShowNone'
      }
      if (ObligorBtn != undefined) {
        ObligorBtn.className = 'tableShowNone'
      }
      if (ObligorBtn && ObligeeCheck === 'Y') {
        ObligorBtn.className = 'btn btn-default btn-end-form tableStyleShow'
      } else if (ObligeeBtn && ObligeeCheck === 'N') {
        ObligeeBtn.className = 'btn btn-default btn-end-form tableStyleShow'
      }
    }
  }

  _checkChildCareTabEnable = fieldId => {
    const formData = this.state.formContext.formData
    return (
      _.includes(
        _.get(formData, 'Application.benefitCategorieslist', []),
        'CHCR'
      ) &&
      _.some(_.get(formData, 'Application.clients', []), (element, key) => {
        return element.optBenefitCHCR
      })
    )
  }

  _checkChildSupportTabEnable = fieldId => {
    const formData = this.state.formContext.formData
    return (
      _.includes(
        _.get(formData, 'Application.benefitCategorieslist', []),
        'CHSP'
      ) &&
      _.some(_.get(formData, 'Application.clients', []), (element, key) => {
        return element.optBenefitCHSP
      })
    )
  }
  _checkChildSupportEnable = fieldId => {
    const formData = this.state.formContext.formData
    return (
      _.includes(
        _.get(formData, 'Application.benefitCategorieslist', []),
        'CHSP'
      ) &&
      _.some(_.get(formData, 'Application.clients', []), (element, key) => {
        return element.optBenefitCHSP
      })
    )
  }

  _isClientEligibleForCHCR = clientIndex => {
    const formData = this.state.formContext.formData
    return (
      _.includes(
        _.get(formData, 'Application.benefitCategorieslist', []),
        'CHCR'
      ) &&
      _.get(
        formData,
        'Application.clients[' + clientIndex + '].optBenefitCHCR',
        false
      )
    )
  }

  _addChildSupportsFeildsToSchema = formData => {
    for (let i = 1; i < formData.Application.clients.length; i++) {
      // TO_DO remove since there is no age check - GPRB0359584 - US3185134
      if (
        formData.Application.clients[i].optBenefitCHSP
        //&& cur.diff(birthdate, 'years') < 18
      ) {
        _.set(
          formData,
          'Application.clients[' + i + '].obligeeRelationship',
          undefined
        )
        _.set(
          formData,
          'Application.clients[' + i + '].obligorRelationship',
          undefined
        )
        _.set(formData, 'Application.clients[' + i + '].receiveSSI', undefined)
        _.set(
          formData,
          'Application.clients[' + i + '].paternityEstablished',
          undefined
        )
        _.set(
          formData,
          'Application.clients[' + i + '].parentsMarried',
          undefined
        )
        _.set(formData, 'Application.clients[' + i + '].parentsName', undefined)
        _.set(formData, 'Application.clients[' + i + '].copyOfBC', undefined)
      }
    }
  }

  _onFormDataChange = ({ formData }) => {
    const { forms, formContext } = this.state
    const { step } = this.props
    if (step === 1) {
      this._addChildSupportsFeildsToSchema(formData)
    }
    if (
      formData.Application.benefitCategorieslist &&
      formData.Application.someoneAsstYouFlg === 'N'
    ) {
      const index = formData.Application.benefitCategorieslist.indexOf(
        'CHCR',
        0
      )
      if (index > -1) {
        formData.Application.benefitCategorieslist.splice(index, 1)
      }
    }

    this._ObligeeOrObligor(step, formData)
    const tempMaidId = formData.Application.clients[0].temporaryMaidNumber
    const isTempMaidIdValidated = _.get(
      formContext,
      'isTempMaidIdValidated',
      false
    )
    if (tempMaidId && tempMaidId.length === 11 && !isTempMaidIdValidated) {
      this._backendValidate(tempMaidId)
    }
    //Clear the review signature info if we changed anything other than that page.
    if (step != forms.length) {
      _.unset(formData, 'Application.agreement')
      _.unset(formData, 'Application.signatureReceived')
    }

    for (let i = 0; i < formData.Application.clients.length; i++) {
      const clientData = formData.Application.clients[i]
      const dependantInformationArray =
        clientData.dependents &&
        clientData.dependents.map(dependent => {
          let dependentIndexClient
          let dependentIndexContributor
          for (let j = 0; j < formData.Application.clients.length; j++) {
            if (parseInt(dependent) === j) {
              dependentIndexClient = j
            }
          }
          if (formData.Application.contributors) {
            for (let k = 0; k < formData.Application.contributors.length; k++) {
              if (
                dependent === formData.Application.contributors[k].clientFstNm
              ) {
                dependentIndexContributor = k
              }
            }
          }
          if (formData.Application.clients[dependentIndexClient]) {
            const dependentClient =
              formData.Application.clients[dependentIndexClient]
            return (
              clientData.clientFstNm +
              ' ' +
              clientData.clientLstNm +
              ' is claiming ' +
              dependentClient.clientFstNm +
              ' ' +
              dependentClient.clientLstNm +
              ' as a dependent'
            )
          } else if (
            formData.Application.contributors &&
            formData.Application.contributors[dependentIndexContributor]
          ) {
            const dependentClient =
              formData.Application.contributors[dependentIndexContributor]
            return (
              clientData.clientFstNm +
              ' ' +
              clientData.clientLstNm +
              ' is claiming ' +
              dependentClient.clientFstNm +
              ' ' +
              dependentClient.clientLstNm +
              ' as a dependent'
            )
          }
        })
      _.set(
        formData,
        'Application.clients[' + i + '].dependantInformation',
        dependantInformationArray
      )
      if (clientData.militaryEndDate === undefined) {
        _.set(
          formData,
          'Application.clients[' + i + '].militaryActiveStatus',
          'Y'
        )
      } else {
        _.set(
          formData,
          'Application.clients[' + i + '].militaryActiveStatus',
          'N'
        )
      }
    }

    for (let i = 0; i < formData.Application.clients.length; i++) {
      const clientDetails = formData.Application.clients[i]
      const unpaidBillsForMonths =
        clientDetails && clientDetails.unpaidBillsForMonths
      if (clientDetails && unpaidBillsForMonths === '1') {
        _.set(
          formData,
          'Application.clients[' + i + '].livesTogetherSecondMonth',
          undefined
        )
        _.set(
          formData,
          'Application.clients[' + i + '].livesTogetherThirdMonth',
          undefined
        )
      } else if (clientDetails && unpaidBillsForMonths === '2') {
        _.set(
          formData,
          'Application.clients[' + i + '].livesTogetherThirdMonth',
          undefined
        )
      } else if (clientDetails && unpaidBillsForMonths === undefined) {
        _.set(
          formData,
          'Application.clients[' + i + '].livesTogetherFirstMonth',
          undefined
        )
        _.set(
          formData,
          'Application.clients[' + i + '].livesTogetherSecondMonth',
          undefined
        )
        _.set(
          formData,
          'Application.clients[' + i + '].livesTogetherThirdMonth',
          undefined
        )
      }
    }

    if (
      formData.Application.benefitCategorieslist &&
      formData.Application.benefitCategorieslist.length > 0 &&
      formData.Application.benefitCategorieslist.indexOf('CHCR') > -1
    ) {
      _.set(formData, 'Application.clients[' + 0 + '].optBenefitCHCR', true)
    }

    for (let i = 0; i < formData.Application.clients.length; i++) {
      if (
        formData.Application.benefitCategorieslist &&
        formData.Application.benefitCategorieslist.length > 0 &&
        formData.Application.benefitCategorieslist.indexOf('HMEN') > -1
      ) {
        _.set(formData, 'Application.clients[' + i + '].optBenefitHMEN', true)
      }
    }

    let value = formData.Application.clients
    _.forEach(value, function(client) {
      if (
        client.unpaidMedicalBills &&
        client.unpaidMedicalBills !== undefined
      ) {
        client.unpaidBillsForMonths =
          client.unpaidMedicalBills === 'N' ? '' : client.unpaidBillsForMonths
      }
      if (client.clientFarmWorker && client.clientFarmWorker !== undefined) {
        client.farmerJobEnd =
          client.clientFarmWorker === 'N' ? '' : client.farmerJobEnd
        client.twentyFiveDollarQue =
          (client.farmerJobEnd =
            client.clientFarmWorker === 'N' ? '' : client.farmerJobEnd) ===
            'N' || client.clientFarmWorker === 'N'
            ? ''
            : client.twentyFiveDollarQue
      }
    })
    for (let i = 0; i < formData.Application.clients.length; i++) {
      const thisApplicant = formData.Application.clients[i]
      if (
        i !== 0 &&
        thisApplicant.optBenefitCHSP &&
        thisApplicant.countryOfBirth === undefined
      ) {
        //defaulting countryofbirth to 'US'
        _.set(formData, 'Application.clients[' + i + '].countryOfBirth', 'US')
      }
      //setting countryofbirth on user select
      else if (
        i !== 0 &&
        (thisApplicant.relationship === '37' ||
          thisApplicant.relationship === '59') &&
        thisApplicant.optBenefitCHSP &&
        thisApplicant.countryOfBirth != undefined
      ) {
        _.set(
          formData,
          'Application.clients[' + i + '].countryOfBirth',
          thisApplicant.countryOfBirth
        )
      }
    }
    if (+this.state.slideIndex > 4 && +step === 1) {
      for (let i = 0; i < formData.Application.clients.length; i++) {
        let thisApplicant = formData.Application.clients[i]
        let birthdate = _moment(config, this.state.currentDate).diff(
          thisApplicant.clientBthDt,
          'days',
          false
        )
        if (birthdate > 6574 && thisApplicant.optBenefitCHCR) {
          _.set(
            formData,
            'Application.clients[' + i + '].role',
            'parentorcaregiver'
          )
        } else if (!thisApplicant.optBenefitCHCR) {
          _.set(formData, 'Application.clients[' + i + '].role', undefined)
        }
      }
    }

    //Update client data based on changes to benefitCategoriesList
    if (
      !_.isEqual(
        _.get(formData, 'Application.benefitCategorieslist', []),
        _.get(
          this.props.myApplicationData,
          'Application.benefitCategorieslist',
          []
        )
      )
    ) {
      const benefitCategoriesList = _.get(
        formData,
        'Application.benefitCategorieslist',
        []
      )
      for (
        var i = 0;
        i < _.get(formData, 'Application.clients', []).length;
        i++
      ) {
        if (!benefitCategoriesList.includes('HEWL')) {
          _.set(
            formData,
            'Application.clients[' + i + '].optBenefitHEWL',
            false
          )
        }
        if (!benefitCategoriesList.includes('WVWORKS')) {
          _.set(
            formData,
            'Application.clients[' + i + '].optBenefitWVWORKS',
            false
          )
        }
        if (!benefitCategoriesList.includes('FMAS')) {
          _.set(
            formData,
            'Application.clients[' + i + '].optBenefitFMAS',
            false
          )
        }
        if (!benefitCategoriesList.includes('FDNT')) {
          _.set(
            formData,
            'Application.clients[' + i + '].optBenefitFDNT',
            false
          )
        }
        if (!benefitCategoriesList.includes('HMEN')) {
          _.set(
            formData,
            'Application.clients[' + i + '].optBenefitHMEN',
            false
          )
        }
        if (!benefitCategoriesList.includes('CHSP')) {
          _.set(
            formData,
            'Application.clients[' + i + '].optBenefitCHSP',
            false
          )
        }
        if (!benefitCategoriesList.includes('MDPA')) {
          _.set(
            formData,
            'Application.clients[' + i + '].optBenefitMDPA',
            false
          )
        }
        if (!benefitCategoriesList.includes('CHCR')) {
          _.set(
            formData,
            'Application.clients[' + i + '].optBenefitCHCR',
            false
          )
          _.set(
            formData,
            'Application.clients[' + i + '].needAllDayChildCare',
            undefined
          )
          _.set(
            formData,
            'Application.clients[' + i + '].needBeforeSchoolChildCare',
            undefined
          )
          _.set(
            formData,
            'Application.clients[' + i + '].needAfterSchoolChildCare',
            undefined
          )
          _.set(
            formData,
            'Application.clients[' + i + '].needSpecialChildCare',
            undefined
          )
        }
      }
    }
    let xpdtApplArray = []
    xpdtApplArray.push(formData.Application.clients[0].hundredDollarCash)
    xpdtApplArray.push(formData.Application.clients[0].clientFarmWorker)
    xpdtApplArray.push(
      formData.Application.clients[0].hundredAndFiftyDollarCash
    )
    xpdtApplArray.push(formData.Application.clients[0].rentMorgage)
    if (xpdtApplArray.indexOf('Y') > -1) {
      _.set(formData, 'Application.xpdtAppl', 'Y')
    } else {
      _.set(formData, 'Application.xpdtAppl', 'N')
    }
    if (
      formData.Application.clientsObligor &&
      formData.Application.clientsObligor[0].nonCustodialParentObligor === true
    ) {
      _.set(formData, 'Application.clientsObligor[0].clientFrstNm', 'Unknown')
      _.set(formData, 'Application.clientsObligor[0].clientLastNm', 'Unknown')
    } else if (
      formData.Application.clientsObligor &&
      formData.Application.clientsObligor[0].nonCustodialParentObligor ===
        false &&
      formData.Application.clientsObligor[0].clientFrstNm === 'Unknown' &&
      formData.Application.clientsObligor[0].clientLastNm === 'Unknown'
    ) {
      _.set(formData, 'Application.clientsObligor[0].clientFrstNm', '')
      _.set(formData, 'Application.clientsObligor[0].clientLastNm', '')
    }

    for (let i = 0; i < formData.Application.clients.length; i++) {
      const clientData = formData.Application.clients[i]
      const wagesDetails = _.get(
        clientData,
        'incometypeDetails.earnedIncome.wagesDetails',
        []
      )
      let selfEmploymentIncomeDetails = _.get(
        clientData,
        'incometypeDetails.selfemploymentIncome.selfEmploymentIncomeDetails',
        []
      )
      for (let k = 0; k < wagesDetails.length; k++) {
        const setSchedule = _.get(wagesDetails[k], 'setSchedule', '')
        let workDayIndex = _.get(wagesDetails[k], 'workdayTimings', [])
        if (setSchedule === 'N') {
          workDayIndex = workDayIndex.map(ele => {
            let keys = Object.keys(ele)
            keys.forEach(key => {
              ele[key] = undefined
            })
            return ele
          })
        }
      }
      if (selfEmploymentIncomeDetails.length > 0) {
        selfEmploymentIncomeDetails = selfEmploymentIncomeDetails.map(
          element => {
            if (element.hasOwnProperty('income')) {
              if (element.income.length > 0) {
                for (let i = 0; i < element.income.length; i++) {
                  if (
                    element.income[i].hasOwnProperty('setSchedule') &&
                    element.income[i].setSchedule === 'N'
                  ) {
                    if (
                      element.income[i].hasOwnProperty('workdayTimings') &&
                      element.income[i].workdayTimings.length > 0
                    ) {
                      element.income[i].workdayTimings = element.income[
                        i
                      ].workdayTimings.map(days => {
                        let keys = Object.keys(days)
                        keys.forEach(day => {
                          days[day] = undefined
                        })
                        return days
                      })
                    }
                  }
                }
              }
            }
            return element
          }
        )
      }
    }

    this.props.updateMyApplication(
      this.props.activeApplicationId,
      'DRAFT',
      formData
    )

    let tabs = this.state.tabs
    if (
      this.state.submitClicked &&
      _.get(formData, 'Application.benefitCategorieslist', []).indexOf('CHSP') >
        -1
    ) {
      if (step == 4 && tabs[7].status == 'error') {
        tabs[7].status = 'editing'
        tabs[7].rightIcon = editingIcon
        tabs[7].rightSpan = editingBadge
      } else if (step == 8 && tabs[3].status == 'error') {
        tabs[3].status = 'editing'
        tabs[3].rightIcon = editingIcon
        tabs[3].rightSpan = editingBadge
      }
    }

    if (tabs[step - 1].status !== 'editing') {
      this.setState({
        tabs: [
          ...tabs.slice(0, step - 1),
          {
            ...tabs[step - 1],
            status: 'editing',
            rightIcon: editingIcon,
            rightSpan: editingBadge
          },
          ...tabs.slice(step)
        ]
      })
    }
    if (
      formData.Application.benefitCategorieslist &&
      formData.Application.benefitCategorieslist.length > 0 &&
      formData.Application.benefitCategorieslist.indexOf('HEWL') > -1
    ) {
      let clients = _.get(formData, 'Application.clients', [])
      clients.forEach(client => {
        if (client.dependents && client.dependents.length > 0) {
          client.dependents.forEach(dependent => {
            if (formData.Application.clients[dependent]) {
              formData.Application.clients[dependent].clmDepnFlg = 'N'
            }
          })
        }
      })
    }
    this.setState({
      citizenShipOrChildSupportStep: step == 4 || step == 8,
      formContext: {
        ...this.state.formContext,
        formData: _.cloneDeep(formData)
      }
    })
  }

  _onValidate = (formData: object, errors: object) => {
    let newFormData = helper.deepClone(this.state.formContext.formData)
    if (!newFormData.Application) {
      newFormData = formData
    }
    if (
      _.includes(
        this.props.selectedEntitlements,
        ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
      )
    ) {
      newFormData.isPeApplication = true
    } else {
      newFormData.isPeApplication = false
    }
    const { forms, tabs, liveValidate, formContext } = this.state
    const { step } = this.props

    //Call CP-specific validation library.
    if (this.state.liveValidate) {
      validator.validateCPForm(
        newFormData,
        errors,
        forms.length === step ? null : step,
        this.state.formContext,
        this.state.incomplete ? true : false,
        this.state.currentDate,
        this.state.submitClicked,
        this.state.errorMessages
      )
    }

    //Determine which tabs contain errors and update their statuses.
    let foundErrors = helper.findErrors(errors)

    if (foundErrors && foundErrors.length > 0) {
      this.hasError = true
      let newTabs = [...tabs]
      for (let i = 0; i < foundErrors.length; ++i) {
        try {
          let errorObject = JSON.parse(foundErrors[i])
          let errorStep = errorObject.step - 1
          if (
            newTabs[errorStep].status !== 'error' &&
            !this.state.citizenShipOrChildSupportStep
          ) {
            newTabs[errorStep].status = 'error'
            newTabs[errorStep].rightIcon = errorIcon
            newTabs[errorStep].rightSpan = errorBadge
          }
        } catch (err) {
          console.error('Error on parsing errors', err)
        }
      }
      const newTabStatus = this._determineTabStatus(newTabs)
      if (!_.isEqual(newTabStatus, this.props.tabStatus)) {
        this.props.updateMyApplicationTabStatus(
          this.props.activeApplicationId,
          newTabStatus
        )
      }
    } else {
      this.hasError = false
      this.setState({
        liveValidate: false,
        formContext: {
          ...formContext,
          hasError: this.hasError
        }
      })
    }

    return errors
  }

  _onSubmit = ({ formData }) => {
    // This executes after validation has passed.
    const { liveValidate, forms } = this.state
    const { step } = this.props

    //Set tab(s) to validated status
    let newTabs = helper.deepClone(this.state.tabs)
    if (step === forms.length) {
      for (let i = 0; i < forms.length; ++i) {
        if (i !== 1) {
          newTabs[i].status = 'validated'
          newTabs[i].rightIcon = validatedIcon
          newTabs[i].rightSpan = validatedBadge
        }
      }
      this.setState(
        {
          // liveValidate: false,
          tabs: newTabs
        },
        () => {
          let benefitCategorieslist = _.get(
            this.state.formContext,
            'formData.Application.benefitCategorieslist',
            []
          )
          if (benefitCategorieslist.indexOf('WVWORKS') > -1) {
            this._showDomesticViolenceModal()
          } else {
            this._submitFormData()
          }
        }
      )
    } else {
      newTabs[step - 1].status = 'validated'
      newTabs[step - 1].rightIcon = validatedIcon
      newTabs[step - 1].rightSpan = validatedBadge
      this.setState(
        {
          //  liveValidate: false,
          tabs: newTabs,
          showSubmissionModal: false // incase reset to false after the flow went through and set to true
        },
        () => {
          this.setState(
            {
              liveValidate: false
            },
            () => {
              this._onNext()
            }
          )
        }
      )
    }
  }

  _setVictimOfAFamOrDomestViol(value) {
    let formData = this.state.formContext.formData
    _.set(formData.Application, 'victimOfAFamOrDomestViol', value)
    this.props.updateMyApplication(
      this.props.activeApplicationId,
      'DRAFT',
      formData
    )
  }

  _showDomesticViolenceModal() {
    this.setState({
      showDomesticViolenceModal: true
    })
  }

  _exitDomesticViolenceModal() {
    this.setState(
      {
        showDomesticViolenceModal: false
      },
      () => this._submitFormData()
    )
  }
  _onNext = () => {
    // increment step in forms
    const { tabs, forms } = this.state
    const { step, activeApplicationId, updateMyApplicationStep } = this.props
    let courtOrder = false
    let birthCertificate = []
    if (this.props.step === 12) {
      const supportOrderFlag =
        this.props.myApplicationData.Application.clientApplicant[0]
          .supportOrder === 'Y'
          ? true
          : false
      let courtOrderDocs
      const myApplicationData = this.props.myApplicationData
      for (let i = 0; i < myApplicationData.Application.clients.length; i++) {
        if (myApplicationData.Application.clients[i].myDocuments) {
          courtOrderDocs =
            _.find(
              this.props.myApplicationData.Application.clients[i].myDocuments[0]
                .docList,
              function(o) {
                return o.fileType === 'Court Order'
              }
            ) || courtOrderDocs
        }
        if (myApplicationData.Application.clients[i].copyOfBC === 'Y') {
          if (myApplicationData.Application.clients[i].myDocuments) {
            let birthCertDocs = _.find(
              myApplicationData.Application.clients[i].myDocuments[0].docList,
              function(o) {
                return o.fileType === 'Birth Certificate'
              }
            )
            if (birthCertDocs === undefined) {
              birthCertificate.push('Please attach Birth Certificate')
              this.setState(prevState => {
                return {
                  ...prevState,
                  errorMessage: {
                    ...prevState.errorMessage,
                    birthCertificate: birthCertificate
                  }
                }
              })
            } else {
              this.setState(prevState => {
                return {
                  ...prevState,
                  errorMessage: {
                    ...prevState.errorMessage,
                    birthCertificate: birthCertificate
                  }
                }
              })
            }
          } else {
            birthCertificate.push('Please attach Birth Certificate')
            this.setState(prevState => {
              return {
                ...prevState,
                errorMessage: {
                  ...prevState.errorMessage,
                  birthCertificate: birthCertificate
                }
              }
            })
          }
        } else {
          this.setState(prevState => {
            return {
              ...prevState,
              errorMessage: {
                ...prevState.errorMessage,
                birthCertificate: birthCertificate
              }
            }
          })
        }
      }
      if (supportOrderFlag && !courtOrderDocs) {
        courtOrder = false
        this.setState(prevState => {
          return {
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              courtOrder: true
            }
          }
        })
      } else {
        courtOrder = false
        this.setState(prevState => {
          return {
            ...prevState,
            errorMessage: {
              ...prevState.errorMessage,
              courtOrder: false
            }
          }
        })
      }
    }
    if (true) {
      let nextStep = step + 1

      while (!tabs[nextStep - 1].isVisible) {
        nextStep++
      }

      if (nextStep > forms.length) {
        return
      }

      const nextShowNext = nextStep > 1
      if (nextStep === 2) {
        updateMyApplicationStep(activeApplicationId, nextStep + 1)
      } else {
        updateMyApplicationStep(activeApplicationId, nextStep)
      }
      this.setState(
        {
          tabs: [
            ...tabs.slice(0, step - 1),
            {
              ...tabs[step - 1],
              status: 'validated',
              rightIcon: validatedIcon,
              rightSpan: validatedBadge
            },
            ...tabs.slice(step)
          ],
          showNext: nextShowNext
        },
        () =>
          this._saveFormDataDraft(
            false,
            nextStep,
            this._determineTabStatus(this.state.tabs)
          )
      )
      if (step != 1) {
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          this.props.tabStatus[nextStep - 1].name
            .toLowerCase()
            .split('_')
            .map(function(word) {
              return word.charAt(0).toUpperCase() + word.slice(1)
            })
            .join(' ') +
          ' - Benefits Application'
      } else {
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          this.props.tabStatus[nextStep].name
            .toLowerCase()
            .split('_')
            .map(function(word) {
              return word.charAt(0).toUpperCase() + word.slice(1)
            })
            .join(' ') +
          ' - Benefits Application'
      }

      this._updateClientRelationship(this.state.formContext.formData)

      // return to top of form
      const content = document.getElementById('content')
      if (content) {
        window.scroll(0, content.offsetTop)
        content.focus()
      }
    }
  }

  // Goes to previous step while saving formData
  _onPrevious = () => {
    const { tabs, forms } = this.state
    const { step } = this.props

    // check if step is already at the beginning
    if (step === 1) return false

    // decrease step and save formData
    let previousStep = step - 1

    while (!tabs[previousStep - 1].isVisible) {
      previousStep--
    }

    if (previousStep > forms.length) {
      return
    }

    //Set the previous tab to editing status.
    this._changeStep(previousStep)

    // return to top of form
    const content = document.getElementById('content')
    if (content) {
      content.focus()
    }
  }

  // changing step of application, sets whether or not it should show the next button
  _changeStep = step => {
    if (step === STEPS.FAMILY_AND_HOUSEHOLD) {
      this._updateClientRelationship(this.state.formContext.formData)
    }
    const { activeApplicationId, updateMyApplicationStep } = this.props
    if (this.props.tabStatus.length > 0) {
      if (step != 2) {
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          this.props.tabStatus[step - 1].name
            .toLowerCase()
            .split('_')
            .map(function(word) {
              return word.charAt(0).toUpperCase() + word.slice(1)
            })
            .join(' ') +
          ' - Benefits Application'
      } else {
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Start - Benefits Application'
      }
    }

    updateMyApplicationStep(activeApplicationId, step)
    this._setShowNext(step > 1)
  }

  _setShowNext = next => {
    this.setState({ showNext: next })
  }

  _errorListTemplate = (props: any) => {
    const { errors, schema, uiSchema } = props

    const masterSchema = helper.resolveSchemaDefinitions(
      schema,
      schema.definitions
    )

    const orderedErrors = this.orderErrors(errors, masterSchema, uiSchema)

    return (
      <div
        className="panel panel-danger errors"
        id="appintake-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <span className="panel-title">Errors</span>
        </div>
        <ul className="list-group">
          {orderedErrors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this._onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  orderErrors = (errors, masterSchema, uiSchema): any[] => {
    const orderArray = _.uniq(this.buildOrderArray(masterSchema))
    const errorKeyMsgRegex = new RegExp(/^([a-zA-Z0-9]+): (.*)/)

    const newErrorStackQueue = []
    errors.map(error => {
      const errorResult = error.stack.match(errorKeyMsgRegex)
      const errorKey = errorResult[1]
      let errorContentsObj
      try {
        errorContentsObj = JSON.parse(errorResult[2])
      } catch (err) {
        errorContentsObj = {}
      }
      // Code Changes for Deferred Defect 4286
      const step = errorContentsObj.step
      const index = _.get(_.get(errorContentsObj, 'step', ''), '1', '0')

      if (newErrorStackQueue[index] === undefined) {
        for (let i = 0; i <= index; i++) {
          if (newErrorStackQueue[i] === undefined) {
            newErrorStackQueue.push([])
          }
        }
      }

      newErrorStackQueue[index].push({
        index: _.indexOf(orderArray, errorKey),
        value: errorKey,
        stack: error.stack,
        step: step
      })
    })

    let tempErrorObjList = []
    for (let i = 0; i < newErrorStackQueue.length; i++) {
      tempErrorObjList = _.concat(
        tempErrorObjList,
        _.sortBy(newErrorStackQueue[i], ['step'])
      )
    }

    const orderedErrors = _.forEach(tempErrorObjList, obj => obj.stack)

    return orderedErrors
  }

  buildOrderArray = (localSchema, orderArray = []) => {
    switch (localSchema['type']) {
      case 'object':
        this.buildOrderArray(localSchema['properties'], orderArray)
        break
      case 'array':
        this.buildOrderArray(localSchema['items'], orderArray)
        break
      case undefined:
        if (_.isObject(localSchema)) {
          _.forEach(localSchema, (v, k) => {
            switch (v['type']) {
              case 'object':
                if (
                  _.isEmpty(
                    _.xor(_.keys(v['properties']), [
                      'category',
                      'subCategory',
                      'languageCode',
                      'key',
                      'value',
                      'rulesEngineCode',
                      'sortOrder'
                    ])
                  )
                ) {
                  orderArray.push(k)
                  break
                }
                this.buildOrderArray(v['properties'], orderArray)
                break
              case 'array':
                if (
                  _.isEmpty(
                    _.xor(_.keys(_.get(v, 'items.properties')), [
                      'category',
                      'subCategory',
                      'languageCode',
                      'key',
                      'value',
                      'rulesEngineCode',
                      'sortOrder'
                    ])
                  ) ||
                  _.get(v, 'items.enumNames', []).length > 0
                ) {
                  orderArray.push(k)
                  break
                }
                this.buildOrderArray(v['items'], orderArray)
                break
              default:
                orderArray.push(k)
            }
          })
        } else {
          console.log('buildOrderArray localSchema not object', localSchema)
        }
    }

    return orderArray
  }

  _onErrorClick = (error: any) => {
    event.preventDefault()
    const { forms } = this.state
    const { step, activeApplicationId, updateMyApplicationStep } = this.props
    let clientIndex

    if (error.id.match('Application_clients_([0-9]+)')) {
      clientIndex = error.id.match('Application_clients_([0-9]+)')[1]
    } else if (error.id.match('Application_clientApplicant_([0-9]+)')) {
      clientIndex = error.id.match('Application_clientApplicant_([0-9]+)')[1]
    } else if (
      error.id.match(' Application_childSupportEnforcement_([0-9]+)')
    ) {
      clientIndex = error.id.match(
        'Application_childSupportEnforcement_([0-9]+)'
      )[1]
    }
    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          accordionActiveId:
            forms[error.step - 1].uiSchema['ui:rootFieldId'] +
            '_Application_clients',
          accordionActiveKey: +clientIndex
        }
      },
      () => {
        setTimeout(() => {
          if (step != error.step) {
            updateMyApplicationStep(activeApplicationId, error.step)
          }
          if (step != 1) {
            this.setState({
              viewId:
                forms[error.step - 1].uiSchema['ui:rootFieldId'] +
                '_' +
                error.id
            })
          } else if (step === 1) {
            let errorSlideNumber
            let errorSlideObject
            if (error.id.includes('clients')) {
              errorSlideObject = this.state.slides.find(slide => {
                let keys, matchingKey
                if (slide.localUiSchema.clients) {
                  keys = Object.keys(slide.localUiSchema.clients.items)
                  matchingKey = keys.find(key => {
                    return error.id.includes(key)
                  })
                }
                return matchingKey ? matchingKey.length > 0 : false
              })
              errorSlideNumber = this.state.slides.indexOf(errorSlideObject)
            } else if (error.id.includes('assister')) {
              errorSlideObject = this.state.slides.find(slide => {
                let keys, matchingKey
                if (slide.localUiSchema.assister) {
                  keys = Object.keys(slide.localUiSchema.assister)
                  matchingKey = keys.find(key => {
                    return error.id.includes(key)
                  })
                }

                return matchingKey ? matchingKey.length > 0 : false
              })
              errorSlideNumber = this.state.slides.indexOf(errorSlideObject)
            } else {
              errorSlideObject = this.state.slides.find(slide => {
                const keys = Object.keys(slide.localUiSchema)
                const matchingKey = keys.find(key => {
                  return error.id.includes(key)
                })
                return matchingKey ? matchingKey.length > 0 : false
              })
              errorSlideNumber = this.state.slides.indexOf(errorSlideObject)
            }
            this.setState(prevState => {
              return {
                ...prevState,
                viewId:
                  forms[error.step - 1].uiSchema['ui:rootFieldId'] +
                  '_' +
                  error.id,
                formContext: {
                  ...prevState.formContext,
                  slideIndex: errorSlideNumber
                }
              }
            })
          }
        }, 1000)
      }
    )
  }

  _switchOnLiveValidate = () => {
    this.setState({
      citizenShipOrChildSupportStep: false,
      liveValidate: true
    })
  }

  _submitClicked = () => {
    this.setState({
      citizenShipOrChildSupportStep: false,
      liveValidate: true,
      submitClicked: true
    })
  }
  _onError = (errors: object) => {
    const { liveValidate, formContext } = this.state
    const { step } = this.props

    //Enable live validation upon error to allow for realtime evaluation of errors in response to formData changes.
    if (!liveValidate) {
      this.setState({
        //   liveValidate: true,
        formContext: {
          ...formContext,
          hasError: this.hasError
        }
      })
    }
    const errorContent = document.getElementById('appintake-errors')
    if (errorContent) {
      window.scroll(0, errorContent.offsetTop)
      errorContent.focus()
    }
    if (step != 2) {
      document.title =
        (this.hasError ? 'Error' + ' - ' : '') +
        this.props.tabStatus[step - 1].name
          .toLowerCase()
          .split('_')
          .map(function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
          })
          .join(' ') +
        ' - Benefits Application'
    } else {
      document.title =
        (this.hasError ? 'Error' + ' - ' : '') + 'Start - Benefits Application'
    }
  }

  _transformErrors = (errors: object) => {
    return errors
  }

  _openAddContributor = (targetFieldId: string, fieldType: string) => {
    const { forms } = this.state

    //TODO: move schema creation to initial appintake load
    let localSchema = {}
    let localUiSchema = {}

    for (let i = 0; i < forms.length; ++i) {
      _.merge(
        localSchema,
        _.get(
          forms[i].schema,
          'properties.Application.properties.contributors',
          {}
        )
      )
      _.merge(localSchema, {
        definitions: _.get(forms[i].schema, 'definitions', {})
      })
      _.merge(
        localUiSchema,
        _.get(forms[i].uiSchema, 'Application.contributors', {})
      )
    }

    localSchema = {
      ...localSchema,
      minItems: 1,
      title: '',
      description: ''
    }

    localUiSchema = {
      ...localUiSchema,
      'ui:rootFieldId': 'AppIntake_Application_contributors',
      'ui:title': '',
      'ui:description': '',
      'ui:array': null,
      'ui:options': {
        orderable: false,
        addable: false,
        removable: false
      }
    }

    this.setState({
      contributorModalProps: {
        show: true,
        targetFieldId,
        fieldType,
        form: {
          schema: localSchema,
          uiSchema: localUiSchema,
          refs: this.state.application['metaData']['refs']
        }
      }
    })
  }

  _saveContributor = contributorFormData => {
    if (contributorFormData[0].clientBthDt) {
      contributorFormData[0].clientBthDt = _moment(
        config,
        contributorFormData[0].clientBthDt,
        'YYYY-MM-DD'
      ).format('MM/DD/YYYY')
    } else {
      contributorFormData[0].clientBthDt = undefined
    }
    const modalProps = this.state.contributorModalProps

    let newFormData = helper.deepClone(this.props.myApplicationData)

    newFormData = {
      ...newFormData,
      Application: {
        ...newFormData.Application,
        contributors: [
          ...(newFormData.Application.contributors
            ? newFormData.Application.contributors
            : []),
          ...contributorFormData
        ]
      }
    }

    //Determine the formData path to the field where we clicked "Other Person Not Applying" and set it accordingly
    let targetPath = helper.idSchemaToPath(modalProps.targetFieldId)

    switch (modalProps.fieldType) {
      case 'string':
        _.set(newFormData, targetPath, contributorFormData[0].clientFstNm)
        break
      case 'array':
        let targetFormData = _.get(newFormData, targetPath, [])
        if (targetFormData && targetFormData.length > 0) {
          _.set(newFormData, targetPath, [
            ...targetFormData,
            contributorFormData[0].clientFstNm
          ])
        } else {
          _.set(newFormData, targetPath, [contributorFormData[0].clientFstNm])
        }
    }
    this.setState(
      {
        contributorModalProps: {
          ...this.state.contributorModalProps,
          show: false
        }
      },
      () => {
        this.props.updateMyApplication(
          this.props.activeApplicationId,
          'DRAFT',
          newFormData
        )
        alert('Successfully added non-applicant.')
      }
    )
    this._onFormDataChange({ formData: newFormData })
  }

  _cancelContributor = () => {
    this.setState({
      contributorModalProps: {
        ...this.state.contributorModalProps,
        targetFieldId: null,
        fieldType: null,
        show: false
      }
    })
  }

  _setRedirect = url => {
    this.props.setActiveApplicationId('0')
    this.setState({ redirect: baseUrl + url })
  }

  updateUploadedDocs(clients) {
    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          formData: {
            ...this.state.formContext.formData,
            Application: {
              ...this.state.formContext.formData.Application,
              clients
            }
          }
        }
      },
      () => {
        this._onFormDataChange({
          formData: {
            Application: this.state.formContext.formData.Application,
            applId: this.props.myApplicationData.applId
          }
        })
      }
    )
  }

  _onDocUpload = (uploadedDoc: any) => {
    if (uploadedDoc.doc.ptype === 'myDocuments') {
      const clients = this.state.formContext.formData.Application.clients.slice()
      const client = clients[uploadedDoc.clientIndex]
      const docList =
        client.myDocuments &&
        client.myDocuments[uploadedDoc.documentClssIndex].docList
      if (!client.myDocuments) {
        client.myDocuments = JSON.parse(
          JSON.stringify(
            this.state.application['forms'][STEPS.DOCUMENT_UPLOAD - 1]
              .myDocsSchema
          )
        )
      }
      if (client.myDocuments) {
        if (
          uploadedDoc.doc.typeRefCode === '2' ||
          uploadedDoc.doc.typeRefCode === '18'
        ) {
          const existingDocument =
            docList &&
            docList.find(document => {
              return document.typeRefCode === uploadedDoc.doc.typeRefCode
            })
          if (existingDocument) {
            const removalIndex = _.indexOf(docList, existingDocument)
            client.myDocuments[uploadedDoc.documentClssIndex].docList.splice(
              removalIndex,
              1
            )
            client.myDocuments[uploadedDoc.documentClssIndex].docList.push(
              uploadedDoc.doc
            )
          } else {
            client.myDocuments &&
              client.myDocuments[uploadedDoc.documentClssIndex].docList.push(
                uploadedDoc.doc
              )
          }
        } else {
          client.myDocuments &&
            client.myDocuments[uploadedDoc.documentClssIndex].docList.push(
              uploadedDoc.doc
            )
        }
      }

      this.updateUploadedDocs(clients)
    } else {
      const clients = this.state.formContext.formData.Application.clients.slice()
      const client = clients[uploadedDoc.clientIndex]
      const docList =
        client.myDocumentsChildCare &&
        client.myDocumentsChildCare[uploadedDoc.documentClssIndex].docList
      if (!client.myDocumentsChildCare) {
        client.myDocumentsChildCare = JSON.parse(
          JSON.stringify(
            this.state.application['forms'][STEPS.DOCUMENT_UPLOAD - 1]
              .myDocsSchema
          )
        )
      }
      if (client.myDocumentsChildCare) {
        client.myDocumentsChildCare &&
          client.myDocumentsChildCare[
            uploadedDoc.documentClssIndex
          ].docList.push(uploadedDoc.doc)
      }
      this.updateUploadedDocs(clients)
    }
  }

  _onUploadDelete = (deletedDoc: any) => {
    if (deletedDoc.pType === 'myDocuments') {
      const clients = this.state.formContext.formData.Application.clients.slice()
      for (let i = 0; i < clients.length; i++) {
        const client = clients[i]
        const clientName =
          client.clientFstNm +
          (client.clientLstNm ? ' ' + client.clientLstNm : '')
        if (deletedDoc.clientName === clientName) {
          client.myDocuments[deletedDoc.documentClssIndex].docList.splice(
            deletedDoc.docIndex,
            1
          )
          break
        }
      }

      this.updateUploadedDocs(clients)
    } else {
      const clients = this.state.formContext.formData.Application.clients.slice()
      for (let i = 0; i < clients.length; i++) {
        const client = clients[i]
        const clientName =
          client.clientFstNm +
          (client.clientLstNm ? ' ' + client.clientLstNm : '')
        if (deletedDoc.clientName === clientName) {
          client.myDocumentsChildCare[0].docList.splice(deletedDoc.docIndex, 1)
          break
        }
      }

      this.updateUploadedDocs(clients)
    }
  }

  _updateTabs = (prevProps, prevState) => {
    const { tabs, forms, formContext } = this.state

    if (!_.isEmpty(tabs)) {
      let newTabs = tabs.map(element => {
        let isVisible
        if (typeof element['visibleIf'] === 'boolean') {
          isVisible = element['visibleIf']
        } else {
          isVisible = false
          if (!_.isEmpty(this.props.myApplicationData)) {
            try {
              var f = new Function(
                'formData',
                'return (' +
                  helper.resolveStringRefs(
                    element['visibleIf'],
                    formContext,
                    'root',
                    null,
                    null,
                    null
                  ) +
                  ')'
              )
              isVisible = f(this.props.myApplicationData)
            } catch (err) {
              console.error(
                'Error in AppIntake tabs visibleIf check',
                element['visibleIf'],
                err
              )
            }
          }
        }
        return {
          ...element,
          isVisible
        }
      })
      if (!_.isEqual(newTabs, prevState.tabs)) {
        this.setState({ tabs: newTabs })
      }
      const newTabStatus = this._determineTabStatus(newTabs)
      if (!_.isEqual(newTabStatus, this.props.tabStatus)) {
        this.props.updateMyApplicationTabStatus(
          this.props.activeApplicationId,
          newTabStatus
        )
      }
    }
  }

  _onConfirmMessage = () => {
    this.setState({ showDraftModal: false })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  onFocus = e => {
    if (e.which === 9 && !this.state.tabFocus) {
      this.setState({ tabFocus: true })
    } else if (e.which === 1 && this.state.tabFocus) {
      this.setState({ tabFocus: false })
    }
  }

  _onUpdateCarouselSlides = (slideIndex, slides) => {
    let oldSlidesLength = this.state.slides.length
    let newSlidesLength = slides.length
    let showNext = this.state.showNext
    let oldSlideIndex = this.state.slideIndex

    const { formContext } = this.state
    const { formData } = formContext

    if (
      newSlidesLength > 0 &&
      oldSlidesLength !== newSlidesLength &&
      this.props.step === 1 &&
      Object.keys(formData).length > 0
    ) {
      this.setState({ slideIndex, slides: _.cloneDeep(slides) })
    }
    if (
      oldSlideIndex !== slideIndex &&
      this.props.step === 1 &&
      Object.keys(formData).length > 0
    ) {
      this.setState({ slideIndex })
    }
    if (this.state.formContext.slideIndex) {
      this.setState(prevState => {
        return {
          ...prevState,
          formContext: {
            ...prevState.formContext,
            slideIndex: null
          }
        }
      })
    }
  }

  _backendValidate = checkValue => {
    let maidNum = checkValue
    if (maidNum.length === 11) {
      const endpoint = MAIDValidateEndpoint.replace('{MAID}', maidNum)
      serviceHelper
        .fetchJson(
          endpoint,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              roleId: this.props.roleId,
              orgId: this.props.orgId
            }
          },
          this.props.showErrorMessage
        )
        .then(json => {
          this.setState(prevState => {
            return {
              ...prevState,
              formContext: {
                ...prevState.formContext,
                isTempMaidValid: json,
                isTempMaidIdValidated: true
              }
            }
          })
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          return ex
        })
    } else {
      return false
    }
  }

  _programService = () => {
    const { activeApplicationId } = this.props
    this.props.updateMyApplicationStep(activeApplicationId, 1)
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }

    const { presentation, step, roleName, serviceErrors } = this.props
    const {
      forms,
      formContext,
      slideIndex,
      slides,
      citizenShipOrChildSupportStep
    } = this.state
    let validator = null
    if (forms && step) {
      validator = new CustomValidator(
        forms[step - 1].schema,
        forms[step - 1].uiSchema,
        formContext
      )
    }
    let newformContext = {
      ...formContext,
      roleName: roleName
    }

    const presentationProps = {
      slideIndex,
      slides,
      STEPS,
      ..._.omit(this.props, 'presentation'),
      ...this.state,
      formContext: newformContext,
      validator,
      citizenShipOrChildSupportStep,
      onPrevious: this._onPrevious,
      saveFormDataDraft: this._saveFormDataDraft,
      exitFromApplication: this._exitFromApplication,
      changeView: this._changeView,
      onDocUpload: this._onDocUpload,
      onUploadDelete: this._onUploadDelete,
      onNext: this._onNext,
      onSubmit: this._onSubmit,
      errorMessage: this.state.errorMessage,
      onFormDataChange: this._onFormDataChange,
      onValidate: this._onValidate,
      errorListTemplate: this._errorListTemplate,
      onError: this._onError,
      transformErrors: this._transformErrors,
      saveContributor: this._saveContributor,
      cancelContributor: this._cancelContributor,
      onConfirmMessage: this._onConfirmMessage,
      setRedirect: this._setRedirect,
      submitFormData: this._submitFormData,
      submitIdentityProof: this.submitIdentityProof,
      closeSubmissionModal: this.closeSubmissionModal,
      backendValidate: this._backendValidate,
      programService: this._programService,
      serviceErrors,
      onDeleteClick: this._onDeleteClick,
      hasError: this.hasError,
      showErrorMessage: this.props.showErrorMessage,
      switchOnLiveValidate: this._switchOnLiveValidate.bind(this),
      submitClicked: this._submitClicked.bind(this),
      exitDomesticViolenceModal: this._exitDomesticViolenceModal
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let activeApplicationId = '0'
  let myApplicationData = {}
  let step = 1
  let tabStatus = []
  if (state.myApplications) {
    activeApplicationId = state.myApplications.activeApplicationId || '0'

    if (
      state.myApplications.applicationsArray &&
      state.myApplications.applicationsArray.length > 0
    ) {
      const myApplication = _.find(
        state.myApplications.applicationsArray,
        function(element) {
          return element['applId'] === activeApplicationId
        }
      )
      if (myApplication) {
        myApplicationData = myApplication['applicationData'] || {}
        step = myApplication['step'] || 1
        tabStatus = myApplication['tabStatus'] || []
      }
    }
  }
  let saveMAID = state.peApplications.saveMAID
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let roleId
  let roleName
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleName = _.get(state.userAccess, 'selectedOrg.roleName') || ''
  } else {
    roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''
  }
  const serviceErrors = state.myMessagesError.myMessagesError.errorMessage || []
  let userId = _.get(state.auth, 'userAccount.userId') || ''

  // let ieOrgId = _.get(state.userAccess, 'userRoles[0].organization.orgId') || ''
  let userAccess = _.get(state, 'userAccess') || ''
  let userRoleIdOrgIdList = _.get(state, 'userAccess.userRoleIdOrgIdList') || []
  let ieOrgId, userRoleId
  let currentuserRoleIdOrgIdRecord = _.find(userRoleIdOrgIdList, current => {
    return (
      _.get(current, 'orgId') ===
        _.get(state.userAccess, 'selectedOrg.ieOrgId') &&
      _.get(current, 'rolename') === roleName
    )
  })
  ieOrgId = _.get(currentuserRoleIdOrgIdRecord, 'orgId', '')
  userRoleId = _.get(currentuserRoleIdOrgIdRecord, 'userRoleId', '')
  if (!userRoleId) {
    userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  }
  let selectedEntitlements = _.get(
    state.userAccess,
    'selectedUserRole.entitlements',
    []
  ).map(element => element.entitlementName)
  return {
    auth: state.auth,
    orgId,
    userRoleId,
    userId,
    roleId,
    saveMAID,
    roleName,
    activeApplicationId,
    myApplicationData,
    step,
    tabStatus,
    selectedEntitlements,
    serviceErrors,
    ieOrgId,
    locale: state.i18n.locale
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addMyApplication: (applId, status, applicationData) => {
      dispatch(actions.addMyApplication(applId, status, applicationData))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    removeMyApplication: applId => {
      dispatch(actions.removeMyApplication(applId))
    },
    updateMyApplication: (applId, status, applicationData) => {
      dispatch(actions.updateMyApplication(applId, status, applicationData))
    },
    setActiveApplicationId: applId => {
      dispatch(actions.setActiveApplicationId(applId))
    },
    assignApplicationId: applId => {
      dispatch(actions.assignApplicationId(applId))
    },
    updateMyApplicationStep: (applId, step) => {
      dispatch(actions.updateMyApplicationStep(applId, step))
    },
    updateMyApplicationTabStatus: (applId, tabStatus) => {
      dispatch(actions.updateMyApplicationTabStatus(applId, tabStatus))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppIntakeContainer)
