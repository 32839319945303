import * as React from 'react'
import * as _ from 'lodash'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { Tabs, Tab } from 'react-bootstrap'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
const moment = require('moment-mini-ts')
import { Link } from 'react-router-dom'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'

declare const process
const baseUrl = process.env.PUBLIC_URL
const Fragment = (React as any).Fragment

interface MyBenefitsPresentationProps {
  formData: any
  formContext: any
  pinCheck: boolean
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  onFormDataChange: (formData: any) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  errorMessage: Array<any>
  onDeleteClick: () => void
  oldFormData: any
  selectedBenefits: any
  currentDate: any
  programType?: string
}

function MyBenefitsHeader(props) {
  return (
    <div className="row">
      <p className="benefit-header1">
        <span className="pe-7s-gift" />
        {I18n.t('MyBenefits.title')}
      </p>
    </div>
  )
}

// function checkForYear(item) {
//   console.log('My benefit presentation ---')
//   let benefitMonth = moment(item.benefitMonth, 'MMM YYYY')
//   let currentDt = _moment(config, this.props.currentDate)
//   let resultArr = currentDt.diff(benefitMonth, 'days')
//   return resultArr <= 366
// }

export default function MyBenefitsPresentation(
  props: MyBenefitsPresentationProps
) {
  const {
    formContext,
    forms,
    onFormDataChange,
    formComponents,
    pinCheck,
    onDeleteClick,
    errorMessage,
    oldFormData,
    selectedBenefits,
    currentDate,
    programType
  } = props
  let { formData } = props

  let formData2 = []
  if (formData && formData.length > 0 && _.isArray(formData[0])) {
    for (let i = 0; i < formData[0].length; i++) {
      for (let k = 0; k < formData[0][i].length; k++) {
        if (formData[0][i][k].renewalIndicator === 'Y') {
          formData2.push(formData[0][i][k])
        }
      }
    }
  } else if (
    formData &&
    formData.length > 0 &&
    _.isArray(formData[0] && formData[0].benefits)
  ) {
    formData2 = _.filter(formData[0].benefits, ['renewalIndicator', 'Y'])
  } else if (
    formData &&
    formData.length > 0 &&
    _.isArray(formData[0] && formData[0].benefitsSecond)
  ) {
    formData2 = _.filter(formData[0].benefitsSecond, ['renewalIndicator', 'Y'])
  } else if (
    formData &&
    formData.length > 0 &&
    _.isArray(formData[0] && formData[0].benefitsThird)
  ) {
    formData2 = _.filter(formData[0].benefitsThird, ['renewalIndicator', 'Y'])
  } else if (
    formData &&
    formData.length > 0 &&
    _.isArray(formData[0] && formData[0].benefitsFourth)
  ) {
    formData2 = _.filter(formData[0].benefitsFourth, ['renewalIndicator', 'Y'])
  }

  const filtered1FormData = { benefits: formData2 || [] }
  const filtered2FormData = _.map(filtered1FormData.benefits, benefit => {
    switch (benefit.renewalStatus) {
      case 'Renewal Pending': {
        benefit.renewalStatus = 'Not Started'
        break
      }
      case 'Renewal in Progress': {
        benefit.renewalStatus = 'Pending'
        break
      }
      case 'Submitted': {
        benefit.renewalStatus = 'Submitted'
        break
      }
    }
    return {
      action: benefit.action,
      caseNumber: benefit.caseNumber,
      benefitProgramName: benefit.benefitProgramName,
      renewalIndicator: benefit.renewalIndicator,
      reviewIndicator: benefit.reviewIndicator,
      renewByDate: moment(benefit.renewByDate).format('MM/DD/YYYY'),
      renewalStatus: benefit.renewalStatus,
      applId: benefit.applId,
      otherBenefitProgramName: benefit.otherBenefitProgramName,
      programCodeDescSummary: benefit.programCodeDescSummary,
      prgmCode: benefit.benefitProgramCode
    }
  })

  const filteredFormData = { benefits: filtered2FormData || [] }
  let snapBenefitFormData = []
  let otherBenefitFormData = []
  let childCareBenefits = []
  let snapPRC2Benefits = []
  if (filtered2FormData && filtered2FormData.length > 0) {
    for (let i = 0; i < filtered2FormData.length; i++) {
      if (
        filtered2FormData[i].benefitProgramName === 'SNAP' ||
        filtered2FormData[i].benefitProgramName === 'Health Care Benefits' ||
        filtered2FormData[i].benefitProgramName ===
          'Medicare Premium Assistance' ||
        _.includes(
          _.toLower(filtered2FormData[i].benefitProgramName),
          'wv works'
        )
      ) {
        snapBenefitFormData.push(filtered2FormData[i])
      } else if (
        filtered2FormData[i].benefitProgramName === 'Child Care' ||
        filtered2FormData[i].benefitProgramName === 'Child Care - CCA'
      ) {
        childCareBenefits.push(filtered2FormData[i])
      } else if (
        filtered2FormData[i].benefitProgramName === 'SNAP PRC2' ||
        filtered2FormData[i].benefitProgramName === 'SNAP PRC2 - FS P'
      ) {
        snapPRC2Benefits.push(filtered2FormData[i])
      } else {
        otherBenefitFormData.push(filtered2FormData[i])
      }
    }
  }

  if (otherBenefitFormData.length > 0) {
    otherBenefitFormData = otherBenefitFormData.map(ele => {
      return {
        ...ele,
        benefitProgramName:
          ele.benefitProgramName +
          ' - ' +
          (ele.otherBenefitProgramName
            ? ele.otherBenefitProgramName
            : ele.prgmCode)
      }
    })
  }
  if (childCareBenefits.length > 0) {
    childCareBenefits = childCareBenefits.map(ele => {
      return {
        ...ele,
        benefitProgramName: 'Child Care - CCA'
      }
    })
  }

  if (snapBenefitFormData.length > 0) {
    snapBenefitFormData = snapBenefitFormData.map(ele => {
      return {
        ...ele,
        benefitProgramName:
          ele.benefitProgramName +
          ' - ' +
          (ele.programCodeDescSummary
            ? ele.programCodeDescSummary
            : ele.prgmCode)
      }
    })
  }

  if (snapPRC2Benefits.length > 0) {
    snapPRC2Benefits = snapPRC2Benefits.map(ele => {
      return {
        ...ele,
        benefitProgramName: 'SNAP - Food Stamp- 6- or 12-Month Contact Form'
      }
    })
  }

  const showRenewalsMessage =
    (snapBenefitFormData.length > 0 || otherBenefitFormData.length > 0) &&
    childCareBenefits.length > 0
  const snapBenefitFormData2 = { benefits: snapBenefitFormData || [] }
  const otherBenefitFormData2 = { benefitsSecond: otherBenefitFormData || [] }
  const childCareBenefits2 = { benefitsThird: childCareBenefits }
  const snapPRC2Benefits2 = { benefitsFourth: snapPRC2Benefits }
  if (formData && formData.length > 0) {
    if (formData && _.isArray(formData[1])) {
      formData[1] = formData[1].map(element => {
        if (element.benefitAmount != undefined) {
          return {
            ...element,
            benefitAmount: (+element.benefitAmount).toFixed(2)
          }
        } else {
          return element
        }
      })
      var filterFormData = formData[1].filter(function(item) {
        let benefitMonth = moment(item.benefitMonth, 'MMM YYYY')
        let currentDt = _moment(config, props.currentDate)
        let resultArr = currentDt.diff(benefitMonth, 'days')
        return resultArr <= 366
      })
    }
  }

  let startRenewal
  let startRenewalText
  let linkDisabled = true
  let caseNumber = -1
  let reviewIndicator = ''
  let renewalStatus = ''
  let applId = 0
  let applId1 = '0'
  let benefitProgramName = ''
  let programNamesString
  let programCodeString
  let updatedProgamNames
  let updatedProgramCode
  let CCA = false
  let snapPRC2 = false

  if (
    formContext &&
    _.isArray(formContext.formData) &&
    formContext.formData.length > 0 &&
    formContext.formData[0] &&
    formContext.formData[0][0] &&
    formContext.formData[0][0][0] &&
    formContext.formData[0][0][0].action &&
    formContext.formData[0][0][0].action === 'true'
  ) {
    const programNames = formContext.formData[0][0].map(program => {
      if (program.renewalIndicator === 'Y') {
        return program.benefitProgramName
      }
    })
    const programCodes = formContext.formData[0][0].map(program => {
      if (program.renewalIndicator === 'Y') {
        return program.benefitProgramCode
      }
    })
    updatedProgamNames = programNames.filter(function(ele) {
      return ele !== undefined
    })
    updatedProgramCode = programCodes.filter(function(ele) {
      return ele !== undefined
    })
    programNamesString = updatedProgamNames.join(',')
    programCodeString = updatedProgramCode.join(',')
  } else if (
    formContext &&
    formContext.formData &&
    formContext.formData.length > 0 &&
    formContext.formData[0][1] &&
    formContext.formData[0][1].find(program => {
      return program.action && program.action === 'true'
    })
  ) {
    const otherProgramNames = formContext.formData[0][1].find(program => {
      return program.action && program.action === 'true'
    })

    programNamesString =
      otherProgramNames.benefitProgramName +
      '-' +
      otherProgramNames.otherBenefitProgramName
    programCodeString = otherProgramNames.benefitProgramCode
  }

  let flag = true
  if (
    snapBenefitFormData2 &&
    snapBenefitFormData2.benefits &&
    snapBenefitFormData2.benefits.length > 0
  ) {
    _.map(snapBenefitFormData2, benefit => {
      benefit = benefit.filter(bnfObj => {
        return bnfObj.action === 'true'
      })
      if (benefit && benefit.length > 0 && benefit[0].action) {
        flag = false
        reviewIndicator = benefit[0].reviewIndicator
        caseNumber = benefit[0].caseNumber
        linkDisabled = false
        applId =
          benefit[0].applId && benefit[0].applId.length > 3
            ? benefit[0].applId
            : undefined
        applId1 = benefit[0].applId
        renewalStatus = benefit[0].renewalStatus
      }
    })
  }
  if (
    flag &&
    otherBenefitFormData2 &&
    otherBenefitFormData2.benefitsSecond &&
    otherBenefitFormData2.benefitsSecond.length > 0
  ) {
    _.map(otherBenefitFormData2, benefit => {
      benefit.filter(bnfObj => {
        return bnfObj.action === 'true'
      })
      if (benefit && benefit.length > 0 && benefit[0].action) {
        flag = false
        reviewIndicator = benefit[0].reviewIndicator
        caseNumber = benefit[0].caseNumber
        linkDisabled = false
        applId =
          benefit[0].applId && benefit[0].applId.length > 3
            ? benefit[0].applId
            : undefined
        applId1 = benefit[0].applId
        renewalStatus = benefit[0].renewalStatus
      }
    })
  }
  if (
    flag &&
    childCareBenefits2 &&
    childCareBenefits2.benefitsThird &&
    childCareBenefits2.benefitsThird.length > 0
  ) {
    CCA = true
    _.map(childCareBenefits2, benefit => {
      benefit.filter(bnfObj => {
        return bnfObj.action === 'true'
      })
      if (benefit && benefit.length > 0 && benefit[0].action) {
        reviewIndicator = benefit[0].reviewIndicator
        caseNumber = benefit[0].caseNumber
        linkDisabled = false
        applId =
          benefit[0].applId && benefit[0].applId.length > 3
            ? benefit[0].applId
            : undefined
        applId1 = benefit[0].applId
        renewalStatus = benefit[0].renewalStatus
      }
    })
  }

  if (
    flag &&
    snapPRC2Benefits2 &&
    snapPRC2Benefits2.benefitsFourth &&
    snapPRC2Benefits2.benefitsFourth.length > 0
  ) {
    snapPRC2 = true
    _.map(snapPRC2Benefits2, benefit => {
      benefit.filter(bnfObj => {
        return bnfObj.action === 'true'
      })
      if (benefit && benefit.length > 0 && benefit[0].action) {
        reviewIndicator = benefit[0].reviewIndicator
        caseNumber = benefit[0].caseNumber
        linkDisabled = false
        applId =
          benefit[0].applId && benefit[0].applId.length > 3
            ? benefit[0].applId
            : undefined
        applId1 = benefit[0].applId
        renewalStatus = benefit[0].renewalStatus
      }
    })
  }

  if (
    caseNumber > 1 &&
    applId === undefined &&
    renewalStatus === 'Not Started'
  ) {
    startRenewalText = (
      <Fragment>
        <p>{I18n.t('MyBenefits.startRenewalText')}</p>
      </Fragment>
    )
    startRenewal = (
      <Fragment>
        <Link
          to={
            baseUrl +
            `/${
              CCA ? 'renewal-child-care' : snapPRC2 ? 'renewal-prc2' : 'renewal'
            }?caseId=` +
            caseNumber +
            '&reviewIndicator=' +
            reviewIndicator +
            '&renewalStatus=' +
            renewalStatus +
            '&applId=' +
            applId +
            '&benefitProgramName=' +
            programNamesString +
            '&prgNames=' +
            programNamesString +
            '&programCode=' +
            programCodeString +
            '&showRenewalMessage=' +
            showRenewalsMessage
          }
          className="btn btn-primary"
          id="myBenefits_startRenewal"
        >
          {I18n.t('MyBenefits.startRenewal')}
        </Link>
      </Fragment>
    )
  } else if (caseNumber > 1 && applId && renewalStatus === 'Not Started') {
    startRenewalText = (
      <Fragment>
        <p>{I18n.t('MyBenefits.startRenewalText')}</p>
      </Fragment>
    )
    startRenewal = (
      <Fragment>
        <Link
          to={
            baseUrl +
            `/${
              CCA ? 'renewal-child-care' : snapPRC2 ? 'renewal-prc2' : 'renewal'
            }?caseId=` +
            caseNumber +
            '&reviewIndicator=' +
            reviewIndicator +
            '&renewalStatus=' +
            renewalStatus +
            '&applId=' +
            applId +
            '&benefitProgramName=' +
            programNamesString +
            '&prgNames=' +
            programNamesString +
            '&programCode=' +
            programCodeString +
            '&showRenewalMessage=' +
            showRenewalsMessage
          }
          className="btn btn-primary"
          id="myBenefits_startRenewal"
        >
          {I18n.t('MyBenefits.startRenewal')}
        </Link>
      </Fragment>
    )
  } else if (caseNumber > 1 && applId > 0 && renewalStatus === 'Pending') {
    startRenewalText = (
      <Fragment>
        <p>{I18n.t('MyBenefits.startRenewalText')}</p>
      </Fragment>
    )
    startRenewal = (
      <Fragment>
        <Link
          to={
            baseUrl +
            `/${
              CCA ? 'renewal-child-care' : snapPRC2 ? 'renewal-prc2' : 'renewal'
            }?caseId=` +
            caseNumber +
            '&reviewIndicator=' +
            reviewIndicator +
            '&renewalStatus=' +
            renewalStatus +
            '&applId=' +
            applId +
            '&benefitProgramName=' +
            programNamesString +
            '&prgNames=' +
            programNamesString +
            '&programCode=' +
            programCodeString +
            '&showRenewalMessage=' +
            showRenewalsMessage
          }
          className="btn btn-primary"
        >
          {I18n.t('myBenefits.inProgress')}
        </Link>
        <p />
        <p> {I18n.t('MyBenefits.continueExisting')}</p>
      </Fragment>
    )
  } else if (
    caseNumber > 1 &&
    applId === undefined &&
    renewalStatus === 'Pending'
  ) {
    if (
      applId1 != '0' &&
      applId1 !== undefined &&
      applId1 != null &&
      applId1 !== ''
    ) {
      startRenewalText = (
        <Fragment>
          <p>{I18n.t('MyBenefits.startRenewalText')}</p>
        </Fragment>
      )
      startRenewal = (
        <Fragment>
          <Link
            to={
              baseUrl +
              `${
                CCA
                  ? '/renewal-child-care'
                  : snapPRC2
                  ? 'renewal-prc2'
                  : '/renewal'
              }?applId=` +
              applId1 +
              '&showRenewalMessage=' +
              showRenewalsMessage
            }
            className="btn btn-primary"
          >
            {I18n.t('MyBenefits.inProgress')}
          </Link>
          <p />
          <p> {I18n.t('MyBenefits.alreadyStarted')}</p>
        </Fragment>
      )
    } else {
      startRenewalText = (
        <Fragment>
          <p>{I18n.t('MyBenefits.startRenewalText')}</p>
        </Fragment>
      )
      startRenewal = (
        <Fragment>
          <button className="btn btn-secondary">
            {' '}
            {I18n.t('MyBenefits.inProgress')}
          </button>
          <p />
          <p>{I18n.t('MyBenefits.alreadyStarted')}</p>
        </Fragment>
      )
    }
  } else if (renewalStatus === 'Submitted') {
    return (
      <Fragment>
        <p> {I18n.t('MyBenefits.renewed')} </p>
      </Fragment>
    )
  } else {
    startRenewalText = (
      <Fragment>
        <p>{I18n.t('MyBenefits.startRenewalText')}</p>
      </Fragment>
    )
    startRenewal = (
      <Fragment>
        <button className="btn btn-secondary">Start Renewal</button>
      </Fragment>
    )
  }

  if (forms && !_.isEmpty(forms) && formData) {
    formData = formDataCompare(formData, oldFormData)
    return (
      <div className="form-section col-lg-12">
        <MyBenefitsHeader />
        <Tabs id="my-benefits-nav-tabs" defaultActiveKey={0}>
          <Tab eventKey="0" title={I18n.t('MyBenefits.benefitSummary')}>
            {formData[0] && formData[0].length > 0 ? (
              <div>
                <p>
                  <br />
                  {I18n.t('MyBenefits.ifDue')}
                </p>
                <Form
                  schema={forms[3]['schema']}
                  uiSchema={forms[3]['uiSchema']}
                  formData={formData[3]}
                  formContext={formContext}
                  onChange={onFormDataChange}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                >
                  <input type="submit" style={{ display: 'none' }} />
                </Form>
              </div>
            ) : (
              <p> {I18n.t('MyBenefits.noRenewal')}</p>
            )}
          </Tab>
          <Tab eventKey="1" title={I18n.t('MyBenefits.benefitDetails')}>
            {pinCheck && formData[2] && formData[2].length > 0 ? (
              <Form
                schema={forms[0]['schema']}
                uiSchema={forms[0]['uiSchema']}
                formData={formData[2]}
                formContext={formContext}
                onChange={onFormDataChange}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                FieldTemplate={formComponents.FieldTemplate}
              >
                <input type="submit" style={{ display: 'none' }} />
              </Form>
            ) : (
              <p> {I18n.t('MyBenefits.noServices')}</p>
            )}
          </Tab>
          <Tab eventKey="2" title={I18n.t('MyBenefits.benefitRenewal')}>
            {formData2 && formData2.length > 0 ? (
              <div>
                {startRenewalText}
                {(snapBenefitFormData2.benefits.length > 0 ||
                  otherBenefitFormData2.benefitsSecond.length > 0) &&
                  _.find(childCareBenefits2.benefitsThird, b => {
                    return b.action === 'true'
                  }) && (
                    <p>
                      You have selected the Child Care renewal.  To complete the
                      Family Assistance renewal, you must return to the Benefit
                      Renewal tab and complete a separate renewal submission.
                    </p>
                  )}
                {childCareBenefits2.benefitsThird.length > 0 &&
                  (_.find(snapBenefitFormData2.benefits, b => {
                    return b.action === 'true'
                  }) ||
                    _.find(otherBenefitFormData2.benefitsSecond, b => {
                      return b.action === 'true'
                    })) && (
                    <p>
                      You have selected the Family Assistance renewal.  To
                      complete the Child Care renewal, you must return to the
                      Benefit Renewal tab and complete a separate renewal
                      submission.
                    </p>
                  )}
                <Form
                  schema={forms[1]['schema']}
                  uiSchema={forms[1]['uiSchema']}
                  formData={snapBenefitFormData2}
                  formContext={formContext}
                  onChange={onFormDataChange}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                >
                  <input type="submit" style={{ display: 'none' }} />
                </Form>
                <Form
                  schema={forms[4]['schema']}
                  uiSchema={forms[4]['uiSchema']}
                  formData={otherBenefitFormData2}
                  formContext={formContext}
                  onChange={onFormDataChange}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                >
                  <input type="submit" style={{ display: 'none' }} />
                </Form>
                <Form
                  schema={forms[5]['schema']}
                  uiSchema={forms[5]['uiSchema']}
                  formData={childCareBenefits2}
                  formContext={formContext}
                  onChange={onFormDataChange}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                >
                  <input type="submit" style={{ display: 'none' }} />
                </Form>
                <Form
                  schema={forms[6]['schema']}
                  uiSchema={forms[6]['uiSchema']}
                  formData={snapPRC2Benefits2}
                  formContext={formContext}
                  onChange={onFormDataChange}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                >
                  <input type="submit" style={{ display: 'none' }} />
                </Form>
                <div>{startRenewal}</div>
              </div>
            ) : (
              <p>{I18n.t('MyBenefits.noRenewal')}</p>
            )}
          </Tab>
          <Tab eventKey="3" title={I18n.t('MyBenefits.benefitsHistory')}>
            {Array.isArray(filterFormData) && filterFormData.length > 0 ? (
              <Form
                schema={forms[2]['schema']}
                uiSchema={forms[2]['uiSchema']}
                formData={filterFormData}
                formContext={formContext}
                onChange={onFormDataChange}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                FieldTemplate={formComponents.FieldTemplate}
              >
                <input type="submit" style={{ display: 'none' }} />
              </Form>
            ) : (
              <p>{I18n.t('MyBenefits.noIssuedBenefits')}</p>
            )}
          </Tab>
        </Tabs>
      </div>
    )
  }
  return (
    <div>
      <ScreenPreLoader loaded={false} />
      <ErrorModal
        errorMessage={props.errorMessage}
        onDeleteClick={props.onDeleteClick}
      />
    </div>
  )
}

function formDataCompare(formData, oldFormData) {
  if (_.isEqual(formData, oldFormData)) {
    return formData
  } else {
    return oldFormData
  }
}
