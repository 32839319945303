import * as React from 'react'
import { Link } from 'react-router-dom'
import { imageMap } from '~/images'
import { get, find } from 'lodash'
import { config } from '~/config'
import { I18n } from 'react-redux-i18n'
import AccountMenu from './AccountMenu'
import LocaleMenu from './LocaleMenu'

const _ = { get, find }

declare const process
const baseUrl = process.env.PUBLIC_URL

const PARENT_ID = 'Header_account_link'

interface Entitlement {
  entitlementId: number
  entitlementName: string
  entitlementDescription: string
}

interface UserRole {
  userRoleId: number
  uuid: string
  role: {
    roleId: number
    multilingualDisplayName: {
      en: string
      es: string
    }
    description: {
      en: string
      es: string
    }
    tenantId: number
    status: string
    startDate: string
    endDate: string
  }
  entitlements: Array<Entitlement>
  orgList: Array<orgObj>
}

interface orgObj {
  roleId: number
  roleName: string
  orgId: number
  orgName: string
  roleorgId: number
  dfltflg: string
}

interface HeaderProps {
  isLoggedIn: boolean
  userAccount: {
    uuid: string
    firstName: string
    defaultUserRoleId?: number
  }
  userAccess: {
    userRoles: Array<UserRole>
    selectedUserRole: UserRole
    selectedOrg: orgObj
    orgList: Array<orgObj>
  }
  logoutUser: () => void
  fakeLogin: () => void
  setSelectedUserRole: (selectedUserRole: UserRole) => void
  setSelectedOrg: (selectedOrg: orgObj) => void
  locale: string
  auth: any
  setLocale: any
}

export default function Header(props: HeaderProps) {
  const {
    isLoggedIn,
    userAccount,
    userAccess,
    logoutUser,
    fakeLogin,
    setSelectedUserRole,
    setSelectedOrg,
    locale,
    auth,
    setLocale
  } = props

  function focusMainContent() {
    const mainContent = document.getElementById('content')
    if (mainContent) {
      mainContent.focus()
    }
  }

  let envName,
    envNameArr = [
      'LOCAL',
      'DEV',
      'TEST',
      'STAGE',
      'OFEDEV',
      'OFETST',
      'OFEUAT',
      'SIT',
      'TRN',
      'OFESIT',
      'OFESTG',
      'OFEMNO',
      'OFEPRF',
      'OFEPROD'
    ]
  let resultConfigEnvName = _.find(envNameArr, function(el, i) {
    return el === config.environment
  })
  if (resultConfigEnvName != 'OFEPROD') {
    envName = (
      <h1 className="headerText nav navbar-nav headerStyle searchStyle">
        {resultConfigEnvName}
      </h1>
    )
  } else {
    envName = (
      <h1 className="headerTextProd nav navbar-nav headerStyle searchStyle">
        OFEPROD
      </h1>
    )
  }

  return (
    <header>
      <div className="navbar" id="nav-content">
        <a
          href="#content"
          className="skip"
          onClick={() => {
            focusMainContent()
          }}
        >
          {I18n.t('General.skipToMain')}
        </a>
        <div className="container-fluid header-content">
          <div>
            <div className="navbar-header margin-left-minus-15 float-left">
              <Link className="navbar-brand" to={baseUrl + '/'}>
                <img
                  src={imageMap[config.tenant.code].CP_Logo}
                  className="img-fluid navbar-logo"
                  aria-hidden="true"
                  alt=""
                />
                <span className="sr-only">
                  West Virginia Department of Health & Human Resources Logo
                </span>
              </Link>
            </div>
            <nav id="#navbar-collapse" className="navbar-collapse">
              <ul className="nav navbar-nav" style={{ marginTop: '5px' }}>
                <li className="margin-left-15px">
                  <Link to={baseUrl + '/'} className="home-link">
                    {I18n.t('Header.homeLink')}
                  </Link>
                </li>
                <li className="nav">
                  <span>{envName}</span>
                </li>
              </ul>
            </nav>
          </div>
          <nav>
            <ul className="nav navbar-nav-sign navbar-right">
              <li>
                <LocaleMenu locale={locale} setLocale={setLocale} />
              </li>
              <li className="margin-yl-minus-10px">
                {isLoggedIn ? (
                  <AccountMenu
                    userAccount={userAccount}
                    userAccess={userAccess}
                    locale={locale}
                    setSelectedUserRole={setSelectedUserRole}
                    setSelectedOrg={setSelectedOrg}
                    logoutUser={logoutUser}
                    parent={PARENT_ID}
                    auth={auth}
                  />
                ) : process.env.NODE_ENV === 'production' ? (
                  <Link
                    id={PARENT_ID}
                    className="sign-link-style"
                    to={baseUrl + '/login'}
                  >
                    {I18n.t('Header.signInLink')}
                  </Link>
                ) : (
                  <button
                    id={PARENT_ID}
                    onClick={fakeLogin}
                    className="sign-in-button sign-in-font"
                  >
                    {I18n.t('Header.signInLink')}
                  </button>
                )}
              </li>
              <li className="divider-vertical">
                <Link to={baseUrl + '/help'}>{I18n.t('Header.helpLink')}</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}
