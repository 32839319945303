import * as React from 'react'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import '!style-loader!css-loader!../src/customStyles.css'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config' // endpoints
import * as actions from '../../../actions'

const baseUrl = process.env.PUBLIC_URL

export function SiteMap(props) {
  document.title = 'Site Map - IE Client Portal'
  let helpPopover = (
    <Popover id="helpPopover">
      <a href="/help" target="_blank">
        Help Center
      </a>
    </Popover>
  )

  let helpLink = (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={helpPopover}
    >
      <button className="btn btn-tiny btn-white">
        <span className="fa fa-life-ring btn-icon-sm" />
        Help
      </button>
    </OverlayTrigger>
  )
  return (
    <div>
      <div id="content" className="col-12">
        <div className="header-view">
          <p className="heading">
            <i className="fa fa-sitemap btn-icon-md" /> Site Map
          </p>
          <div className="header-sub" />
        </div>
        <div className="siteMapBelowHeader">
          <div className="float-right margin-left-auto">
            {helpLink}
            <button
              className="printStyle print btn btn-tiny btn-white"
              onClick={function() {
                window.print()
              }}
            >
              <span className="fa fa-print btn-icon-sm" />
              Print
            </button>
          </div>
        </div>
        <h5 className="siteMapHeaderText">
          WVDoHS - West Virginia Department of Health & Human Resources
        </h5>
        <div>
          <ul className="siteMapText">
            <li>
              <Link to={baseUrl + '/benefitsfinderWV'}>
                Screen for Assistance
              </Link>
            </li>
            <li>
              <Link to={baseUrl + '/programs&services'}>
                Programs & Services
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: baseUrl + '/programs&services',
                  state: { tabKey: 'health-wellness' }
                }}
              >
                Programs & Services/ Health Care
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: baseUrl + '/programs&services',
                  state: { tabKey: 'family-assistance' }
                }}
              >
                Programs & Services/ Family Assistance
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: baseUrl + '/programs&services',
                  state: { tabKey: 'food-nutrition' }
                }}
              >
                Programs & Services/ Food & Nutrition
              </Link>
            </li>
            {config['preGoLiveTitlesVisibile'] && (
              <li>
                <Link
                  to={
                    props.isLoggedIn
                      ? baseUrl + '/application'
                      : baseUrl + '/login'
                  }
                >
                  Apply Now
                </Link>
              </li>
            )}
            {config['preGoLiveTitlesVisibile'] && (
              <li>
                <Link to={baseUrl + '/myaccount'}>My Account</Link>
              </li>
            )}
            {config['preGoLiveTitlesVisibile'] && (
              <li>
                <Link to={baseUrl + '/myaccount/benefits'}>
                  My Account/My Benefits
                </Link>
              </li>
            )}
            {config['preGoLiveTitlesVisibile'] && (
              <li>
                <Link to={baseUrl + '/myaccount/messages'}>
                  My Account/ My Messages
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const isLoggedIn = helper.isLoggedIn(state.auth, config)
  return {
    isLoggedIn
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMap)
