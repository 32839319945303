import * as React from 'react'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash' // extra functionality
import { connect } from 'react-redux'
import { config } from '../../../config' // endpoints
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import * as queryString from 'query-string'
import { ClientPortalException, CODES, shouldThrow } from '../../base/Errors'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { UnauthorizedError } from '../src/components/utils'
const moment = require('moment-timezone')
const baseUrl = process.env.PUBLIC_URL
const formsEndpoint = config['forms_MyDashboard']
const peOverrideEndpoint = config['peOverrideEndpoint']
const agreeAutoAcceptEndPoint = config['agreeAutoAcceptEndPoint']
const deleteDraftEndPoint = config['deleteDraftEndPoint']
const submitOverrideEndpoint = config['submitOverride']
const noticeGateEndpoint = config['gatewayWvUrl'] + config['pECreatePdf']
const noticeGateReadEndpoint =
  config['gatewayWvUrl'] + config['pEgetAttachments']
const getPeApplicationsForPEUser =
  config['gatewayWvUrl'] + config['pEsearchAllByStatus']
const submitEndpoint = config['gatewayWvUrl'] + config['pEapplications']
const getPeApplicationsForDashboard =
  config['gatewayWvUrl'] + config['pEsearch']
const DashboardEndPoint = config['gatewayWvUrl'] + config['peDashboardServices']
const getPdfEndpoint =
  config['gatewayWvUrl'] +
  '/WVIE/application/pdf/v1.0/getApplicationPDF?appID={applId}'
interface Org {
  roleId: number
  roleName: string
  orgId: number
  orgName: string
  roleorgId: number
  dfltflg: string
}

interface MyDashboardContainerProps {
  presentation: any
  location: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userAccess: {
    selectedOrg: Org
    selectedUserRole: any
    userRoles: Array<UserRole>
  }
  orgId: string
  userRoleId: number
  userId: string
  locale: string
  roleId: number
  authHeader: string
  removePEApplication: (applId: string) => void
  setActivePEApplicationId: (applId: string) => void
  saveMaidID: (maid: object) => void
  roleName: string
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  history: any
  logoutUser
}

interface MyDashboardContainerState {
  application: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>

  formData: any
  formContext: any
  showUploadModal: boolean
  showSuccessPopup: boolean
  initialDataLoad: boolean
  redirect: string
  loadScreen: boolean
  callingApi: boolean
  popupoad: boolean
  applId: string
  uuid: string
  orgId: string
  userId: string
  userRoleId: string
  roleId: number
  roleName: string
  uuidToName: string
  frmPage: string
  currentAccordionIndex: string
  helpExpanded: boolean
}
/**
 * PEApplications provides:
 *  1) Application full (CP APPINTAKE) Navigation
 *  2) PE application  (CP PEAPPINTAKE) Navigation
 *  3) view Submitted Application details
 *  4)
 *
 * @class MyDashboard
 * @extends {React.Component<MyDashboardContainerProps, MyDashboardContainerState>}
 */
class MyDashboardContainer extends React.Component<
  MyDashboardContainerProps,
  MyDashboardContainerState
> {
  constructor(props: MyDashboardContainerProps) {
    super(props)
    this.state = {
      application: null,
      forms: null,
      formData: null,
      showUploadModal: false,
      showSuccessPopup: false,
      formContext: { elibilityFormEdit: false },
      initialDataLoad: false,
      redirect: null,
      loadScreen: null,
      callingApi: false,
      popupoad: null,
      applId: null,
      uuid: null,
      userRoleId: null,
      orgId: null,
      userId: null,
      roleId: null,
      roleName: null,
      uuidToName: null,
      frmPage: null,
      currentAccordionIndex: null,
      helpExpanded: true
    }
  }

  getLocalTime = utcDate => {
    return utcDate
  }

  handleAccordionClick = (id: string) => {
    let accordionIndex: string = (
      parseInt(id.match('(?:.*)Application_([0-9]+)_?')[1]) + 1
    ).toString()
    //id = 1 is for My Applications Submitted and id = 3 is for My Saved Draft Applications.
    //id = 2 is for My Agency's Applications Submitted and id = 4 is for My Agency's Saved Applications.\
    this.setState({ currentAccordionIndex: accordionIndex, popupoad: false })
    if (accordionIndex == '4' || accordionIndex == '6') {
      this.getIndividualResult(accordionIndex).then(response => {
        if (response && response.length > 0) {
          response.map(value => {
            let orgName = this.props.userAccess.selectedOrg.orgName
            var fullApplication = []
            var peApplication = []
            var renewalApplication = []
            value.peApplication.modifiedDate = this.getLocalTime(
              value.peApplication.modifiedDate
            )
            value.fullApplication.modifiedDate = this.getLocalTime(
              value.fullApplication.modifiedDate
            )
            value.renewalApplication.modifiedDate = this.getLocalTime(
              value.renewalApplication.modifiedDate
            )
            value.peApplication.appDate = this.getLocalTime(
              value.peApplication.appDate
            )
            value.fullApplication.appDate = this.getLocalTime(
              value.fullApplication.appDate
            )
            value.renewalApplication.appDate = this.getLocalTime(
              value.renewalApplication.appDate
            )
            value.renewalApplication.submittedOn = this.getLocalTime(
              value.renewalApplication.submittedOn
            )
            value.renewalApplication.renewalDate = this.getLocalTime(
              value.renewalApplication.renewalDate
            )
            value.fullApplication.submittedOn = this.getLocalTime(
              value.fullApplication.submittedOn
            )

            if (value.renewalApplication.appId != undefined) {
              renewalApplication.push(value.renewalApplication)
            }
            if (value.fullApplication.appId != undefined) {
              fullApplication.push(value.fullApplication)
            }
            if (value.peApplication != undefined) {
              peApplication.push(value.peApplication)
            }
            value.fullApplications = []
            value.peApplications = []
            value.renewalApplications = []
            value.accordionTitle =
              'Name : ' +
              (value.firstName ? value.firstName : '') +
              ' ' +
              (value.lastName ? value.lastName : '') +
              (accordionIndex == '4' || accordionIndex == '6'
                ? value.dob
                  ? '  DOB: ' + moment.utc(value.dob).format('MM/DD/YYYY')
                  : ''
                : '')
            if (value.fullApplication != null) {
              value.fullApplications.push({ fullApplication: fullApplication })
              if (fullApplication.length > 0) {
                fullApplication[0].agency = orgName
              }
            }
            delete value.fullApplication

            if (value.renewalApplication != null) {
              value.renewalApplications.push({
                renewalApplication: renewalApplication
              })
              if (renewalApplication.length > 0) {
                renewalApplication[0].agency = orgName
              }
            }
            delete value.renewalApplication
            if (value.peApplication != null) {
              value.peApplications.push({ peApplication: peApplication })
              if (peApplication.length > 0) {
                peApplication[0].agency = orgName
              }
            }
            delete value.peApplication
          })
        }
        let newFormData = helper.deepClone(this.state.formData)
        if (accordionIndex == '6') {
          newFormData.Application.myAgencyApplicationSubmitted.clients = response
        } else {
          newFormData.Application.myAgencyApplicationDraft.clients = response
        }

        this.setState({
          formData: newFormData,
          formContext: {
            ...this.state.formContext,
            formData: newFormData
          }
        })

        if (accordionIndex == '4') {
          for (
            var i = 0;
            i < newFormData.Application.myAgencyApplicationDraft.clients.length;
            i++
          ) {
            var maindiv = document.getElementById(
              'form1_Application_myAgencyApplicationDraft_clients_' +
                i +
                '_peApplications_0'
            )

            var _status =
              document.getElementById(
                'form1_Application_myAgencyApplicationDraft_clients_' +
                  i +
                  '_peApplications_0_peApplication_0_appStatusCd'
              ) != null
                ? document.getElementById(
                    'form1_Application_myAgencyApplicationDraft_clients_' +
                      i +
                      '_peApplications_0_peApplication_0_appStatusCd'
                  ).innerText
                : ''
            var tRow = maindiv && maindiv.getElementsByTagName('td')
            var tHeader = maindiv && maindiv.getElementsByTagName('th')
            if (tHeader) {
              tHeader[3].removeAttribute('style')
              tRow[3].removeAttribute('style')
              tHeader[4].removeAttribute('style')
              tRow[4].removeAttribute('style')

              tHeader[5].removeAttribute('style')
              tRow[5].removeAttribute('style')
              tHeader[6].removeAttribute('style')
              tRow[6].removeAttribute('style')

              tHeader[8].removeAttribute('style')
              tRow[8].removeAttribute('style')
              tHeader[9].removeAttribute('style')
              tRow[9].removeAttribute('style')
              if (_status == 'Submitted') {
                tHeader[7].style.display = 'none'
                tRow[7].style.display = 'none'
                tHeader[8].style.display = 'none'
                tRow[8].style.display = 'none'
                tHeader[10].innerText = 'Submitted By'
              } else if (_status == 'DEACTIVATED') {
                tHeader[3].style.display = 'none'
                tRow[3].style.display = 'none'
                tHeader[4].style.display = 'none'
                tRow[4].style.display = 'none'
                tHeader[5].style.display = 'none'
                tRow[5].style.display = 'none'
                tHeader[6].style.display = 'none'
                tRow[6].style.display = 'none'
                tHeader[9].style.display = 'none'
                tRow[9].style.display = 'none'
                tHeader[11].innerText = 'Last Modified By'
              } else {
                tHeader[3].style.display = 'none'
                tRow[3].style.display = 'none'
                tHeader[4].style.display = 'none'
                tRow[4].style.display = 'none'
                tHeader[5].style.display = 'none'
                tRow[5].style.display = 'none'
                tHeader[6].style.display = 'none'
                tRow[6].style.display = 'none'
                tHeader[9].style.display = 'none'
                tRow[9].style.display = 'none'
                tHeader[10].innerText = 'Last Modified By'
              }
            }
          }
        }
      })
    } else if (accordionIndex == '1' || accordionIndex == '2') {
      this.getAccordionResult(accordionIndex).then(response => {
        if (response && response.length > 0) {
          response.map(value => {
            var fullApplication = []
            var peApplication = []
            var renewalApplication = []
            value.peApplication.modifiedDate = this.getLocalTime(
              value.peApplication.modifiedDate
            )
            value.fullApplication.modifiedDate = this.getLocalTime(
              value.fullApplication.modifiedDate
            )
            value.renewalApplication.modifiedDate = this.getLocalTime(
              value.renewalApplication.modifiedDate
            )
            value.peApplication.appDate = this.getLocalTime(
              value.peApplication.appDate
            )
            value.fullApplication.appDate = this.getLocalTime(
              value.fullApplication.appDate
            )
            value.renewalApplication.appDate = this.getLocalTime(
              value.renewalApplication.appDate
            )
            value.fullApplication.submittedOn = this.getLocalTime(
              value.fullApplication.submittedOn
            )
            value.renewalApplication.submittedOn = this.getLocalTime(
              value.renewalApplication.submittedOn
            )
            value.renewalApplication.renewalDate = this.getLocalTime(
              value.renewalApplication.renewalDate
            )

            if (value.renewalApplication.appId != undefined) {
              renewalApplication.push(value.renewalApplication)
            }
            if (value.fullApplication.appId != undefined) {
              fullApplication.push(value.fullApplication)
            }
            if (value.peApplication != undefined) {
              peApplication.push(value.peApplication)
            }
            value.fullApplications = []
            value.renewalApplications = []
            value.peApplications = []
            value.accordionTitle =
              'Name : ' +
              (value.firstName ? value.firstName : '') +
              ' ' +
              (value.lastName ? value.lastName : '') +
              (accordionIndex == '1' || accordionIndex == '2'
                ? value.dob
                  ? '  DOB: ' + moment.utc(value.dob).format('MM/DD/YYYY')
                  : ''
                : '')

            if (value.renewalApplication != null) {
              value.renewalApplications.push({
                renewalApplication: renewalApplication
              })
            }
            if (value.fullApplication != null) {
              value.fullApplications.push({ fullApplication: fullApplication })
            }
            if (value.peApplication != null) {
              value.peApplications.push({ peApplication: peApplication })
            }
            value.fullApplication = fullApplication
            value.peApplication = peApplication
            value.renewalApplication = renewalApplication
          })
        }
        let newFormData = helper.deepClone(this.state.formData)
        if (accordionIndex == '2') {
          newFormData.Application.myAgencyExpiringApplications.clients = response
        } else {
          newFormData.Application.myExpiringApplications.clients = response
        }
        this.setState({
          formData: newFormData,
          formContext: {
            ...this.state.formContext,
            formData: newFormData
          }
        })
        if (accordionIndex == '1') {
          for (
            var i = 0;
            i < newFormData.Application.myExpiringApplications.clients.length;
            i++
          ) {
            var maindiv = document.getElementById(
              'form1_Application_myExpiringApplications_clients_' +
                i +
                '_peApplications_0'
            )
            var _status =
              document.getElementById(
                'form1_Application_myExpiringApplications_clients_' +
                  i +
                  '_peApplications_0_peApplication_0_appStatusCd'
              ) != null
                ? document.getElementById(
                    'form1_Application_myExpiringApplications_clients_' +
                      i +
                      '_peApplications_0_peApplication_0_appStatusCd'
                  ).innerText
                : ''

            var tRow = maindiv && maindiv.getElementsByTagName('td')
            var tHeader = maindiv && maindiv.getElementsByTagName('th')
            if (tHeader) {
              tHeader[3].removeAttribute('style')
              tRow[3].removeAttribute('style')
              tHeader[4].removeAttribute('style')
              tRow[4].removeAttribute('style')

              tHeader[5].removeAttribute('style')
              tRow[5].removeAttribute('style')
              tHeader[6].removeAttribute('style')
              tRow[6].removeAttribute('style')

              tHeader[8].removeAttribute('style')
              tRow[8].removeAttribute('style')
              tHeader[9].removeAttribute('style')
              tRow[9].removeAttribute('style')
              if (_status == 'Submitted') {
                tHeader[7].style.display = 'none'
                tRow[7].style.display = 'none'
                tHeader[8].style.display = 'none'
                tRow[8].style.display = 'none'
                tHeader[10].innerText = 'Submitted By'
              } else if (_status == 'DEACTIVATED') {
                tHeader[3].style.display = 'none'
                tRow[3].style.display = 'none'
                tHeader[4].style.display = 'none'
                tRow[4].style.display = 'none'
                tHeader[5].style.display = 'none'
                tRow[5].style.display = 'none'
                tHeader[6].style.display = 'none'
                tRow[6].style.display = 'none'
                tHeader[9].style.display = 'none'
                tRow[9].style.display = 'none'
                tHeader[11].innerText = 'Last Modified By'
              } else {
                tHeader[3].style.display = 'none'
                tRow[3].style.display = 'none'
                tHeader[4].style.display = 'none'
                tRow[4].style.display = 'none'
                tHeader[5].style.display = 'none'
                tRow[5].style.display = 'none'
                tHeader[6].style.display = 'none'
                tRow[6].style.display = 'none'
                tHeader[9].style.display = 'none'
                tRow[9].style.display = 'none'
                tHeader[10].innerText = 'Last Modified By'
              }
            }
          }
        } else if (accordionIndex == '2') {
          for (
            var i = 0;
            i <
            newFormData.Application.myAgencyExpiringApplications.clients.length;
            i++
          ) {
            var maindiv = document.getElementById(
              'form1_Application_myAgencyExpiringApplications_clients_' +
                i +
                '_peApplications_0'
            )

            var _status =
              document.getElementById(
                'form1_Application_myAgencyExpiringApplications_clients_' +
                  i +
                  '_peApplications_0_peApplication_0_appStatusCd'
              ) != null
                ? document.getElementById(
                    'form1_Application_myAgencyExpiringApplications_clients_' +
                      i +
                      '_peApplications_0_peApplication_0_appStatusCd'
                  ).innerText
                : ''
            var tRow = maindiv && maindiv.getElementsByTagName('td')
            var tHeader = maindiv && maindiv.getElementsByTagName('th')
            if (tHeader) {
              tHeader[3].removeAttribute('style')
              tRow[3].removeAttribute('style')
              tHeader[4].removeAttribute('style')
              tRow[4].removeAttribute('style')

              tHeader[5].removeAttribute('style')
              tRow[5].removeAttribute('style')
              tHeader[6].removeAttribute('style')
              tRow[6].removeAttribute('style')

              tHeader[8].removeAttribute('style')
              tRow[8].removeAttribute('style')
              tHeader[9].removeAttribute('style')
              tRow[9].removeAttribute('style')
              if (_status == 'Submitted') {
                tHeader[7].style.display = 'none'
                tRow[7].style.display = 'none'
                tHeader[8].style.display = 'none'
                tRow[8].style.display = 'none'
                tHeader[10].innerText = 'Submitted By'
              } else if (_status == 'DEACTIVATED') {
                tHeader[3].style.display = 'none'
                tRow[3].style.display = 'none'
                tHeader[4].style.display = 'none'
                tRow[4].style.display = 'none'
                tHeader[5].style.display = 'none'
                tRow[5].style.display = 'none'
                tHeader[6].style.display = 'none'
                tRow[6].style.display = 'none'
                tHeader[9].style.display = 'none'
                tRow[9].style.display = 'none'
                tHeader[11].innerText = 'Last Modified By'
              } else {
                tHeader[3].style.display = 'none'
                tRow[3].style.display = 'none'
                tHeader[4].style.display = 'none'
                tRow[4].style.display = 'none'
                tHeader[5].style.display = 'none'
                tRow[5].style.display = 'none'
                tHeader[6].style.display = 'none'
                tRow[6].style.display = 'none'
                tHeader[9].style.display = 'none'
                tRow[9].style.display = 'none'
                tHeader[10].innerText = 'Last Modified By'
              }
            }
          }
        }
      })
    } else {
      this.getOrganizationResult(accordionIndex).then(response => {
        if (response && response.length > 0) {
          response.map(value => {
            let orgName = this.props.userAccess.selectedOrg.orgName
            var fullApplication = []
            var peApplication = []
            var renewalApplication = []
            value.peApplication.modifiedDate = this.getLocalTime(
              value.peApplication.modifiedDate
            )
            value.fullApplication.modifiedDate = this.getLocalTime(
              value.fullApplication.modifiedDate
            )
            value.renewalApplication.modifiedDate = this.getLocalTime(
              value.renewalApplication.modifiedDate
            )
            value.peApplication.appDate = this.getLocalTime(
              value.peApplication.appDate
            )
            value.fullApplication.appDate = this.getLocalTime(
              value.fullApplication.appDate
            )

            value.renewalApplication.appDate = this.getLocalTime(
              value.renewalApplication.appDate
            )
            value.fullApplication.submittedOn = this.getLocalTime(
              value.fullApplication.submittedOn
            )

            value.renewalApplication.submittedOn = this.getLocalTime(
              value.renewalApplication.submittedOn
            )
            value.renewalApplication.renewalDate = this.getLocalTime(
              value.renewalApplication.renewalDate
            )
            if (value.fullApplication.appId != undefined) {
              fullApplication.push(value.fullApplication)
            }

            if (value.renewalApplication.appId != undefined) {
              renewalApplication.push(value.renewalApplication)
            }
            if (value.peApplication != undefined) {
              peApplication.push(value.peApplication)
            }
            value.fullApplications = []
            value.peApplications = []
            value.renewalApplications = []
            value.accordionTitle =
              'Name : ' +
              (value.firstName ? value.firstName : '') +
              ' ' +
              (value.lastName ? value.lastName : '') +
              (accordionIndex == '3' || accordionIndex == '5'
                ? value.dob
                  ? '  DOB: ' + moment.utc(value.dob).format('MM/DD/YYYY')
                  : ''
                : '')
            if (value.fullApplication != null) {
              value.fullApplications.push({ fullApplication: fullApplication })
              if (fullApplication.length > 0) {
                fullApplication[0].agency = orgName
              }
            }
            if (value.renewalApplication != null) {
              value.renewalApplications.push({
                renewalApplication: renewalApplication
              })
              if (renewalApplication.length > 0) {
                renewalApplication[0].agency = orgName
              }
            }
            if (value.peApplication != null) {
              value.peApplications.push({ peApplication: peApplication })
              if (peApplication.length > 0) {
                peApplication[0].agency = orgName
              }
            }
          })
        }

        let newFormData = helper.deepClone(this.state.formData)
        if (accordionIndex == '5') {
          newFormData.Application.myApplicationSub.clients = response
        } else {
          newFormData.Application.myApplicationsDraft.clients = response
        }
        this.setState({
          formData: newFormData,
          formContext: {
            ...this.state.formContext,
            formData: newFormData
          }
        })

        if (accordionIndex == '3') {
          for (var i = 0; i < 10; i++) {
            var maindiv = document.getElementById(
              'form1_Application_myApplicationsDraft_clients_' +
                i +
                '_peApplications_0'
            )
            var _status =
              document.getElementById(
                'form1_Application_myApplicationsDraft_clients_' +
                  i +
                  '_peApplications_0_peApplication_0_appStatusCd'
              ) != null
                ? document.getElementById(
                    'form1_Application_myApplicationsDraft_clients_' +
                      i +
                      '_peApplications_0_peApplication_0_appStatusCd'
                  ).innerText
                : ''
            var tRow = maindiv && maindiv.getElementsByTagName('td')
            var tHeader = maindiv && maindiv.getElementsByTagName('th')
            if (tHeader) {
              tHeader[3].removeAttribute('style')
              tRow[3].removeAttribute('style')
              tHeader[4].removeAttribute('style')
              tRow[4].removeAttribute('style')

              tHeader[5].removeAttribute('style')
              tRow[5].removeAttribute('style')
              tHeader[6].removeAttribute('style')
              tRow[6].removeAttribute('style')

              tHeader[8].removeAttribute('style')
              tRow[8].removeAttribute('style')
              tHeader[9].removeAttribute('style')
              tRow[9].removeAttribute('style')
              if (_status == 'Submitted') {
                tHeader[7].style.display = 'none'
                tRow[7].style.display = 'none'
                tHeader[8].style.display = 'none'
                tRow[8].style.display = 'none'
                tHeader[10].innerText = 'Submitted By'
              } else if (_status == 'DEACTIVATED') {
                tHeader[3].style.display = 'none'
                tRow[3].style.display = 'none'
                tHeader[4].style.display = 'none'
                tRow[4].style.display = 'none'
                tHeader[5].style.display = 'none'
                tRow[5].style.display = 'none'
                tHeader[6].style.display = 'none'
                tRow[6].style.display = 'none'
                tHeader[9].style.display = 'none'
                tRow[9].style.display = 'none'
                tHeader[11].innerText = 'Last Modified By'
              } else {
                tHeader[3].style.display = 'none'
                tRow[3].style.display = 'none'
                tHeader[4].style.display = 'none'
                tRow[4].style.display = 'none'
                tHeader[5].style.display = 'none'
                tRow[5].style.display = 'none'
                tHeader[6].style.display = 'none'
                tRow[6].style.display = 'none'
                tHeader[9].style.display = 'none'
                tRow[9].style.display = 'none'
                tHeader[10].innerText = 'Last Modified By'
              }
            }
          }
        }
      })
    }
  }

  _viewPDFApplication = (id: string, applicationId: number) => {
    const fetchEndpoint = getPdfEndpoint.replace(
      '{applId}',
      applicationId.toString()
    )
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            tenantCode: config.tCode,
            portalName: config.portalName,
            uuid: this.props.auth.userAccount.uuid,
            Authorization: config.bearer + (this.props.auth.accessToken || ''),
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage
      )
      .then(response => {
        let content = response.fileContent
        let contentType = 'application/pdf'
        var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
        var bytearray = this.convertDataURIToBinary(pdfAsDataUri)
        var file = new Blob([bytearray], {
          type: contentType
        })
        // for IE 10/11 save doc pop up will appear
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file)
        } else {
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          // auto download the file
          var a = document.createElement('a')
          a.href = fileURL
          a.target = '_blank'
          a.download = 'application.pdf'
          document.body.appendChild(a)
          a.click()
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'MyApplications _viewPDFApplication failed with ex',
          error
        )
        const code = CODES.MY_APPLICATIONS_VIEW_PDF
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  getAppId = (props: any) => {
    const myApplicationsDraftIndex = props.id.match(
      '(?:.*)myApplicationsDraft_clients_([0-9]+)_?'
    )
    const myAgencyApplicationDraftIndex = props.id.match(
      '(?:.*)myAgencyApplicationDraft_clients_([0-9]+)_?'
    )
    let applicationId
    if (
      myApplicationsDraftIndex &&
      props.formContext.formData.Application.myApplicationsDraft.clients.length
    ) {
      applicationId =
        props.formContext.formData.Application.myApplicationsDraft.clients[
          myApplicationsDraftIndex[1]
        ].fullApplications[0].fullApplication[0].appId
    }
    if (
      myAgencyApplicationDraftIndex &&
      props.formContext.formData.Application.myAgencyApplicationDraft.clients
        .length
    ) {
      applicationId =
        props.formContext.formData.Application.myAgencyApplicationDraft.clients[
          myAgencyApplicationDraftIndex[1]
        ].fullApplications[0].fullApplication[0].appId
    }
    return applicationId
  }

  isFullApplicationRadioSelected = (props: any) => {
    let fieldId = props.id
    let flag = false
    if (fieldId.includes('myApplicationSub')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationSub_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myApplicationSub.clients[
          clientIndex
        ].fullApplications[0].fullApplication.forEach(fullApp => {
          if (fullApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myApplicationsDraft')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationsDraft_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myApplicationsDraft.clients[
          clientIndex
        ].fullApplications[0].fullApplication.forEach(fullApp => {
          if (fullApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myExpiringApplications')) {
      let clientIndex = fieldId.match(
        '(?:.*)myExpiringApplications_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myExpiringApplications.clients[
          clientIndex
        ].fullApplications[0].fullApplication.forEach(fullApp => {
          if (fullApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyExpiringApplications')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyExpiringApplications_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myAgencyExpiringApplications.clients[
          clientIndex
        ].fullApplications[0].fullApplication.forEach(fullApp => {
          if (fullApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyApplicationDraft')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyApplicationDraft_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myAgencyApplicationDraft.clients[
          clientIndex
        ].fullApplications[0].fullApplication.forEach(fullApp => {
          if (fullApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyApplicationSubmitted')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyApplicationSubmitted_clients_([0-9]+)_?'
      )[1]
      this.state.formData.Application.myAgencyApplicationSubmitted.clients[
        clientIndex
      ].fullApplications[0].fullApplication.forEach(fullApp => {
        if (fullApp.select) {
          flag = true
        }
      })
    }
    return flag
  }
  isRenewalApplicationRadioSelected = (props: any) => {
    let fieldId = props.id
    let flag = false
    if (fieldId.includes('myApplicationSub')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationSub_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myApplicationSub.clients[
          clientIndex
        ].renewalApplications[0].renewalApplication.forEach(renewalApp => {
          if (renewalApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myApplicationsDraft')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationsDraft_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myApplicationsDraft.clients[
          clientIndex
        ].renewalApplications[0].renewalApplication.forEach(renewalApp => {
          if (renewalApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myExpiringApplications')) {
      let clientIndex = fieldId.match(
        '(?:.*)myExpiringApplications_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myExpiringApplications.clients[
          clientIndex
        ].renewalApplications[0].renewalApplication.forEach(renewalApp => {
          if (renewalApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyExpiringApplications')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyExpiringApplications_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myAgencyExpiringApplications.clients[
          clientIndex
        ].renewalApplications[0].renewalApplication.forEach(renewalApp => {
          if (renewalApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyApplicationDraft')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyApplicationDraft_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myAgencyApplicationDraft.clients[
          clientIndex
        ].renewalApplications[0].renewalApplication.forEach(renewalApp => {
          if (renewalApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyApplicationSubmitted')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyApplicationSubmitted_clients_([0-9]+)_?'
      )[1]
      this.state.formData.Application.myAgencyApplicationSubmitted.clients[
        clientIndex
      ].renewalApplications[0].renewalApplication.forEach(renewalApp => {
        if (renewalApp.select) {
          flag = true
        }
      })
    }
    return flag
  }
  isPEApplicationRadioSelected = (props: any) => {
    let fieldId = props.id
    let flag = false
    let peApplication

    if (fieldId.includes('myApplicationSub')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationSub_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myApplicationSub.clients[
          clientIndex
        ].peApplications[0].peApplication.forEach(peApp => {
          if (peApp.select) {
            flag = true
            peApplication = peApp
          }
        })
      }
      if (peApplication) {
        if (peApplication.eligibilityResults === 'Approved') {
          this.props.saveMaidID({
            maid: peApplication.tempMedicaidId.toString(),
            peId: peApplication.appId
          })
        } else {
          this.props.saveMaidID({
            maid: '0',
            peId: peApplication.appId
          })
        }
      }
    } else if (fieldId.includes('myApplicationsDraft')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationsDraft_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myApplicationsDraft.clients[
          clientIndex
        ].peApplications[0].peApplication.forEach(peApp => {
          if (peApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myExpiringApplications')) {
      let clientIndex = fieldId.match(
        '(?:.*)myExpiringApplications_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myExpiringApplications.clients[
          clientIndex
        ].peApplications[0].peApplication.forEach(peApp => {
          if (peApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyExpiringApplications')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyExpiringApplications_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myAgencyExpiringApplications.clients[
          clientIndex
        ].peApplications[0].peApplication.forEach(peApp => {
          if (peApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyApplicationDraft')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyApplicationDraft_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myAgencyApplicationDraft.clients[
          clientIndex
        ].peApplications[0].peApplication.forEach(peApp => {
          if (peApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyApplicationSubmitted')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyApplicationSubmitted_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myAgencyApplicationSubmitted.clients[
          clientIndex
        ].peApplications[0].peApplication.forEach(peApp => {
          if (peApp.select) {
            flag = true
            peApplication = peApp
          }
        })
      }
      if (peApplication) {
        if (peApplication.eligibilityResults === 'Approved') {
          this.props.saveMaidID({
            maid: peApplication.tempMedicaidId.toString(),
            peId: peApplication.appId
          })
        } else {
          this.props.saveMaidID({
            maid: '0',
            peId: peApplication.appId
          })
        }
      }
    }

    return flag
  }

  _editDraftApplication = (props: any) => {
    let applicationId = this.getAppId(props)
    this._setRedirect('/application?applId=' + applicationId)
  }

  componentDidMount() {
    document.title = 'My Dashboard - My Account'
    if (this.props.location) {
      try {
        const query = queryString.parse(this.props.location)
        var pgName = query.frmPage
        this.setState({ frmPage: pgName })
      } catch (err) {
        console.error('Error on handling applId from URL query', err)
      }
    }
    this._loadApp()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      //TODO: Should we be caching this?
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      serviceHelper
        .fetchJson(
          endpoint,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              locale: (this.props.locale + '_US').toUpperCase()
            }
          },
          this.props.showErrorMessage
        )
        .then(formsJson => {
          this.setState({ callingApi: false })
          const application = formsJson['app']
          const forms = application['forms']
          const formContext = {
            ...this.state.formContext,
            forms
          }
          this.setState({ forms, formContext })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('AppIntake form re-fetch failed due to ex', error)
        })
    }
    if (
      !_.isEqual(prevProps.orgId, this.props.orgId) &&
      this.state.currentAccordionIndex
    ) {
      var pg = 'form1_Application_' + this.state.currentAccordionIndex
      if (this.state.currentAccordionIndex) {
        this.handleAccordionClick(pg)
      }
    }
  }

  getOrganizationResult = (index: string) => {
    let fetchEndpoint = getPeApplicationsForPEUser
    let currentAccType
    let roleName = this.props.roleName

    if (roleName === 'Community Partner' || roleName === 'CP Admin') {
      switch (index) {
        case '1':
          currentAccType = 'CPEXPSELF'
          break
        case '2':
          currentAccType = 'CPEXPAGY'
          break
        case '3':
          currentAccType = 'CPDRAFTSELF'
          break
        case '4':
          currentAccType = 'CPDRAFTAGY'
          break
        case '5':
          currentAccType = 'CPSUBMITTEDSELF'
          break
        case '6':
          currentAccType = 'CPSUBMITTEDAGY'
          break
      }
    } else if (
      roleName === 'Presumptive Eligibility Worker' ||
      roleName == 'PE Admin'
    ) {
      switch (index) {
        case '1':
          currentAccType = 'PEEXPSELF'
          break
        case '2':
          currentAccType = 'PEEXPAGY'
          break
        case '3':
          currentAccType = 'PEDRAFTSELF'
          break
        case '4':
          currentAccType = 'PEDRAFTAGY'
          break
        case '5':
          currentAccType = 'PESUBMITTEDSELF'
          break
        case '6':
          currentAccType = 'PESUBMITTEDAGY'
          break
      }
    }
    fetchEndpoint = DashboardEndPoint

    return serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            orgId: this.props.userAccess.selectedOrg.orgId.toString(),
            roleId: this.props.roleId.toString(),
            accordianType: currentAccType,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        if (!_.isArray(json)) return []

        for (var i = 0; i < json.length; i++) {
          if (json[i].fullApplication.appStatusCd != undefined) {
            var AppSubDftStatus = json[i].fullApplication.appStatusCd
            if (
              AppSubDftStatus == 'SUBMITTED' ||
              AppSubDftStatus == 'submitted'
            ) {
              json[i].fullApplication.appStatusCd = 'Submitted'
            } else if (
              AppSubDftStatus == 'DRAFT' ||
              AppSubDftStatus == 'draft'
            ) {
              json[i].fullApplication.appStatusCd = 'Draft'
            }
          }
        }
        for (var i = 0; i < json.length; i++) {
          if (json[i].renewalApplication.appStatusCd != undefined) {
            var AppSubDftStatus = json[i].renewalApplication.appStatusCd
            if (
              AppSubDftStatus == 'SUBMITTED' ||
              AppSubDftStatus == 'submitted'
            ) {
              json[i].renewalApplication.appStatusCd = 'Submitted'
            } else if (
              AppSubDftStatus == 'DRAFT' ||
              AppSubDftStatus == 'draft'
            ) {
              json[i].renewalApplication.appStatusCd = 'Draft'
            }
          }
        }
        return json
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        return ex
      })
  }

  getAccordionResult = (index: string) => {
    let fetchEndpoint = getPeApplicationsForDashboard

    let applicationType, source, appStatus, searchByDateField, fromDate, toDate
    let appParams = [
      {
        searchApplications: {
          applicationType: null,
          source: null,
          appStatus: null,
          fromDate: null,
          toDate: null,
          searchByDateField: null
        }
      }
    ]
    let currentAccType
    let roleName = this.props.roleName
    if (roleName === 'Community Partner' || roleName === 'CP Admin') {
      switch (index) {
        case '1':
          currentAccType = 'CPEXPSELF'
          break
        case '2':
          currentAccType = 'CPEXPAGY'
          break
        case '3':
          currentAccType = 'CPDRAFTSELF'
          break
        case '4':
          currentAccType = 'CPDRAFTAGY'
          break
        case '5':
          currentAccType = 'CPSUBMITTEDSELF'
          break
        case '6':
          currentAccType = 'CPSUBMITTEDAGY'
          break
      }
    } else if (
      roleName == 'Presumptive Eligibility Worker' ||
      roleName == 'PE Admin'
    ) {
      switch (index) {
        case '1':
          currentAccType = 'PEEXPSELF'
          break
        case '2':
          currentAccType = 'PEEXPAGY'
          break
        case '3':
          currentAccType = 'PEDRAFTSELF'
          break
        case '4':
          currentAccType = 'PEDRAFTAGY'
          break
        case '5':
          currentAccType = 'PESUBMITTEDSELF'
          break
        case '6':
          currentAccType = 'PESUBMITTEDAGY'
          break
      }
    }
    fetchEndpoint = DashboardEndPoint
    return serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            orgId: this.props.userAccess.selectedOrg.orgId.toString(),
            roleId: this.props.roleId.toString(),
            accordiantype: currentAccType,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        if (!_.isArray(json)) return []
        for (var i = 0; i < json.length; i++) {
          if (json[i].fullApplication.appStatusCd != undefined) {
            var ApplDrfStatus = json[i].fullApplication.appStatusCd
            if (ApplDrfStatus == 'DRAFT' || ApplDrfStatus == 'draft') {
              json[i].fullApplication.appStatusCd = 'Draft'
            }
          }
        }
        for (var i = 0; i < json.length; i++) {
          if (json[i].renewalApplication.appStatusCd != undefined) {
            var ApplDrfStatus = json[i].renewalApplication.appStatusCd
            if (ApplDrfStatus == 'DRAFT' || ApplDrfStatus == 'draft') {
              json[i].renewalApplication.appStatusCd = 'Draft'
            }
          }
        }
        return json
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        return ex
      })
  }

  getIndividualResult = (index: string) => {
    let fetchEndpoint: string = DashboardEndPoint
    fetchEndpoint = DashboardEndPoint
    let currentAccType
    let roleName = this.props.roleName
    if (roleName === 'Community Partner' || roleName == 'CP Admin') {
      switch (index) {
        case '1':
          currentAccType = 'CPEXPSELF'
          break
        case '2':
          currentAccType = 'CPEXPAGY'
          break
        case '3':
          currentAccType = 'CPDRAFTSELF'
          break
        case '4':
          currentAccType = 'CPDRAFTAGY'
          break
        case '5':
          currentAccType = 'CPSUBMITTEDSELF'
          break
        case '6':
          currentAccType = 'CPSUBMITTEDAGY'
          break
      }
    } else if (
      roleName === 'Presumptive Eligibility Worker' ||
      roleName === 'PE Admin'
    ) {
      switch (index) {
        case '1':
          currentAccType = 'PEEXPSELF'
          break
        case '2':
          currentAccType = 'PEEXPAGY'
          break
        case '3':
          currentAccType = 'PEDRAFTSELF'
          break
        case '4':
          currentAccType = 'PEDRAFTAGY'
          break
        case '5':
          currentAccType = 'PESUBMITTEDSELF'
          break
        case '6':
          currentAccType = 'PESUBMITTEDAGY'
          break
      }
    }

    return serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            orgId: this.props.userAccess.selectedOrg.orgId.toString(),
            roleId: this.props.roleId.toString(),
            accordiantype: currentAccType
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        if (!_.isArray(json)) return []
        for (var i = 0; i < json.length; i++) {
          if (json[i].fullApplication.appStatusCd != undefined) {
            var RecSubDftStatus = json[i].fullApplication.appStatusCd
            if (
              RecSubDftStatus == 'SUBMITTED' ||
              RecSubDftStatus == 'submitted'
            ) {
              json[i].fullApplication.appStatusCd = 'Submitted'
            } else if (
              RecSubDftStatus == 'DRAFT' ||
              RecSubDftStatus == 'draft'
            ) {
              json[i].fullApplication.appStatusCd = 'Draft'
            }
          }
        }
        for (var i = 0; i < json.length; i++) {
          if (json[i].renewalApplication.appStatusCd != undefined) {
            var RecSubDftStatus = json[i].renewalApplication.appStatusCd
            if (
              RecSubDftStatus == 'SUBMITTED' ||
              RecSubDftStatus == 'submitted'
            ) {
              json[i].renewalApplication.appStatusCd = 'Submitted'
            } else if (
              RecSubDftStatus == 'DRAFT' ||
              RecSubDftStatus == 'draft'
            ) {
              json[i].renewalApplication.appStatusCd = 'Draft'
            }
          }
        }
        return json
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        return ex
      })
  }

  _loadApp = () => {
    serviceHelper
      .fetchJson(
        formsEndpoint.replace('{version}', '1.0'),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            locale: (this.props.locale + '_US').toUpperCase()
          }
        },
        this.props.showErrorMessage
      )
      .then(formJson => {
        this._initializeForm(formJson)
      })
      .catch(error => {
        console.error('MyProviders form fetch failed due to ex', error)
      })
  }

  _initializeForm = (formsJson: any) => {
    let forms, application, formData, formContext, refs
    application = formsJson['app']
    forms = application['forms']
    refs = application['metaData']['refs']
    //load the pre-filled form data
    formData = application['formData']
    let loadScreen = true
    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      ...formContext,
      refs: {
        ...refs,
        '{myApplicationsSubmittedFullAppId}': ({ fieldId, formContext }) => {
          const appId = helper.getSiblingFormData(fieldId, 'appId', formContext)
          return 'Application ID ' + appId
        }
      },
      forms: forms,
      formData: formData,
      component: this,
      deactivateApp: (appId: string, peApp: any) =>
        this._deactivateApp(appId, peApp),
      viewPENotice: (appId: string, docId: string) =>
        this._viewEligibilityNotice(appId, docId),
      confirmEligibility: appId => this._confirmEligibility(appId),
      notAgreeEligibilty: this._notAgreeEligibilty,
      elibilityFormEdit: false,
      agreeEligibilty: this._agreeEligibilty,
      onAccordionClick: this.handleAccordionClick,
      setRedirect: this._setRedirect,
      //_deleteDraftApplication: this._deleteDraftApplication,
      //overrideSubmit: formData => this._overrideSubmit(),
      viewPDFApplication: this._viewPDFApplication,
      config,
      roleName: this.props.roleName
    }
    this.setState({ application, forms, formContext, formData, loadScreen })
    var pg = 'form1_Application_' + this.state.frmPage
    if (this.state.frmPage) {
      this.handleAccordionClick(pg)
    }
  }

  _openUploadModal = () => {
    this.setState({ showUploadModal: true, showSuccessPopup: false })
  }
  _onFormDataChangeModal = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          overridePeData: formData
        },
        formContext: {
          ...prevState.formContext,
          formData: {
            ...prevState.formContext.formData,
            overridePeData: formData
          }
        }
      }
    })
  }
  _agreeEligibilty = () => {
    let formData = helper.deepClone(this.state.formData)

    const fetchEndpoint = agreeAutoAcceptEndPoint.replace(
      '{applicationId}',
      formData.overridePeData.appId
    )
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(results => {
        this._closeUploadModal()
        this._loadApp()
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(' getApps failed with ex', ex)
      })
  }
  _closeUploadModal = () => {
    this.setState({ showUploadModal: false, showSuccessPopup: false })
  }

  _confirmEligibility = (appId: string) => {
    let applId = appId
    let popupoad = true
    this.setState({ popupoad })
    this.setState({ applId })
    let userRoleId = this.props.roleId.toString()
    let orgId = this.props.userAccess.selectedOrg.orgId.toString()
    let userId = this.props.userId
    let uuid = this.props.auth.userAccount.uuid
    this.setState({ uuid })
    this.setState({ userRoleId })
    this.setState({ orgId })
    this.setState({ userId })
  }

  _notAgreeEligibilty = () => {
    this.setState(prevState => {
      return {
        formContext: {
          ...prevState.formContext,
          elibilityFormEdit: true
        }
      }
    })
  }

  _setRedirect = (path: string) => {
    this.setState({ redirect: path })
  }

  _deactivateApp = (appId: string, peApp: any) => {
    serviceHelper
      .fetchJson(
        submitEndpoint + '/' + appId + '?q=detail',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        json.result.applicationDetail[0].Application.Status = 'Deactivated'
        json.result.applicationDetail[0].applId = appId
        var submitUrl = submitEndpoint + '/' + appId
        const request = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          body: JSON.stringify(json.result.applicationDetail[0])
        }

        serviceHelper
          .fetchJson(submitUrl, request, this.props.showErrorMessage)
          .then(responseJson => {
            // window.location.reload()
          })
          .catch(error => {})
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        return ex
      })
  }

  _viewEligibilityNotice = (applicationId: string, docId: string) => {
    if (docId == null || docId.trim().length == 0) {
      //Endpoint for create doc
      var fetchEndpoint = noticeGateEndpoint.replace('{applId}', applicationId)

      serviceHelper
        .fetchJson(
          fetchEndpoint,
          {
            method: 'GET',
            headers: {
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userAccess.selectedUserRole.userRoleId,
              Accept: 'application/json',
              'Content-Type': 'application/json',
              roleId: this.props.roleId,
              orgId: this.props.orgId
            },
            mode: 'cors'
          },
          this.props.showErrorMessage
        )
        .then(res => {
          var content = res.result.contentData
          var contentType = 'application/pdf'

          var bytearray = this.convertDataURIToBinary(
            'data:application/pdf;base64,' + content
          )

          var file = new Blob([bytearray], {
            type: contentType
          })

          return file
        })
        .then(file => {
          //trick to download store a file having its URL
          var fileURL = ''
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file)
          } else {
            fileURL = URL.createObjectURL(file)
            window.open(fileURL)
            // auto download the file
            var a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = 'Notification.pdf'
            document.body.appendChild(a)
            a.click()
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          console.error(' view pdf', ex)
        })
    } else {
      //Endpoint for read doc
      fetchEndpoint = noticeGateReadEndpoint.replace('{docId}', docId)

      fetch(fetchEndpoint, {
        method: 'GET',
        headers: {
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          Accept: '/*/',
          'Content-Type': 'application/pdf'
        },
        mode: 'cors'
      })
        .then(res => {
          return res.arrayBuffer()
        })
        .then(res => {
          var data = new Uint8Array(res, 0, res.byteLength)
          var contentType = 'application/pdf'

          var file = new Blob([data], {
            type: contentType
          })

          return file
        })
        .then(file => {
          //trick to download store a file having its URL
          var fileURL = ''
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file)
          } else {
            fileURL = URL.createObjectURL(file)
            window.open(fileURL)
            // auto download the file
            var a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = 'Notification.pdf'
            document.body.appendChild(a)
            a.click()
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          console.error(' view pdf', ex)
        })
    }
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  // _accordionclickset = () => {
  //   let cla = document.getElementById('hlplink').className
  //   if (cla == 'panel-collapse collapse') {
  //     document.getElementById('hlplink').className =
  //       'panel-collapse collapse.in'
  //     document.getElementById('actrl').className = ''
  //     document.getElementById('actrl').setAttribute('aria-expanded', 'true')
  //   } else {
  //     document.getElementById('hlplink').className = 'panel-collapse collapse'
  //     document.getElementById('actrl').className = 'collapsed'
  //     document.getElementById('actrl').setAttribute('aria-expanded', 'false')
  //   }
  //   return false
  // }

  _accordionclickset = () => {
    this.setState({ helpExpanded: !this.state.helpExpanded })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        }
      }
    })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }
  _sendToApplication = (appFieldId: string) => {
    if (appFieldId) {
      let index = appFieldId.split('_')[1]
      this.setState({
        redirect:
          baseUrl + '/peApplication?applId=' + this.state.formData[index].appId
      })
    } else {
      this.props.removePEApplication('0')
      this.props.setActivePEApplicationId('0')
      this.setState({ redirect: baseUrl + '/peApplication' })
    }
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={baseUrl + this.state.redirect} />
    }
    const { presentation, errorMessage, userAccess } = this.props
    const {
      loadScreen,
      forms,
      formData,
      formContext,
      showUploadModal,
      popupoad,
      applId,
      uuid,
      userRoleId,
      userId,
      orgId,
      helpExpanded
    } = this.state
    const presentationProps = {
      loadScreen,
      forms,
      formData,
      formContext,
      showUploadModal,
      popupoad,
      applId,
      uuid,
      userRoleId,
      userId: this.props.userId,
      orgId: this.props.orgId,
      onFormDataChange: this._onFormDataChange,
      closeUploadModal: this._closeUploadModal,
      onFormDataChangeModal: this._onFormDataChangeModal,
      onaccordionclickset: this._accordionclickset,
      roleId: this.props.roleId,
      roleName: this.props.roleName,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      history: this.props.history,
      //sendToApplication: this._sendToApplication
      sendToApplication: this._sendToApplication,
      userAccess,
      helpExpanded,
      handleAccordionClick: this.handleAccordionClick
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let org = state.userAccess.selectedUserRole.organization
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let userId = _.get(state.auth, 'userAccount.userId') || ''
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  let roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''

  return {
    auth: state.auth,
    userAccess: state.userAccess,
    orgId,
    userRoleId,
    userId,
    locale: state.i18n.locale,
    roleId,
    roleName,
    location: location.search,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    removePEApplication: applId => {
      dispatch(actions.removePEApplication(applId))
    },
    setActivePEApplicationId: applId => {
      dispatch(actions.setActivePEApplicationId(applId))
    },
    saveMaidID: maid => {
      dispatch(actions.saveMaidID(maid))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDashboardContainer)
